import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-searchable-dropdown',
  templateUrl: './searchable-dropdown.component.html',
  styleUrls: ['./searchable-dropdown.component.scss'],
})
export class SearchableDropdownComponent implements OnInit {
  @Input() items = ['Program List', 'Program Applications', 'Program Mentees'];
  @Output() choiceItem = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {}

  selectItem(item: any) {
    this.choiceItem.emit(item);
  }
}
