<ng-container *ngIf="!isLoading; else loadingTemplate">
  <div class="program-list-container px-80">
    <lfx-breadcrumbs [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>

    <div class="card flex-grow-1 mb-5">
      <div class="card-header d-flex px-0 pb-0">
        <div class="tab active" [ngClass]="{'active': activeTabName === 'myProgram'}"
          (click)="changeActiveTab('myProgram')">
          My Program
        </div>
        <div class="tab" [ngClass]="{'active': activeTabName === 'allProgram'}" (click)="changeActiveTab('allProgram')">
          All Programs
        </div>
        <div class="tab-spacer"></div>
        <div class="actions">
          <lfx-input-search [filterFun]="filterFun" class="mr-3" (filter)="applyNameFilter($event)"></lfx-input-search>
          <button type="button" class="btn btn-primary default-border-radius mr-3" (click)="openFilterDialog()">
            <i class="fa fa-filter" aria-hidden="true"></i>
          </button>
          <div class="switch-view">
            <i class="fas fa-th-list" [ngClass]="{'active': tableView}" (click)="tableView = true"></i>
            <i class="fas fa-th-large" [ngClass]="{'active': !tableView}" (click)="tableView = false"></i>
          </div>
        </div>
      </div>
      <div class="card-body">
        <ng-container *ngIf="!isLoadMore && pagination.items!.length > 0; else loadingTemplate">
          <div class="grid-container" *ngIf="tableView === false">
            <lfx-program-detail-card *ngFor="let program of pagination.items" [program]="program">
            </lfx-program-detail-card>
          </div>
          <app-common-table *ngIf="tableView === true" [tableConfiguration]="tableConfig"
            [tableRecords]="pagination.items"></app-common-table>
          <lfx-pagination [paginationConfig]="pagination" (paginationChange)="updatePagination()" class="pt-4 mt-auto">
          </lfx-pagination>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>


<ng-template #loadingTemplate>
  <div *ngIf="!isLoadMore && !isLoading"
    class="size-20 weight-bold d-flex flex-grow-1 w-100 align-items-center justify-content-center">
    No records found
  </div>
  <lfx-loading *ngIf="isLoadMore || isLoading" class="flex-grow-1"></lfx-loading>
</ng-template>



<ng-template #projectNameTemplate let-element>
  <span class="text-black weight-600" lfxEllipsis>
    {{element.rowData?.project?.projectName}}
  </span>
</ng-template>

<ng-template #programTemplate let-element>
  <span class="d-flex align-items-center text-primary w-100 mw-100 cursor-pointer">
    <span (click)="previewProgram(element.rowData.id)" lfxEllipsis>{{element.rowData.programName}}</span>
  </span>
</ng-template>

<ng-template #dueDateTemplate let-element>
  <span class="text-gray-1" lfxEllipsis>
    {{element.rowData?.terms[0].termStartDate | date: 'EEE, MMM d yyyy'}}
  </span>
</ng-template>

<ng-template #appliedDateTemplate let-element>
  <span class="text-gray-1" lfxEllipsis>
    {{element.rowData?.terms[0].termStartDate | date: 'EEE, MMM d yyyy'}}
  </span>
</ng-template>

<ng-template #statusTemplate let-element>
  <span class="status mw-100 w-100 d-flex align-items-center" lfxEllipsis [ngClass]="{
    'inprogress-status': element.rowData.status.toLowerCase() === programStatus.pending.toLowerCase(),
    'completed-status': element.rowData.status.toLowerCase() === programStatus.completed.toLowerCase(),
    'declined-status': element.rowData.status.toLowerCase() === programStatus.declined.toLowerCase(),
    'accepted-status': element.rowData.status.toLowerCase() === programStatus.acceptingApplication.toLowerCase() || element.rowData.status.toLowerCase() === programStatus.accepted.toLowerCase()
  }">
    {{element.rowData.status | titlecase}}
  </span>
</ng-template>


<ng-template #actionsTemplate let-element>
  <span class="text-primary w-100 text-center mw-100 cursor-pointer" lfxEllipsis
    (click)="viewMileStones(element.rowData)">
    View
  </span>
</ng-template>