import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  activeTab = 'Home';
  messageTypes = ['Emails', 'Chats'];
  isMessageDropdownOpen = false;
  @Input() user?: any;
  constructor(private route: Router) { }

  ngOnInit() {
    this.handleActiveTab();
    this.route.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        this.handleActiveTab();
      }
    });
  }

  private handleActiveTab() {
    const urlList = this.route.url.split('/');
    urlList.splice(0, 1);
    if (urlList.includes('programs')) {
      this.changeActiveTab('Programs');
    }
    if (urlList.includes('mentors')) {
      this.changeActiveTab('Mentors');
    }
  }

  changeActiveTab(newTab: string) {
    this.activeTab = newTab;
  }

  toggleMessageDropdown() {
    this.isMessageDropdownOpen = !this.isMessageDropdownOpen;
  }

  selectMessage(active: any) {
    this.changeActiveTab('Messages');
    this.toggleMessageDropdown();
  }

  navigateTo(tab: string) {
    if (tab === 'Mentors') {
      this.route.navigate(['/mentors']);
      this.changeActiveTab('Mentors');
    }
    if (tab === 'Programs') {
      this.route.navigate(['/programs']);
      this.changeActiveTab('Programs');
    }
  }
}
