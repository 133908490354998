<div class="page-header-card d-flex flex-wrap justify-content-between">
  <div class="d-flex flex-column col-lg-6 col-sm-12 px-0">
    <div class="d-flex align-items-center mb-2">
      <div class="project-section width-ex-30 d-flex align-items-center" *ngIf="program?.project">
        <span class="project-title weight-600 size-20 mr-3">Project :</span>
        <img class="project-image mr-2 ml-1" [src]="program.project?.projectLogo" alt="">
        <span class="project-name size-20 weight-800" lfxEllipsis>{{program.project?.projectName}}</span>
      </div>
      <div class="vertical-line mx-4" *ngIf="program.websiteURL && program.project"></div>
      <div class="website-section d-flex width-ex-30" *ngIf="program.websiteURL">
        <span class="website-title size-14 text-dark mr-2">Website</span>
        <a [href]="program.websiteURL" target="_blank" class="website-text text-primary underline cursor-pointer"
          lfxEllipsis>
          {{program.websiteURL}}
        </a>
      </div>
    </div>
    <div class="d-flex align-items-center mb-2">
      <span class="program-name-title mr-2 size-24 weight-600">Program:</span>
      <span class="program-name-text text-primary size-24 weight-600">{{program.programName}}</span>
    </div>
    <div class="d-flex align-items-center mb-2" *ngIf="program.departmentName">
      <span class="department-name-text size-18 weight-600 mr-2">Department:</span>
      <span class="department-name-text ml-1">{{program.departmentName}}</span>
    </div>
    <small class="text-description">{{program.description.substring(0,100)}}</small>
  </div>
  <div class="d-flex align-items-center justify-content-end col-lg-6 col-sm-12 px-0">
    <div class="info-card">
      <span class="info-title weight-600 size-24">
        {{activeTerms.length ? activeTerms[0].termName : program.terms[0].termName}}
      </span>
      <span class="info-text weight-600 size-14">Term</span>
    </div>
    <div class="info-card">
      <span class="info-title weight-600 size-24">
        {{program.programDetail.availableMenteeSpots ?
        (program.programDetail.availableMenteeSpots - acceptedMenteeCount) : 0}}
      </span>
      <span class="info-text weight-600 size-14">Remaining Spots</span>
    </div>
    <div class="info-card">
      <span class="info-title weight-600 size-24">{{acceptedMenteeCount}}</span>
      <span class="info-text weight-600 size-14">Accepted Mentees</span>
    </div>
    <div class="info-card">
      <span class="info-title weight-600 size-24">{{menteeApplicationCount}}</span>
      <span class="info-text weight-600 size-14">Number of Applicants</span>
    </div>
  </div>
</div>