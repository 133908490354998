import { MenteeDashboardComponent } from './pages/mentee-dashboard/mentee-dashboard.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MentorshipDashboardCardComponent } from './components/mentorship-dashboard-card/mentorship-dashboard-card.component';
import { SharedModule } from '../shared/shared.module';
import { MentorsPageComponent } from './pages/mentors-page/mentors-page.component';
import { MentorDetailsCardComponent } from './components/mentor-details-card/mentor-details-card.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    MentorshipDashboardCardComponent,
    MenteeDashboardComponent,
    MentorsPageComponent,
    MentorDetailsCardComponent
  ],
})
export class DashboardModule { }
