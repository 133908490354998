// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT
import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { skills } from "../../mentorship/skills";
@Injectable()
export class SkillsService {
  skillsList: string[] = skills;

  constructor() { }

  public search = (searchText: string): string[] => {
    searchText = (searchText || '').toLowerCase();
    if (!searchText) {
      return this.skillsList;
    }
    return this.skillsList.filter(skillName => skillName.toLowerCase().indexOf(searchText.toLowerCase()) > -1).slice(0, 10);
  }
}
