import { Utilities } from 'src/app/core/utilities';
import { ProjectController } from 'src/app/core/controllers/project.controller';
import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserController } from './core/controllers/user.controller';
import { MenteeController } from './core/controllers/mentee.controller';
import { MENTEE_ID } from './core/constants';
import { LoadingService } from './core/services/loading.service';
import { LfxHeaderFooterService } from './core/services/lfx-header-footer.service';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  user: any;
  headerHeight = 120;
  isLoading = false;

  constructor(
    private projectController: ProjectController,
    private translate: TranslateService,
    private menteeController: MenteeController,
    private loadingService: LoadingService,
    private lfxHeaderFooterService: LfxHeaderFooterService,
    public authService: AuthService
  ) {
    this.translate.use('en');
    this.projectController.getAllProjects().subscribe(res => {
      this.projectController.projects = res;
    });
    this.menteeController.getMenteeById(MENTEE_ID).subscribe(mentee => {
      this.menteeController.activeMentee = mentee;
    });
    setTimeout(() => {
      this.loadingService.loadingObservable.subscribe(isLoading => {
        this.isLoading = isLoading;
      });
    }, 100);
  }


  @HostListener('window:resize')
  handleResize() {
    const header = document.getElementById('app-header');
    this.headerHeight = (header?.clientHeight ? header.clientHeight : 70) + 22 + 48;
  }
}
