import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { APIService, Mentee } from './../../../API.service';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { taskStatus, MilestoneActivityActionType } from 'src/app/core/constants';
import { from } from 'rxjs';
import { MenteeController } from 'src/app/core/controllers/mentee.controller';
import * as _ from 'lodash';

@Component({
  selector: 'lfx-milestone-details',
  templateUrl: './milestone-details.component.html',
  styleUrls: ['./milestone-details.component.scss']
})
export class MilestoneDetailsComponent implements OnInit {

  @Input() milestone!: any;
  isEditMilestone = false;
  updatedDescription = '';
  taskStatus = taskStatus;
  submissionSummary = '';
  submissionActivities = new Array();
  activeIndex: number = -1;

  constructor(
    private api: APIService,
    private menteeController: MenteeController,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.milestone.progress = this.getMilestoneProgress();
    const menteeTaskIds = new Array<string>();
    this.milestone.milestoneTasks.forEach((task: any) => {
      menteeTaskIds.push(task.taskDetail.menteeTaskID);
    });
    this.getListMilestoneActionLogs(this.milestone.id, menteeTaskIds);
  }

  getListMilestoneActionLogs(milestoneId: any, menteeTaskIds: Array<string>) {
    const filter = { milestoneID: { eq: milestoneId }, or: new Array() };
    menteeTaskIds.forEach(id => filter.or.push({ menteeTaskID: { eq: id } }));
    from(this.api.ListMilestoneActionLogs(filter, 1000)).subscribe(res => {
      this.submissionActivities = _.orderBy(res.items as any, 'createdAt', 'asc')
      this.submissionActivities.map(activity => {
        const actorDetails = activity.actor.split("|");
        if (actorDetails.length > 1) {
          activity.actor = {
            type: actorDetails[0],
            name: actorDetails[1],
            image: actorDetails[2]
          };
        } else {
          activity.actor = {
            type: actorDetails[0],
            name: '',
            image: ''
          };
        }
      });
    });
  }

  toggleSection(ev: any, index: number) {
    const target = ev.target;
    target.classList.toggle("active");
    this.activeIndex = index; // close
    const panel: any = document.querySelector(`#lfx-pre-panel-${index}`);
    if (panel?.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  cancel(index: number) {
    const input: any = document.querySelector(`#txt-input${index}`);
    const div: any = document.querySelector(`#txt-read${index}`);
    div.style.display = 'none';
    input.style.display = '';
    input.value = div.innerText;
  }

  addComment(task: any, commentText: string) {
    let comment = {
      commentBy: "mentee",
      commentText,
      createdAt: new Date().getTime()
    };
    let oldComments = task.taskDetail.comments;
    let allComments = [];
    if (oldComments !== null) {
      allComments.push(...oldComments);
    }
    allComments.push(comment);
    this.UpdateMenteeTaskComment(task, allComments);
    this.updateMilestoneActivityLogs(task, commentText, MilestoneActivityActionType.comment);
  }

  submitTask(index: number, row: any, text: string) {
    const input: any = document.querySelector(`#txt-input${index}`);
    const div: any = document.querySelector(`#txt-read${index}`);
    if (text.length) {
      input.style.display = 'none';
      div.style.display = 'flex';
      div.innerText = text;
      // to do api call to submit task details
      // change status as per response
      row.status = taskStatus.completed;
      row.progress = 0;
    } else {
      input.classList.toggle("active");
      setTimeout(() => {
        input.classList.toggle("active");
      }, 2000);
    }
    if (row.taskDetail.status == null || row.taskDetail.status == taskStatus.pending) {
      this.updateTask(row);
    }
  }

  updateTask(row: any) {
    let comment = {
      commentBy: "mentee",
      commentText: this.submissionSummary,
      createdAt: new Date().getTime()
    };
    let oldComments = row.taskDetail.comments;
    let allComments = [];
    if (oldComments !== null) {
      allComments.push(...oldComments);
    }
    allComments.push(comment);
    row.taskDetail.status = taskStatus.submitted;
    this.UpdateMenteeTaskComment(row, allComments);
    this.updateMilestoneActivityLogs(row, this.submissionSummary, MilestoneActivityActionType.submitted);
  }

  async UpdateMenteeTaskComment(row: any, comments: any) {
    await this.api.UpdateMenteeTask({
      id: row.taskDetail.menteeTaskID,
      status: row.taskDetail.status,
      comments: comments
    }).then((res) => {
      row.taskDetail.comments = res.comments;
    });
  }

  getMilestoneProgress() {
    let progress = 0;
    let tasksProgress = 0;
    this.milestone.milestoneTasks?.forEach((e: any) => {
      const taskProgress = e.taskDetail.progress ? e.taskDetail.progress : 0;
      tasksProgress += taskProgress;
    });
    progress = tasksProgress / this.milestone.milestoneTasks.length;
    return progress ? Math.round(progress) : 0;
  }

  editDescription() {
    this.isEditMilestone = true;
    this.updatedDescription = this.milestone.description;
  }

  updateMilestoneDescription() {
    this.api.UpdateMilestone({
      id: this.milestone.id,
      description: this.updatedDescription
    }).then(res => {
      this.milestone.description = this.updatedDescription;
      this.updatedDescription = '';
      this.isEditMilestone = false;
    });
  }

  getHoursFrom(timestamp: any) {
    const date = new Date(timestamp);
    const diff = moment().diff(moment(date.getTime()), 'hours');
    return diff;
  }

  updateMilestoneActivityLogs(milestone: any, actionVal: any, activity: any) {
    this.menteeController.activeMentee.subscribe((mentee: Mentee) => {
      const mentees = `mentee|${mentee.firstName + ' ' + mentee.lastName}|${mentee.imageUrl}`;
      this.api.CreateMilestoneActionLog({
        milestoneID: milestone.milestoneID,
        menteeTaskID: milestone.taskDetail.menteeTaskID,
        createdAt: new Date().getTime(),
        actor: mentees,
        actorID: mentee.id,
        action: actionVal,
        actionType: activity
      }).then((activity: any) => {
        const actorDetails = activity.actor!.split("|");
        if (actorDetails.length > 1) {
          activity.actor = {
            type: actorDetails[0],
            name: actorDetails[1],
            image: actorDetails[2]
          };
        } else {
          activity.actor = {
            type: actorDetails[0],
            name: '',
            image: ''
          };
        }
        this.submissionActivities.push(activity as any);
      });
    });
  }

}
