<input id="typeahead-focus" #skillsInput placeholder="Enter skill" type="text" class="form-control mb-4-2"
  [ngbTypeahead]="search" (focus)="focus$.next($any($event).target.value)"
  (click)="click$.next($any($event).target.value)" #instance="ngbTypeahead" />
<button type="button" class="btn btn-outline-primary ml-auto custom-btn" [ngClass]="{'mb-3': skills.length}"
  (click)="addSkill(skillsInput)">Add</button>
<div class="d-flex flex-wrap">
  <div class="skill mb-1 mr-2 d-flex justify-content-between align-items-center"
    *ngFor="let skill of skills.value; let skillIndex = index">
    {{skill}}
    <i class="fas fa-times ml-2" (click)="removeSkill(skillIndex);"></i>
  </div>
</div>
