import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ProgramDetailCardComponent } from './component/program-detail-card/program-detail-card.component';
import { ProgramPrerequisitesComponent } from './component/program-prerequisites/program-prerequisites.component';
import { MentorshipRoutingModule } from './mentorship-routing.module';
import { ProgramListViewComponent } from './pages/program-list-view/program-list-view.component';
import { ProgramOverviewComponent } from './pages/program-overview/program-overview.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MentorshipRoutingModule
  ],
  declarations: [
    ProgramOverviewComponent,
    ProgramDetailCardComponent,
    ProgramListViewComponent,
    ProgramPrerequisitesComponent
  ]
})
export class MentorshipModule { }
