<div class="modal-header align-items-center">
  <h4 class="gray-1 weight-600 size-20" id="modal-basic-title">Milestone: {{milestone.milestoneName}}</h4>
  <i class="fa fa-times close-btn" aria-hidden="true" (click)="activeModal.dismiss('Cross click')"></i>
</div>
<div class="modal-body flex-unset d-flex flex-column p-0">
  <div class="milestone-details-row d-flex justify-content-between w-100">
    <div class="d-flex align-items-center">
      <span class="info-text weight-600 size-14">#Task: {{' ' + milestone?.milestoneTasks?.length || 1}}</span>
      <span class="info-text weight-600 size-14">Created: {{' '+ milestone.createdAt | date : 'dd-MM-yyyy'}}</span>
      <span class="info-text weight-600 size-14">Due Date: {{' '+ milestone.dueDate | date : 'dd-MM-yyyy'}}</span>
    </div>
  </div>
  <div class="d-flex content-container">
    <div class="milestone-content d-flex flex-column w-100">
      <div>
        <span class="text-dark weight-600 size-18 mb-3">Milestone Description</span>
        <quill-view-html class="d-flex text-gray-200 mb-4-2" [content]="milestone.description" theme="snow">
        </quill-view-html>
      </div>
      <div class=" d-flex flex-column ">
        <div class="d-flex justify-content-between">
          <span class="text-dark weight-600 size-18 mb-3">Milestone Progress</span>
          <span>{{milestone.progress}}%</span>
        </div>
        <ngb-progressbar type="success" class="mb-2" [value]="milestone.progress" [showValue]="true"></ngb-progressbar>
      </div>
      <div class="d-flex flex-column">
        <span class="text-dark weight-600 size-18 mb-3">Task Details</span>
        <ng-container *ngIf="milestone?.milestoneTasks.length; else noData">
          <div class="common-table-container">
            <div class="table-body">
              <div class="table-row" *ngFor="let row of milestone?.milestoneTasks; let rowIndex = index"
                [ngClass]="{'active': activeIndex === rowIndex }">
                <div class="table-body-column align-items-center">
                  <div class="grid align-items-center">
                    <div class="ml-3">
                      <span class="text-black weight-600">
                        {{row.taskName}}
                      </span>
                    </div>
                    <div class="d-flex flex-column pl-3 pr-3">
                      <div class="w-100">
                        <ngb-progressbar type="success" [value]="row.taskDetail.progress" [showValue]="true">
                        </ngb-progressbar>
                      </div>
                    </div>
                    <div class="d-flex flex-row align-items-center"> <i class="fas fa-clock"></i> <span
                        class="text-black ml-3"> Due date:
                        {{row.dueDate | date: 'mediumDate'}}</span>
                    </div>
                    <div class="d-flex">
                      <span class="status" lfxEllipsis
                        [ngClass]="{
                        'pending-status': row.taskDetail?.status?.toLowerCase() === taskStatus.pending.toLowerCase(),
                        'submitted-status': row.taskDetail?.status?.toLowerCase() === taskStatus.submitted.toLowerCase(),
                        'completed-status': row.taskDetail?.status?.toLowerCase() === taskStatus.completed.toLowerCase()}">
                        {{row.taskDetail?.status}} Tasks
                      </span>
                    </div>
                    <div class="d-flex">
                      <button type="button" class="btn py-1 px-3 mx-1 action-collapsible"
                        (click)="toggleSection($event, rowIndex)"><i
                          class="fas fa fa-chevron-{{activeIndex === rowIndex ? 'down' : 'right'}}"></i></button>
                    </div>
                  </div>
                </div>
                <div [id]="'lfx-pre-panel-'+rowIndex" class="max-height"
                  [ngClass]="{'active': activeIndex === rowIndex }">
                  <div class="mx-3 my-3">
                    <div class="text-black">
                      <quill-view-html class="d-flex" [content]="row.description" theme="snow"></quill-view-html>
                    </div>
                    <textarea [id]="'txt-input'+ rowIndex" rows="4" class="form-control mb-3 mt-3 w-100"
                      [placeholder]="'Task Submission summary'" #submissionTaskText></textarea>
                    <div class="preview" [id]="'txt-read'+ rowIndex"></div>
                    <div class="d-flex w-100 justify-content-end">
                      <div class="d-flex"
                        *ngIf="!(row.taskDetail?.status?.toLowerCase() === taskStatus.submitted.toLowerCase()) else update;">
                        <button type="button" class="btn btn-outline-primary py-1 px-3 mx-1 ml-2"
                          (click)="cancel(rowIndex)">Cancel</button>
                        <button type="button" class="btn btn-primary py-1 px-3 mx-1 ml-2"
                          (click)="submitTask(rowIndex, row, submissionTaskText.value)">Submit</button>
                      </div>
                      <ng-template #update>
                        <div class="d-flex">
                          <button type="button" class="btn btn-primary py-1 px-3 mx-1 ml-2"
                            (click)="updateTask(row)">Update</button>
                        </div>
                      </ng-template>
                    </div>
                    <div class="comments-section my-4" *ngIf="row.taskDetail.comments.length">
                      <div class="comments-header my-3">
                        <span class="size-16 pl-3">Comments</span>
                      </div>
                      <div class="comments-body px-3 py-2" *ngFor="let comment of row.taskDetail.comments">
                        <span style="color:darkcyan">{{comment.commentBy | uppercase}}</span> made changes -
                        {{comment.createdAt | date}}
                        <div class="pt-1">
                          {{comment.commentText}}
                        </div>
                      </div>
                    </div>
                    <div class="my-2">
                      <textarea [id]="'txt-input-task'+ rowIndex" rows="4" class="form-control mb-3 mt-3 w-100"
                        [placeholder]="'start typing for reply'" #commentInput></textarea>
                      <!-- <i class="fa fa-paperclip" aria-hidden="true"></i> -->
                      <div class="d-flex w-100 justify-content-end">
                        <div class="d-flex">
                          <button type="button" class="btn btn-outline-primary py-1 px-3 mx-1 ml-2">Cancel</button>
                          <button type="button" class="btn btn-primary py-1 px-3 mx-1 ml-2"
                            (click)="addComment(row,commentInput.value )">Submit </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- <div class="submission-activity 1-100 d-flex justify-content-between"> -->
      <!-- <span>Submission Activity</span>
        <span><i class="fas fa fa-chevron-right"></i></span> -->
      <ng-container *ngTemplateOutlet="milestoneTasksSubmissionsTemplate"></ng-container>
      <!-- </div> -->
    </div>
  </div>
</div>
<!--from the mentor part milestone start-->
<ng-template #milestoneTasksSubmissionsTemplate>
  <ngb-accordion class="mt-auto submission-activity">
    <ngb-panel>
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="panel-header">
          <span class="size-14 weight-600 gray-1">
            Submission Activity
          </span>
          <button class="btn btn-link p-0 gray-1" ngbPanelToggle>
            <i class="fas fa-chevron-right" *ngIf="!opened"></i>
            <i class="fas fa-chevron-up" *ngIf="opened"></i>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="d-flex flex-column w-100 task">
          <span class="dark-date-text size-14 weight-600 mb-2">Yesterday</span>
          <div class="activity d-flex align-items-center w-100 mt-1 mb-3" *ngFor="let activity of submissionActivities">

            <img [src]="activity.actor.image ? activity.actor.image :
            'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+activity.actor.name.toLowerCase().charAt(0)+'.png'">
            <div class="d-flex flex-column flex-grow-1">
              <div class="d-flex align-items-center">
                <span class="date-text size-12 weight-600">
                  <i class="far fa-calendar-alt mr-2"></i>
                  {{activity.createdAt | date : 'd-M-yyyy'}}
                </span>
                <span class="date-text size-12 weight-600">
                  <i class="far fa-clock mr-2"></i>
                  {{getHoursFrom(activity.createdAt) + ' hrs'}}
                </span>
              </div>
              <span class="text-dark size-12 weight-600">
                {{activity.actionType}}ed
                <span class="text-primary">
                  {{activity.menteeTask.task.taskName}}
                </span>
                {{activity.action}}
              </span>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</ng-template>

<!--from the mentor part milestone end-->
<ng-template #noData>
  <div class="text-center">No tasks.</div>
</ng-template>