<div class="card-container">
  <div class="d-flex align-items-center justify-content-between mb-1">
    <span class="milestone-name weight-600 size-12">{{'milestone: ' + milestone.milestoneName}}</span>
    <span class="due-date size-12">
      Due on {{milestone.dueDate | date: 'MMM d'}}
      (<span class="remain-days">{{getRemainDays()+' Days Left'}}</span>)
    </span>
  </div>
  <quill-view-html class="d-flex mb-4-2 mt-2" [content]="milestone.description" theme="snow"></quill-view-html>
  <div class="progressbar mb-2">
    <ngb-progressbar type="success" [value]="milestone.progress" [showValue]="true"></ngb-progressbar>
  </div>
  <div class="d-flex justify-content-end align-items-center completed-tasks-count">
    <span class="size-12 weight-600">
      {{milestone.completedCount + ' of '+ (milestone.milestoneTasks ? milestone.milestoneTasks.length : 0)}}
    </span>
    <span class="size-12">&nbsp;Tasks Completed</span>
  </div>
</div>