import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MenteeApplicationProfileComponent } from "./pages/mentee-application-profile/mentee-application-profile.component";

const routes: Routes = [
  {
    path: 'create-application',
    component: MenteeApplicationProfileComponent,
    // canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MenteeRoutingModule { }

