import { ProjectController } from 'src/app/core/controllers/project.controller';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenteeApplicationStatus, taskStatus } from 'src/app/core/constants';
import { MenteeController } from 'src/app/core/controllers/mentee.controller';
import { Program } from 'src/app/models/program';
import { from } from 'rxjs';
import { APIService } from 'src/app/API.service';
import { ProgramController } from 'src/app/core/controllers/program.controller';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'lfx-program-prerequisites',
  templateUrl: './program-prerequisites.component.html',
  styleUrls: ['./program-prerequisites.component.scss']
})
export class ProgramPrerequisitesComponent implements OnInit {
  @Input() program!: Program;
  taskStatus = taskStatus;
  menteeApplicationStatus = MenteeApplicationStatus;
  isLoading = false;
  breadcrumbs = [
    {
      name: "programs",
      url: '/programs'
    },
    {
      name: "prerequisites",
      url: ''
    }
  ];
  preRequisiteTasks = new Array();
  menteeApplication: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private projectController: ProjectController,
    private menteeController: MenteeController,
    private programController: ProgramController,
    private route: Router,
    private api: APIService
  ) { }

  ngOnInit() {
    const menteeApplicationID = this.activatedRoute.snapshot.paramMap.get('menteeApplicationID');
    if (menteeApplicationID) {
      this.getMenteeApplication(menteeApplicationID);
    }
  }

  getMenteeApplication(id: string) {
    this.isLoading = true;
    from(this.api.GetMenteeApplication(id)).pipe(mergeMap(menteeApplication => {
      this.menteeApplication = menteeApplication;
      this.preRequisiteTasks = menteeApplication.preRequisites?.items as any;
      return this.programController.getProgramByIdGraphql(menteeApplication.programID);
    })).subscribe(program => {
      this.program = program as any;
      this.preRequisiteTasks.map(prerequisite => {
        if (!prerequisite.dueDate) {
          prerequisite.dueDate = this.program.terms[0].applicationAcceptanceEndDate;
        }
      });
      this.program.project = this.projectController.getProjectById(this.program.projectID!);
      if (this.program.projectID && !this.program.project) {
        this.projectController.getAllProjectsByIDs([this.program.projectID]).subscribe(res => {
          this.program.project = res[0];
          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
      }
    });
  }

  isUploadAll() {
    return this.preRequisiteTasks.filter((task: any) => task.status === this.taskStatus.uploaded).length === this.preRequisiteTasks.length;
  }

  goTo(action: string) {
    if (action === 'cancel') {
      this.route.navigate(['/']);
    } else if (action === 'save') {
      // save application
      this.route.navigate(['/']);
    } else if (action === 'submit') {
      // submit application
      this.menteeController.updateApplicationStatusRequest({
        id: this.menteeApplication.id,
        applicationStatus: taskStatus.pending
      }).subscribe(data => {
        this.route.navigate(['/']);
      });
    }
  }
}
