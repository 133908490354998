import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MAX_PHONE_NUMBER_LENGTH, MENTEE_ID, MIN_PHONE_NUMBER_LENGTH, PHONE_NUMBER_PATTERN } from 'src/app/core/constants';
import { MenteeController } from 'src/app/core/controllers/mentee.controller';
import { Profile } from 'src/app/core/models/mentee-profile.model';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { COUNTRY_LIST } from '../../data/countries';
import { MENTEE_PROFILE_STATIC_DATA } from '../../data/mentee-form-data';
import { US_STATES } from '../../data/us-stats';
@Component({
  selector: 'app-mentee-application-profile',
  templateUrl: './mentee-application-profile.component.html',
  styleUrls: ['./mentee-application-profile.component.scss'],
})
export class MenteeApplicationProfileComponent implements OnInit, OnDestroy {
  readonly minPhoneNumberLength = MIN_PHONE_NUMBER_LENGTH;
  readonly maxPhoneNumberLength = MAX_PHONE_NUMBER_LENGTH;
  isLoading = false;
  formSubmitted = false;
  submitBtnLabel: string = 'Finish';
  breadcrumbs = [
    {
      name: "Update Profile",
      url: ''
    }
  ];
  applicationID: String | undefined;
  programID: String | undefined;
  countryList = COUNTRY_LIST;
  usStatesList = US_STATES;
  menteeData = MENTEE_PROFILE_STATIC_DATA;
  isUsSelected = false;
  avatarSrc: string = '';
  menteeHasProfile = false;
  menteeProfileForm: FormGroup = new FormGroup({});
  subscriptions: Subscription[] = [];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private menteeController: MenteeController,
    private formBuilder: FormBuilder,
    private fileUploadService: FileUploadService
  ) { }
  ngOnInit() {
    window.scrollTo(0, 0);

    this.menteeProfileForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]]
      , email: ['', [Validators.required]]
      , phone: ['', [Validators.required,
      Validators.minLength(this.minPhoneNumberLength),
      Validators.maxLength(this.maxPhoneNumberLength),
      Validators.pattern(PHONE_NUMBER_PATTERN)]],
      address1: ['', [Validators.required]],
      address2: [''],
      country: ['', [Validators.required]],
      about: ['', [Validators.required]],
      city: ['', [Validators.required]],
      postCode: ['', [Validators.required]],
      logoUrl: [''],
      resumeUrl: [''],
      skills: new FormArray([], [Validators.required, Validators.minLength(1)]),
      improvementSkills: new FormArray([], [Validators.required, Validators.minLength(1)]),
      comments: ['', [Validators.required]],
      age: [''],
      race: [''],
      gender: [''],
      income: [''],
      educationLevel: ['']
    });

    const routeParamSub = this.activatedRoute.queryParams.subscribe(params => {
      this.applicationID = params['applicationID'];
      this.programID = params['programID'];

      this.scrollToElement('Profile');
      // this.menteeController.getApplications({
      //   userID: MENTEE_ID,
      //   programID: this.programID,
      //   limit: 10,
      //   cursor: ''
      // }).subscribe((applications: any) => {
      //   this.menteeHasProfile = applications.response.data?.listMenteeApplications.items.length ? true : false;
      // });
    });
    this.subscriptions.push(routeParamSub);
  }

  scrollToElement(elementId: string) {
    const elementRef = document.getElementById(elementId) as HTMLElement;
    if (elementRef) {
      elementRef.scrollIntoView({ behavior: 'smooth' });
    }
  }

  openStatesFields() {
    const country = this.menteeProfileForm.get('country')?.value;
    if (country === 'US') {
      this.menteeProfileForm.addControl('state', new FormControl([Validators.required]));
      this.isUsSelected ? (this.isUsSelected = false) : (this.isUsSelected = true);
    } else {
      this.isUsSelected = false;
      this.menteeProfileForm.removeControl('state');
    }
  }

  handleFiles(ev: any, isAvatar: boolean = false) {
    const fileList = ev.target.files;
    if (!fileList.length) {
      return;
    } else {
      // isAvatar ? this.avatarSrc = window.URL.createObjectURL(fileList[0]): '';
      if (isAvatar) {
        this.avatarSrc = 'yes';

        const file = fileList[0];
        const preview: any = document.querySelector('#avatarImage');
        const reader = new FileReader();
        reader.addEventListener("load", function () {
          preview.src = reader.result;
        }, false);

        reader.readAsDataURL(file);
      }
    }
  }
  clearAvatar() {
    const preview: any = document.querySelector('#avatarImage');
    preview.src = '/assets/no_logo_uploaded.png';
  }

  skipProfile() {
    this.router.navigate([`/program/prerequisites/${this.applicationID}`]);
  }

  goBack() {
    // if (confirm('You will lose your changes—are you sure you wish to cancel?')) {
    this.router.navigate([`/program/overview/${this.programID}`]);
    // }
  }

  createMenteeApplication() {
    const values = this.menteeProfileForm.value;
    if (this.menteeProfileForm.invalid) {
      return;
    }
    localStorage.setItem('menteeProfile', JSON.stringify(values));
    this.submit(values)
    // this.router.navigate([`/program/prerequisites/${this.programID}`]);
  }

  submit(values: any) {
    const logoControl = this.menteeProfileForm.get('logoUrl') as AbstractControl;
    const resumeControl = this.menteeProfileForm.get('resumeUrl') as AbstractControl;

    forkJoin(
      logoControl.value instanceof Blob
        ? this.fileUploadService.uploadFile(logoControl.value as File)
        : '',
      this.fileUploadService.uploadFile(resumeControl.value)
    )
      .pipe(
        switchMap(([logoUrl, resumeUrl]) => {
          const userProfileRequest: Profile = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            type: 'apprentice',
            phone: values.phone,
            logoUrl: logoUrl,
            introduction: values.introduction,
            address: {
              address1: values.address1,
              address2: values.address2,
              country: values.country,
              state: values.state,
              city: values.city,
              zipCode: values.zipCode,
            },
            profileLinks: {
              githubProfileLink: '', //  values.githubUrl,
              linkedinProfileLink: '', // values.linkedInUrl,
              twitterProfileLink: '', // values.linkedInUrl,
              resumeLink: resumeUrl || '',
            },
            skillSet: {
              skills: values.skills.map((skill: any) => skill.name),
              improvementSkills: values.improvementSkills.map((skill: any) => skill.name),
              comments: values.comments,
            },
            demographics: {
              age: values.age || '',
              race: values.race || '',
              gender: values.gender || '',
            },
            socioeconomics: {
              income: values.householdIncome || '',
              educationLevel: values.educationLevel || '',
            },
          };

          return this.menteeController.createUserProfile(userProfileRequest);
        })
      )
      .subscribe(
        newApprentice => {
          // if (this.projectId) {
          // Moves user to project applied page
          // this.router.navigate(['/project/applied/']);
          // } else {
          // Moves user to project matching page
          // this.getMatchingProjects();
          window.scrollTo(0, 0);
          this.router.navigate([`/program/prerequisites/${this.applicationID}`]);
          // }
        },
        err => {
          let httpCode = err.split('|')[0];
          if (httpCode == 409) {
            window.scrollTo(0, 0);
          }
          this.formSubmitted = false;
          this.submitBtnLabel = 'Submit';
        }
      );
  }


  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
