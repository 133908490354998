import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProgramPrerequisitesComponent } from './component/program-prerequisites/program-prerequisites.component';
import { ProgramOverviewComponent } from './pages/program-overview/program-overview.component';

const routes: Routes = [
  {
    component: ProgramOverviewComponent,
    path: 'overview/:programId'
  },
  {
    path: 'prerequisites/:menteeApplicationID',
    component: ProgramPrerequisitesComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MentorshipRoutingModule { }
