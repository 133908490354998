import { GenericSearchPipe } from './../../../core/pipes/generic-search.pipe';
import { MenteeApplicationStatus, MENTEE_ID, ProgramStatus } from './../../../core/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { TableColumnConfig } from './../../../core/models/table-column-config';
import { TableConfig } from './../../../core/models/table-config';
import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { forkJoin, of, Subscription } from 'rxjs';
import { MenteeController } from 'src/app/core/controllers/mentee.controller';
import { ProjectController } from 'src/app/core/controllers/project.controller';

@Component({
  selector: 'lfx-mentee-dashboard',
  templateUrl: './mentee-dashboard.component.html',
  styleUrls: ['./mentee-dashboard.component.scss']
})
export class MenteeDashboardComponent implements OnInit, AfterViewInit {

  @ViewChild('projectNameTemplate') projectNameTemplate!: TemplateRef<any>;
  @ViewChild('programTemplate') programTemplate!: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate!: TemplateRef<any>;
  @ViewChild('actionTemplate') actionTemplate!: TemplateRef<any>;
  @ViewChild('actionTemplate_cp') actionTemplate_cp!: TemplateRef<any>;
  @ViewChild('actionTemplate_myApp') actionTemplate_myApp!: TemplateRef<any>;

  tableConfig: TableConfig = {
    xPadding: '20px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>(),
    isLoading: false,
    error: false
  }
  tableConfig_completedProgram: TableConfig = {
    xPadding: '20px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>(),
    isLoading: false,
    error: false
  }
  tableConfig_myApplication: TableConfig = {
    xPadding: '20px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>(),
    isLoading: false,
    error: false
  }
  breadcrumbs = [{
    name: "mentee dashboard",
    url: ""
  }];
  isLoading = false;
  isLoadMore = false; subscriptions: Subscription[] = [];
  allApplications = new Array();
  applications = new Array();

  programStatus = ProgramStatus;
  genericSearchPipe = new GenericSearchPipe();
  selectedTab = 'myCurrentProgram';
  constructor(private route: Router,
    private activatedRoute: ActivatedRoute,
    private menteeController: MenteeController,
    private projectController: ProjectController) { }

  ngOnInit() {
    this.tableConfig.isLoading = true;
    const routeParamSub = this.activatedRoute.queryParams.subscribe(params => {
      const getAllApplication = this.menteeController.getApplications({
        userID: MENTEE_ID,
        limit: 100,
        cursor: ''
      });
      forkJoin([getAllApplication, this.projectController.getAllProjects()])
        .subscribe((data: any) => {
          const [applications, projects] = data;
          this.projectController.projects = projects;
          const items = applications.response.data?.listMenteeApplications.items;
          this.allApplications = items.map((item: any) => {
            if (item?.program.projectID) {
              item.program['project'] = this.projectController.getProjectById(item?.program.projectID)
            }
            item.program.path = 'program/overview/' + item.program.id;
            return item;
          });
          this.changeTab('myCurrentProgram');
          this.tableConfig.isLoading = false;
        });
    });
    this.subscriptions.push(routeParamSub);

  }

  ngAfterViewInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    this.tableConfig.columns = [
      {
        title: 'Project',
        width: '25.5%',
        template: this.projectNameTemplate
      },
      {
        title: 'Program Name',
        width: '35%',
        template: this.programTemplate,
      },
      {
        title: 'Status',
        width: '26.5%',
        template: this.statusTemplate,
        isCenter: true,
        key: 'status'
      },
      {
        title: 'Action',
        width: '13%',
        template: this.actionTemplate,
        isCenter: true,
      }
    ];
    this.tableConfig_completedProgram.columns = [
      {
        title: 'Project',
        width: '25.5%',
        template: this.projectNameTemplate
      },
      {
        title: 'Program Name',
        width: '35%',
        template: this.programTemplate,
      },
      {
        title: 'Mentors',
        width: '26.5%',
        template: this.statusTemplate,
        isCenter: true,
        key: 'status'
      },
      {
        title: 'Action',
        width: '13%',
        template: this.actionTemplate_cp,
        isCenter: true,
      }
    ];
    this.tableConfig_myApplication.columns = [
      {
        title: 'Project',
        width: '25.5%',
        template: this.projectNameTemplate
      },
      {
        title: 'Program Name',
        width: '35%',
        template: this.programTemplate,
      },
      {
        title: 'Prerequisites Task Status',
        width: '26.5%',
        template: this.statusTemplate,
        isCenter: true,
        key: 'status'
      },
      {
        title: 'Action',
        width: '13%',
        template: this.actionTemplate_myApp,
        isCenter: true,
      }
    ];
  }

  previewProject(programID: string) {
    this.route.navigate(['/program/overview', programID]);
  }

  filterFun = (filterText: string, appliedFilter?: any) => {
    const searchResult = this.genericSearchPipe.transform(this.applications.map((app: any) => app.program), filterText, 'programName');
    return of({ items: searchResult, nextToken: false })
  }

  changeTab(tab: string) {
    this.selectedTab = tab;
    if (tab == 'myCurrentProgram') {
      this.applications = this.allApplications.filter(app => app.applicationStatus === MenteeApplicationStatus.accepted);
    } else if (tab == 'myCompletedProgram') {
      this.applications = this.allApplications.filter(app => app.applicationStatus === MenteeApplicationStatus.graduated);
    } else if (tab == 'myApplications') {
      this.applications = this.allApplications.filter(app => app.applicationStatus === MenteeApplicationStatus.pending || app.applicationStatus === MenteeApplicationStatus.draft);
    }
  }

  viewAllPrograms() {
    this.route.navigate(['/programs']);
  }

  viewMileStones(programID: string) {
    this.route.navigate([`/program/milestone/${programID}`]);
  }

  navigateToMenteeApplication(menteeApplicationID: string) {
    this.route.navigate([`/program/prerequisites/${menteeApplicationID}`]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
