<ng-container *ngIf="!isLoading else loadingTemplate">
  <div class="d-flex task-management-container flex-column px-80">
    <lfx-breadcrumbs [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>
    <lfx-program-overview-header *ngIf="program" [program]="program"></lfx-program-overview-header>

    <div class="d-flex mt-3 mb-3 w-100 milestone-container">
      <div class="col-lg-4 col-md-12 col-sm-12">
        <div class="card">
          <h2 class="card-header">
            <span class="header-text size-20 weight-600">
              To Do
            </span>
            <span class="list-size size-18 weight-600">
              {{todo.length}}
            </span>
          </h2>
          <!-- <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="todo" id="todo" -->
          <!-- [cdkDropListConnectedTo]="[doingList,doneList]" class="list card-body" (cdkDropListDropped)="drop($event)"> -->
          <lfx-milestone-card [milestone]="milestone" class="milestone-card d-flex" *ngFor="let milestone of todo"
            (click)="viewMilestone(milestone)">
            <!-- cdkDrag -->
          </lfx-milestone-card>
          <!-- </div> -->
        </div>
      </div>

      <div class="col-lg-4 col-md-12 col-sm-12">
        <div class="card">
          <h2 class="card-header">
            <span class="header-text size-20 weight-600"> Doing </span>
            <span class="list-size size-18 weight-600">
              {{doing.length}}
            </span>
          </h2>

          <!-- <div cdkDropList #doingList="cdkDropList" [cdkDropListData]="doing" id="doing" -->
          <!-- [cdkDropListConnectedTo]="[doneList,todoList]" class="list card-body" (cdkDropListDropped)="drop($event)"> -->
          <lfx-milestone-card [milestone]="milestone" class="milestone-card d-flex" *ngFor="let milestone of doing"
            (click)="viewMilestone(milestone)">
            <!-- cdkDrag -->
          </lfx-milestone-card>
          <!-- </div> -->
        </div>
      </div>

      <div class="col-lg-4 col-md-12 col-sm-12">
        <div class="card">
          <h2 class="card-header">
            <span class="header-text size-20 weight-600">Done</span>
            <span class="list-size size-18 weight-600">
              {{done.length}}
            </span>
          </h2>

          <!-- <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="done" id="done" -->
          <!-- [cdkDropListConnectedTo]="[doingList,todoList]" class="list card-body" (cdkDropListDropped)="drop($event)"> -->
          <lfx-milestone-card [milestone]="milestone" class="milestone-card d-flex" *ngFor="let milestone of done"
            (click)="viewMilestone(milestone)">
            <!-- cdkDrag -->
          </lfx-milestone-card>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loadingTemplate>
  <lfx-loading></lfx-loading>
</ng-template>