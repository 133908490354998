import { MenteeApplicationStatus, MENTEE_ID, ProgramStatus } from './../../../core/constants';
import { ProgramController } from './../../../core/controllers/program.controller';
import { ProjectController } from 'src/app/core/controllers/project.controller';
import { PaginationConfig, TableConfig } from './../../../core/models/table-config';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Utilities } from 'src/app/core/utilities';
import { Program } from 'src/app/models/program';
import { APIService } from 'src/app/API.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { from, of } from 'rxjs';
import { Router } from '@angular/router';
import { FilterPopupComponent } from 'src/app/shared/filter-popup/filter-popup.component';
import { GenericSearchPipe } from 'src/app/core/pipes/generic-search.pipe';
import * as _ from 'lodash';
import { TableColumnConfig } from 'src/app/core/models/table-column-config';

@Component({
  selector: 'lfx-program-list-view',
  templateUrl: './program-list-view.component.html',
  styleUrls: ['./program-list-view.component.scss']
})
export class ProgramListViewComponent implements OnInit {

  isLoading = false;
  isLoadMore = false;
  allPrograms = new Array<Program>();
  programs = new Array<Program>();
  utilities = new Utilities();
  filter: any = {};
  pagination = new PaginationConfig();
  activeTabName = '';

  filterForm = new FormGroup({
    project: new FormArray([]),
    status: new FormControl('')
  });

  @ViewChild('projectNameTemplate') projectNameTemplate!: TemplateRef<any>;
  @ViewChild('programTemplate') programTemplate!: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate!: TemplateRef<any>;
  @ViewChild('dueDateTemplate') dueDateTemplate!: TemplateRef<any>;
  @ViewChild('appliedDateTemplate') appliedDateTemplate!: TemplateRef<any>;
  @ViewChild('actionsTemplate') actionsTemplate!: TemplateRef<any>;

  genericSearchPipe = new GenericSearchPipe();
  programStatus = ProgramStatus;
  tableView = false;
  tableConfig = new TableConfig();
  myProgramsColumns = new Array<TableColumnConfig>();
  allProgramsColumns = new Array<TableColumnConfig>();


  breadcrumbs = [
    {
      name: "programs",
      url: ''
    }
  ];
  constructor(
    private api: APIService,
    private route: Router,
    private modalService: NgbModal,
    private projectController: ProjectController,
    private programController: ProgramController
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.pagination.numberOfElementsPerPage = 8;
    this.getAllPrograms();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.myProgramsColumns = [
      {
        title: 'Project',
        width: '14%',
        template: this.projectNameTemplate
      },
      {
        title: 'Program Name',
        width: '25%',
        template: this.programTemplate
      },
      {
        title: 'Applied Date',
        width: '17.5%',
        template: this.appliedDateTemplate
      },
      {
        title: 'Due Date',
        width: '17.5%',
        template: this.dueDateTemplate
      },
      {
        title: 'Status',
        width: '17.5%',
        template: this.statusTemplate
      },
      {
        title: 'Action',
        isCenter: true,
        width: '8.5%',
        template: this.actionsTemplate
      }
    ];
    this.allProgramsColumns = [
      {
        title: 'Project',
        width: '14%',
        template: this.projectNameTemplate
      },
      {
        title: 'Program Name',
        width: '25%',
        template: this.programTemplate
      },
      {
        title: 'Applied Date',
        width: '17.5%',
        template: this.appliedDateTemplate
      },
      {
        title: 'Due Date',
        width: '17.5%',
        template: this.dueDateTemplate
      },
      {
        title: 'Status',
        width: '17.5%',
        template: this.statusTemplate
      },
      {
        title: 'Action',
        isCenter: true,
        width: '8.5%',
        template: this.actionsTemplate
      }
    ];
  }

  getAllPrograms() {
    from(this.programController.getAllProgramsGraphql()).subscribe((res: any) => {
      let { items, nextToken } = res;
      items = (items as Array<any>)?.map(item => {
        if (item?.projectID) {
          item['project'] = this.projectController.getProjectById(item?.projectID)
        }
        item.path = 'program/overview/' + item.id;
        return item;
      }).filter(program => program.status !== ProgramStatus.draft && program.status !== ProgramStatus.declined);
      this.allPrograms = _.orderBy(items, 'createdAt', 'desc');
      this.programs = _.cloneDeep(this.allPrograms);
      this.changeActiveTab('myProgram');
      this.isLoadMore = false;
      this.isLoading = false;
    })
  }

  changeActiveTab(newTabName: string) {
    if (this.activeTabName === newTabName) {
      return;
    }
    this.isLoadMore = true;
    this.activeTabName = newTabName;
    switch (this.activeTabName) {
      case 'myProgram':
        this.programs = this.allPrograms.filter(program => {
          return !!program.mentees.find(mentee => mentee.id === MENTEE_ID && mentee.applicationStatus === MenteeApplicationStatus.accepted);
        });
        this.tableConfig.columns = this.myProgramsColumns;
        break;
      default:
        this.programs = _.cloneDeep(this.allPrograms);
        this.tableConfig.columns = this.allProgramsColumns;
        break;
    }
    this.generatePages(this.programs);
    setTimeout(() => {
      this.isLoadMore = false;
    }, 300);
  }

  openFilterDialog() {
    const dialogRef = this.modalService.open(FilterPopupComponent, {
      size: "lg",
      centered: true
    });
    const instance = dialogRef.componentInstance;
    instance.parentForm = this.filterForm;
    instance.tabsConfig = [
      {
        title: "Project",
        infoTitle: "Select Project",
        type: "searchableList",
        items: this.projectController.projects,
        controlName: "project",
        viewName: 'projectName'
      },
      {
        title: "Status",
        infoTitle: "Select Status",
        type: "radio",
        items: this.getStatusList(),
        controlName: "status",
        search: (text: string): string[] => {
          return [""];
        }
      }
    ];

    dialogRef.closed.subscribe(result => {
      this.filterForm = result;
      this.generateFilterFromFilterForm();
      this.applyNameFilter(this.filter.programName);
    });
  }

  private getStatusList() {
    return Object.values(ProgramStatus);
  }

  private generateFilterFromFilterForm() {
    const filterFormValue = this.filterForm.value;
    const programNameFilter = this.filter.programName;
    this.filter = {};
    this.filter.programName = programNameFilter;
    if (filterFormValue.project.length) {
      this.filter.projects = filterFormValue.project.map((project: any) => project.projectID);
    }
    if (filterFormValue.status.length) {
      this.filter.status = filterFormValue.status;
    }
  }

  applyNameFilter(filteredText: string) {
    this.isLoadMore = true;
    this.filter.programName = filteredText;
    this.programs = this.applyFilter();
    this.generatePages(this.programs);
    this.isLoadMore = false;
    this.isLoading = false;

  }

  private applyFilter() {
    let filterResult = _.cloneDeep(this.allPrograms);
    if (this.filter.programName) {
      filterResult = this.genericSearchPipe.transform(filterResult, this.filter.programName, 'programName');
    }
    if (this.filter.projects) {
      filterResult = filterResult.filter(program => this.filter.projects.includes(program.projectID));
    }
    if (this.filter.status) {
      filterResult = filterResult.filter(program => this.filter.status.toLowerCase() === program.status.toLowerCase());
    }
    return filterResult;
  }

  filterFun = (filterText: string, appliedFilter?: any) => {
    const searchResult = this.genericSearchPipe.transform(this.allPrograms, filterText, 'programName');
    return of({ items: searchResult.slice(0, 5), nextToken: searchResult.length > 5 })
  }

  private generatePages(programList: Array<Program>) {
    this.pagination.numberOfPages = Math.ceil(programList.length / this.pagination.numberOfElementsPerPage);
    this.pagination.activePageIndex = 1;
    this.updatePagination();
  }

  updatePagination() {
    const from = (this.pagination.activePageIndex - 1) * this.pagination.numberOfElementsPerPage;
    const to = this.pagination.activePageIndex * this.pagination.numberOfElementsPerPage;
    this.pagination.items = this.programs.slice(from, to > this.programs.length ? undefined : to);
  }

  createNewProgram() {
    this.route.navigateByUrl('/program/create');
  }

  previewProgram(programID: string) {
    this.route.navigateByUrl('/program/overview/' + programID);
  }

  viewMileStones(program: Program) {
    if (program.mentees.find(mentee => mentee.id === MENTEE_ID && mentee.applicationStatus === MenteeApplicationStatus.accepted)) {
      this.route.navigate([`/program/milestone/${program.id}`]);
    } else {
      this.previewProgram(program.id);
    }
  }

}
