<div class="header-container" id="app-header">
  <div class="d-flex align-items-center flex-wrap">
    <a [routerLink]="['/']">
      <div class="header-item home" [ngClass]="{ active: activeTab === 'Home' }" (click)="changeActiveTab('Home')">
        <i class="fas fa-home"></i>
      </div>
    </a>
    <div class="header-item" [ngClass]="{ active: activeTab === 'Programs' }" (click)="navigateTo('Programs')">
      Programs
    </div>
    <div class="header-item" (click)="navigateTo('Mentors')" [ngClass]="{ active: activeTab === 'Mentors' }">
      Mentors
    </div>
    <div class="header-item" (clickOutside)="isMessageDropdownOpen = false"
      [ngClass]="{ active: activeTab === 'Messages' }">
      <span class="d-flex align-items-center" (click)="toggleMessageDropdown()">
        Messages
        <i class="fas fa-chevron-down"></i>
      </span>
      <app-searchable-dropdown class="searchable-dropdown" *ngIf="isMessageDropdownOpen"
        (choiceItem)="selectMessage($event)" [items]="messageTypes">
      </app-searchable-dropdown>
    </div>
    <div class="header-item" (click)="changeActiveTab('Forms')" [ngClass]="{ active: activeTab === 'Forms' }">
      Forms
    </div>
  </div>
  <div class="user-profile d-flex align-items-center ml-4 mb-2">
    <a [routerLink]="['/user']">
      <img [src]="user?.avatarUrl" alt="" *ngIf="user?.avatarUrl" />
      <span class="user-name">{{ user?.fullName }}</span>
    </a>
  </div>
</div>