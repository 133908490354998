<div class="mentee-overview-container">
  <ng-container *ngIf="!isLoading else loadingTemplate">
    <lfx-breadcrumbs [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>

    <div class="d-flex align-items-center mt-4">
      <span (click)="scrollToElement('Profile')" class="tab active">
        Profile
      </span>
      <span (click)="scrollToElement('Skills')" class="tab">
        Skills
      </span>
      <span (click)="scrollToElement('Demographics')" class="tab">
        Demographics
      </span>
    </div>

    <form [formGroup]="menteeProfileForm">
      <!-- Profile Tab Content -->
      <p id="Profile" class="text-uppercase scroll-elem">Profile</p>
      <div class="d-flex card mt-2">
        <div class="card-body row">
          <div class="col-12">
            <h5 class="mt-8">User Information</h5>
            <div class="form-card d-flex flex-wrap">
              <div class="col-4 pl-0 mb-3">
                <label class="input-title text-muted" for="first-name">First Name</label>
                <input type="text" formControlName="firstName" [placeholder]="'For Ex. John'" class="form-control"
                  name="first-name">
              </div>
              <div class="col-4 pl-0 mb-3">
                <label class="input-title text-muted" for="last-name">Last Name</label>
                <input type="text" formControlName="lastName" [placeholder]="'For Ex. Doe'" class="form-control"
                  name="last-name">
              </div>
              <div class="col-4 pl-0 mb-3">
                <label class="input-title text-muted" for="email">Email</label>
                <input type="email" formControlName="email" [placeholder]="'Email'" class="form-control" name="email">
              </div>
              <div class="col-4 pl-0 mb-3">
                <label class="input-title text-muted" for="phone">Phone <span class="text-danger">*</span></label>
                <input formControlName="phone" type="tel" [minlength]="minPhoneNumberLength"
                  [maxlength]="maxPhoneNumberLength" class="form-control" placeholder="123-456-7890" required
                  trim="blur" name="phone">
                <div
                  *ngIf="menteeProfileForm.controls.phone.touched && menteeProfileForm.controls.phone.dirty && menteeProfileForm.controls.phone.invalid"
                  class="mt-3 alert alert-danger">
                  <div *ngIf="menteeProfileForm.controls.phone.errors?.required">
                    Phone is required.
                  </div>
                  <div
                    *ngIf="!menteeProfileForm.controls.phone.errors?.pattern && menteeProfileForm.controls.phone.errors?.minlength">
                    Phone number must be at least 4 digits long.
                  </div>
                  <div *ngIf="menteeProfileForm.controls.phone.errors?.pattern">
                    Please enter a valid phone number.
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="col-12 row">
            <div class="col-6">
              <h5 class="mt-3">External Profile</h5>
              <div class="form-card">
                <div class="el-container d-flex  align-items-center mb-3">
                  <div class="el-title">
                    <p class="el-title-p">Connect to LinkedIn</p>
                    <p class="el-title-s">Ensure Your Project Affiliations Are Accurate By Connecting Your Account.</p>
                  </div>
                  <div class="el-icon">
                    <img src="/assets/linkedin.png" class="linkedIn" alt="LinkedIn" />
                  </div>
                  <div class="el-action">
                    <button class="btn btn-outline-primary el-button linkedin">Connect</button>
                  </div>
                </div>
                <div class="el-container d-flex  align-items-center mb-3">
                  <div class="el-title">
                    <p class="el-title-p">Connect to Github</p>
                    <p class="el-title-s">Don't Miss A Code Contribution By Linking Your GitHub Account.</p>
                  </div>
                  <div class="el-icon">
                    <img src="/assets/github.png" class="github" alt="Github" />
                  </div>
                  <div class="el-action">
                    <button class="btn btn-outline-primary el-button github">Connect</button>
                  </div>
                </div>
                <div class="el-container d-flex  align-items-center">
                  <div class="el-title">
                    <p class="el-title-p">Connect to Twitter</p>
                    <p class="el-title-s">Don't Miss A Code Contribution By Linking Your Twitter Account.</p>
                  </div>
                  <div class="el-icon">
                    <img src="/assets/twitter.png" class="twitter" alt="Twitter" />
                  </div>
                  <div class="el-action">
                    <button class="btn btn-outline-primary el-button twitter">Connect</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 d-flex flex-column align-self-stretch">
              <h5 class="mt-3">Introduction</h5>
              <div class="form-card d-flex flex-column grow">
                <p>Tell us a little bit about yourself <span
                  class="text-danger">*</span></p>
                <div class="about align-self-stretch grow">
                  <textarea class="pl-2 pt-2" formControlName="about" rows="5"
                    placeholder="Testing Mentorship Platform to experience what it is like to sign upas a mentee."></textarea>
                </div>
                <div
                  *ngIf="menteeProfileForm.controls.about.touched && menteeProfileForm.controls.about.dirty && menteeProfileForm.controls.about.invalid"
                  class="mt-3 alert alert-danger">
                  <div *ngIf="menteeProfileForm.controls.about.errors?.required">
                    About is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <h5 class="mt-3">Mailing Address</h5>
            <div class="form-card d-flex flex-wrap">
              <div class="col-6 pl-0 mb-3">
                <p>Your address is required to ensure that you are not a person or entity restricted by U.S. export
                  controls or sanction programs; and are not a resident of Cuba, Iran, North KOrea, Syria or Crimea
                  region of Ukraine.</p>
              </div>
              <div class="col-6"></div>
              <div class="col-4 pl-0 mb-3">
                <label class="input-title text-muted" for="full-address">Full Address <span
                    class="text-danger">*</span></label>
                <input type="text" formControlName="address1" [placeholder]="'Enter address here'" class="form-control"
                  name="full-address">

                <div
                  *ngIf="menteeProfileForm.controls.address1.touched && menteeProfileForm.controls.address1.dirty && menteeProfileForm.controls.address1.invalid"
                  class="mt-3 alert alert-danger">
                  <div *ngIf="menteeProfileForm.controls.address1.errors?.required">
                    Address is required.
                  </div>
                </div>
              </div>
              <div class="col-4 pl-0 mb-3">
                <label class="input-title text-muted" for="first-name">Address Line 2</label>
                <input type="text" formControlName="address2" [placeholder]="'Enter address line 2 here'"
                  class="form-control" name="email">
              </div>
              <div class="col-4 pl-0 mb-3">
                <label class="input-title text-muted" for="country">Country <span class="text-danger">*</span></label>
                <select formControlName="country" class="form-control" name="country" (change)="openStatesFields()">
                  <option *ngFor="let c of countryList" [value]="c.value">{{c.name}}</option>
                </select>

                <div
                  *ngIf="menteeProfileForm.controls.country.touched && menteeProfileForm.controls.country.dirty && menteeProfileForm.controls.country.invalid"
                  class="mt-3 alert alert-danger">
                  <div *ngIf="menteeProfileForm.controls.country.errors?.required">
                    Country is required.
                  </div>
                </div>
              </div>
              <div class="col-4 pl-0 mb-3" *ngIf="isUsSelected">
                <label class="input-title text-muted" for="country">State <span class="text-danger">*</span></label>
                <select formControlName="state" class="form-control" name="state">
                  <option *ngFor="let c of usStatesList" [value]="c.value">{{c.name}}</option>
                </select>

                <div
                  *ngIf="menteeProfileForm.controls.state.touched && menteeProfileForm.controls.state.dirty && menteeProfileForm.controls.state.invalid"
                  class="mt-3 alert alert-danger">
                  <div *ngIf="menteeProfileForm.controls.state.errors?.required">
                    State is required.
                  </div>
                </div>
              </div>
              <div class="col-4 pl-0 mb-3">
                <label class="input-title text-muted" for="city">City <span class="text-danger">*</span></label>
                <input type="text" formControlName="city" [placeholder]="'Enter city here'" class="form-control"
                  name="city">

                <div
                  *ngIf="menteeProfileForm.controls.city.touched && menteeProfileForm.controls.city.dirty && menteeProfileForm.controls.city.invalid"
                  class="mt-3 alert alert-danger">
                  <div *ngIf="menteeProfileForm.controls.city.errors?.required">
                    City is required.
                  </div>
                </div>
              </div>
              <div class="col-4 pl-0 mb-3">
                <label class="input-title text-muted" for="postal-code">Postal Code <span
                    class="text-danger">*</span></label>
                <input type="number" formControlName="postCode" [placeholder]="'Enter postal code here'"
                  class="form-control" name="postal-code">

                <div
                  *ngIf="menteeProfileForm.controls.postCode.touched && menteeProfileForm.controls.postCode.dirty && menteeProfileForm.controls.postCode.invalid"
                  class="mt-3 alert alert-danger">
                  <div *ngIf="menteeProfileForm.controls.postCode.errors?.required">
                    Postal Code is required.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 row">
            <div class="col-6">
              <h5 class="mt-3">Avatar</h5>
              <div class="form-card d-flex">
                <div class="col-6">
                  <div class="add-attachment d-flex align-items-center flex-column"><span>Drop files to
                      upload</span><span>Or</span><button type="button" class="btn btn-primary custom-btn">Browse
                      Files</button><span>File type: JPG, PNG, SVG</span><span>Max
                      size:2mb</span><input type="file" name="uploadFile" id="avatar"
                      accept="image/png, image/jpeg, image/svg+xml" formControlName="logoUrl"
                      (change)="handleFiles($event, true)" /></div>
                </div>
                <div class="col-6" [hidden]="!menteeProfileForm.controls.logoUrl.value">
                  <div class="add-attachment d-flex flex-column align-items-center justify-content-center">
                    <img id="avatarImage" src="/assests/no_logo_uploaded.png" class="avatar_img" alt="avatar" />
                  </div>
                  <div class="close-button" (click)="clearAvatar()"><img src="/assets/icon_close.png" /></div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <h5 class="mt-3">Upload your Resume</h5>
              <div class="form-card d-flex">
                <div class="col-6">
                  <div class="add-attachment d-flex align-items-center flex-column"><span>Drop files to
                      upload</span><span>Or</span><button type="button" class="btn btn-primary custom-btn">Browse
                      Files</button><input type="file" name="uploadResume" formControlName="resumeUrl" id="resume"
                      (change)="handleFiles($event, false)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p id="Skills" class="text-uppercase scroll-elem">Skills</p>
      <div class="d-flex card mt-2">
        <div class="card-body">
          <div class="form-card d-flex flex-column mb-3">
            <p>1. What skills are you currently proficient in? List up to five skills for each category:programming
              languages, operating system and technologies. <span class="text-danger">*</span></p>
            <lfx-user-skills [parentForm]="menteeProfileForm" [controlName]="'skills'">
            </lfx-user-skills>

            <div
              *ngIf="menteeProfileForm.controls.skills.touched && menteeProfileForm.controls.skills.dirty && menteeProfileForm.controls.skills.invalid"
              class="mt-3 alert alert-danger">
              <div *ngIf="menteeProfileForm.controls.skills.errors?.required">
                Skills is required.
              </div>
            </div>
          </div>
          <div class="form-card d-flex flex-column mb-3">
            <p>2. What areas do you want to improve in? <span class="text-danger">*</span></p>
            <lfx-user-skills [parentForm]="menteeProfileForm" [controlName]="'improvementSkills'">
            </lfx-user-skills>

            <div
              *ngIf="menteeProfileForm.controls.improvementSkillsched && menteeProfileForm.controls.improvementSkills.dirty && menteeProfileForm.controls.improvementSkills.invalid"
              class="mt-3 alert alert-danger">
              <div *ngIf="menteeProfileForm.controls.improvementSkills.errors?.required">
                Improvement Skills is required.
              </div>
            </div>
          </div>
          <div class="form-card d-flex flex-column mb-3">
            <p>3. Anything else you want mentors to know? <span class="text-danger">*</span></p>
            <textarea class="pl-2 pt-2" formControlName="comments" rows="5"></textarea>

            <div
              *ngIf="menteeProfileForm.controls.comments.touched && menteeProfileForm.controls.comments.dirty && menteeProfileForm.controls.comments.invalid"
              class="mt-3 alert alert-danger">
              <div *ngIf="menteeProfileForm.controls.comments.errors?.required">
                Comments is required.
              </div>
            </div>
          </div>
        </div>
      </div>

      <p id="Demographics" class="text-uppercase scroll-elem">Demographics (Optional)</p>
      <div class="d-flex card mt-2">
        <div class="card-body">
          <div class="form-card d-flex flex-column mb-3">
            <p class="mt-2 mb-2">We want to encourage greater diversity within the open source community, and
              demographic information helps us achieve that goal. Demographic information is collected for the purpose
              of compiling, analyzing and disclosing aggregate statistics regarding diversity of participation in open
              source project and communities(including in Mentorship projects and Mentorship opportunities), to help
              track progress towards meeting our commitment to diversity initiatives.</p>
            <p class="mt-2 mb-2">Consent to provide demographic information for the purpose listed above is optional
              and
              purely voluntary, and is provided by selecting an option from the drop-down for the corresponding
              category. Demographic information is considered confidential and private, and will not be shared with
              mentors as part of your registration details when you are considered for mentorships; nor will it be
              used
              to subject you to adverse treatment.</p>
            <p class="mt-2 mb-2">Should you choose to provide any demographics information, you many request removal
              of
              that information from Mentorship at any time by writing to<a
                href="mailto:privacy@linuxfoundation.org">privacy@linuxfoundation.org</a>.</p>
            <div class="mt-3 d-flex flex-wrap">
              <div class="col-4 pl-0 mb-3">
                <label class="input-title text-muted" for="age">{{ menteeData.ageQuestion.question }} </label>
                <select formControlName="age" class="form-control" name="age">
                  <option *ngFor="let c of menteeData.ageQuestion.options" [value]="c.value">{{c.name}}</option>
                </select>
              </div>
              <div class="col-4 pl-0 mb-3">
                <label class="input-title text-muted" for="race">{{ menteeData.racesQuestion.question }} </label>
                <select formControlName="race" class="form-control" name="race">
                  <option *ngFor="let c of menteeData.racesQuestion.options" [value]="c.value">{{c.name}}</option>
                </select>
              </div>
              <div class="col-4 pl-0 mb-3">
                <label class="input-title text-muted" for="gender">{{ menteeData.genderQuestion.question }} </label>
                <select formControlName="gender" class="form-control" name="gender">
                  <option *ngFor="let c of menteeData.genderQuestion.options" [value]="c.value">{{c.name}}</option>
                </select>
              </div>
              <div class="col-4 pl-0 mb-3">
                <label class="input-title text-muted" for="income">{{ menteeData.incomeConsentQuestion.question }}
                </label>
                <select formControlName="income" class="form-control" name="income">
                  <option *ngFor="let c of menteeData.incomeConsentQuestion.options" [value]="c.value">{{c.name}}
                  </option>
                </select>
              </div>
              <div class="col-4 pl-0 mb-3">
                <label class="input-title text-muted" for="educationLevel">{{ menteeData.educationLevelQuestion.question
                  }}
                </label>
                <select formControlName="educationLevel" class="form-control" name="educationLevel">
                  <option *ngFor="let c of menteeData.educationLevelQuestion.options" [value]="c.value">{{c.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="action-buttons d-flex pt-3 pb-3 justify-content-end">
      <button class="btn  mr-2 skip" (click)="skipProfile()">Skip</button>
      <button class="btn btn-outline-primary mr-2 back" (click)="goBack()">Back</button>
      <button class="btn btn-primary finish" (click)="createMenteeApplication()">{{submitBtnLabel}}</button>
    </div>
  </ng-container>
</div>

<ng-template #loadingTemplate>
  <lfx-loading></lfx-loading>
</ng-template>
