import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { SkillsService } from 'src/app/core/services/skills.service';

@Component({
  selector: 'lfx-user-skills',
  templateUrl: './user-skills.component.html',
  styleUrls: ['./user-skills.component.scss']
})
export class UserSkillsComponent implements OnInit {
  @ViewChild('instance', { static: true }) instance!: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  @Input() controlName! : string;
  @Input() parentForm!: FormGroup;

  constructor(
  private skillsService: SkillsService
  ) { }

  get skills () {
    return this.parentForm.get(this.controlName) as FormArray;
  }

  ngOnInit() {
  }

  search: any = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => {
        return this.skillsService.search(term);
      })
    );
  }
  addSkill(skillInput: HTMLInputElement) {
    if (!skillInput.value.length || this.isAlreadyAdded(skillInput.value)) {
      return
    }
    this.skills.push(new FormControl(skillInput.value));
    skillInput.value = '';
  }

  private isAlreadyAdded(value: string) {
    return this.skills.controls.filter((skill) => skill.value === value).length;
  }

  removeSkill(skillIndex: number) {
    this.skills.removeAt(skillIndex);
  }


}
