import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { FilterPopupComponent } from './filter-popup/filter-popup.component';
import { IconLoaderComponent } from './icon-loader/icon-loader.component';
import { InputSearchComponent } from './input-search/input-search.component';
import { PaginationComponent } from './pagination/pagination.component';
import { CardNumberViewComponent } from './card-number-view/card-number-view.component';
import { LoadingComponent } from './loading/loading.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlankLandingPageComponent } from './blank-landing-page/blank-landing-page.component';
import { SearchableDropdownComponent } from './searchable-dropdown/searchable-dropdown.component';
import { HeaderComponent } from './header/header.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { RouterModule } from '@angular/router';
import { AutoCompleteDropdownComponent } from './auto-complete-dropdown/auto-complete-dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericSearchPipe } from '../core/pipes/generic-search.pipe';
import { NgBootstrapModule } from './ng-bootstrap.module';
import { SkillsService } from '../core/services/skills.service';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CommonTableComponent } from './common-table/common-table.component';
import { CustomAdapter, CustomDateParserFormatter } from '../core/services/ngb-date.service';
import { EllipsisDirective } from '../core/directives/ellipsis.directive';
import { TranslateModule } from '@ngx-translate/core';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { UserSkillsComponent } from './user-skills/user-skills.component';
import { FileUploadService } from '../core/services/file-upload.service';
import { PrerequisitesComponent } from './prerequisites/prerequisites.component';
import { QuillModule } from 'ngx-quill';
import { ProgramOverviewHeaderComponent } from '../mentorship/component/program-overview-header/program-overview-header.component';

@NgModule({
  imports: [
    CommonModule,
    NgBootstrapModule,
    ClickOutsideModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    VirtualScrollerModule,
    QuillModule
  ],
  declarations: [
    BlankLandingPageComponent,
    ImageCarouselComponent,
    SearchableDropdownComponent,
    HeaderComponent,
    AutoCompleteDropdownComponent,
    GenericSearchPipe,
    CommonTableComponent,
    EllipsisDirective,
    LoadingComponent,
    CardNumberViewComponent,
    PaginationComponent,
    InputSearchComponent,
    IconLoaderComponent,
    FilterPopupComponent,
    BreadcrumbsComponent,
    UserSkillsComponent,
    PrerequisitesComponent,
    ProgramOverviewHeaderComponent
  ],
  exports: [
    NgBootstrapModule,
    ClickOutsideModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    VirtualScrollerModule,

    BlankLandingPageComponent,
    ImageCarouselComponent,
    SearchableDropdownComponent,
    HeaderComponent,
    AutoCompleteDropdownComponent,
    CommonTableComponent,
    GenericSearchPipe,
    EllipsisDirective,
    LoadingComponent,
    CardNumberViewComponent,
    PaginationComponent,
    InputSearchComponent,
    IconLoaderComponent,
    FilterPopupComponent,
    BreadcrumbsComponent,
    UserSkillsComponent,
    PrerequisitesComponent,
    ProgramOverviewHeaderComponent,
    QuillModule
  ],
  providers: [
    GenericSearchPipe,
    SkillsService,
    FileUploadService,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class SharedModule { }
