import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { from } from 'rxjs';
import { APIService } from 'src/app/API.service';
import { MENTEE_ID, taskStatus } from 'src/app/core/constants';
import { MenteeController } from 'src/app/core/controllers/mentee.controller';
import { ProgramController } from 'src/app/core/controllers/program.controller';
import { Program } from 'src/app/models/program';
import { MilestoneDetailsComponent } from '../milestone-details/milestone-details.component';
@Component({
  selector: 'lfx-task-management',
  templateUrl: './task-management.component.html',
  styleUrls: ['./task-management.component.scss']
})
export class TaskManagementComponent implements OnInit {
  milestones = new Array();
  isLoading: boolean = false;
  todo = new Array<any>();
  doing = new Array<any>();
  done = new Array<any>();
  @Input() program!: Program;

  breadcrumbs = [
    {
      name: "programs",
      url: '/programs'
    },
    {
      name: "Milestone",
      url: ''
    }
  ];
  allTasks = [];
  constructor(
    private api: APIService,
    private programController: ProgramController,
    private menteeController: MenteeController,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,

  ) { }

  ngOnInit() {
    const programID = this.activatedRoute.snapshot.paramMap.get('programId');
    if (programID) {
      this.getProgram(programID);
    }

  }

  getProgram(programId: string) {
    this.isLoading = true;
    this.programController.getProgramByIdGraphql(programId).subscribe((program: any) => {
      this.program = program;
      this.breadcrumbs.splice(1, 0, {
        name: this.program.programName,
        url: `/program/overview/${this.program.id}`
      })
      this.getAllMilestones();
    });
  }

  private getAllMilestones() {
    this.isLoading = true;
    this.menteeController.getMenteeMilestones({
      userID: MENTEE_ID,
      programID: this.program.id
    }).subscribe((milestones: any) => {
      this.isLoading = false;
      this.milestones = milestones;
      this.initBoard();
    }, _ => {
      this.isLoading = false;
      this.milestones = [];
      this.initBoard();
    });
  }

  private initBoard() {
    this.todo = [];
    this.doing = [];
    this.done = [];
    this.milestones.forEach((milestone, i) => {
      milestone.completedCount = this.countCompletedTasks(milestone);
      const isDone = milestone.milestoneTasks?.every((item: any) => item.taskDetail.status === taskStatus.completed);
      const isDoing = milestone.milestoneTasks?.some((item: any) => item.taskDetail.status === taskStatus.submitted);
      if (isDone) {
        this.done.push(milestone);
      } else if (isDoing) {
        this.doing.push(milestone);
      } else {
        this.todo.push(milestone);
      }
    });
  }
  private countCompletedTasks(milestone: any) {
    if (milestone.milestoneTasks && milestone.milestoneTasks.length) {
      return milestone.milestoneTasks?.filter((task: any) => task?.taskDetail?.status === taskStatus.completed).length;
    }
    return 0;
  }

  viewMilestone(milestone: any) {
    const modalRef = this.modalService.open(MilestoneDetailsComponent, {
      size: "xl",
      centered: true
    });

    const instance = modalRef.componentInstance;
    instance.milestone = milestone;

    modalRef.closed.subscribe(milestone => {
      this.initBoard();
    });
  }

}
