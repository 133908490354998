<div class="dashboard-container px-80">
  <lfx-breadcrumbs [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>

  <div class="card flex-grow-1 mb-5">
    <div class="card-header d-flex px-0 pb-0">
      <div class="tab active" (click)="changeTab('myCurrentProgram')"
        [ngClass]="{'active': selectedTab === 'myCurrentProgram'}">
        My Current Programs
      </div>
      <div class="tab" (click)="changeTab('myCompletedProgram')"
        [ngClass]="{'active': selectedTab ===  'myCompletedProgram'}">
        My Completed Programs
      </div>
      <div class="tab" (click)="changeTab('myApplications')" [ngClass]="{'active': selectedTab === 'myApplications'}">
        My Applications
      </div>
      <div class="tab-spacer"></div>
      <div class="actions">
        <lfx-input-search [filterFun]="filterFun" class="mr-3" (filter)="false"></lfx-input-search>
        <button type="button" class="btn btn-outline-primary custom-btn" (click)="viewAllPrograms()">View All
          Programs</button>
      </div>
    </div>
    <div class="card-body" *ngIf="selectedTab === 'myCurrentProgram'">
      <app-common-table class="h-100" [tableConfiguration]="tableConfig" [tableRecords]="applications">
      </app-common-table>
    </div>
    <div class="card-body" *ngIf="selectedTab === 'myCompletedProgram'">
      <app-common-table class="h-100" [tableConfiguration]="tableConfig_completedProgram" [tableRecords]="applications">
      </app-common-table>
    </div>
    <div class="card-body" *ngIf="selectedTab === 'myApplications'">
      <app-common-table class="h-100" [tableConfiguration]="tableConfig_myApplication" [tableRecords]="applications">
      </app-common-table>
    </div>
  </div>
  <div class="spacer"></div>
</div>


<ng-template #projectNameTemplate let-element>
  <span class="text-black weight-600">
    {{element.rowData.program?.project?.projectName}}
  </span>
</ng-template>

<ng-template #programTemplate let-element>
  <span class="d-flex align-items-center text-primary w-100 mw-100 cursor-pointer"
    (click)="previewProject(element.rowData.program.id)">
    {{element.rowData.program.programName}}
  </span>
</ng-template>


<ng-template #statusTemplate let-element>
  <!-- <span class="status mw-100 w-100 d-flex align-items-center justify-content-center" lfxEllipsis [ngClass]="{
    'inprogress-status': element.rowData.applicationStatus.toLowerCase() === programStatus.pending.toLowerCase(),
    'completed-status': element.rowData.applicationStatus.toLowerCase() === programStatus.completed.toLowerCase(),
    'declined-status': element.rowData.applicationStatus.toLowerCase() === programStatus.declined.toLowerCase(),
    'accepted-status': element.rowData.applicationStatus.toLowerCase() === programStatus.acceptingApplication.toLowerCase() || element.rowData.applicationStatus.toLowerCase() === programStatus.accepted.toLowerCase()
  }"> 
    {{element.rowData.applicationStatus | titlecase}}
  </span> -->
  <div class="status mw-100 w-100 d-flex align-items-center justify-content-center m-auto"
    *ngIf="((element.rowData.applicationStatus !== 'draft') && (selectedTab !== 'myApplications'))" lfxEllipsis
    [ngClass]="{
    'inprogress-status': element.rowData.applicationStatus.toLowerCase() === programStatus.pending.toLowerCase(),
    'completed-status': element.rowData.applicationStatus.toLowerCase() === programStatus.completed.toLowerCase(),
    'declined-status': element.rowData.applicationStatus.toLowerCase() === programStatus.declined.toLowerCase(),
    'accepted-status': element.rowData.applicationStatus.toLowerCase() === programStatus.acceptingApplication.toLowerCase() || element.rowData.applicationStatus.toLowerCase() === programStatus.accepted.toLowerCase()
  }"></div>
  <div class="status mw-100 w-100 d-flex align-items-center justify-content-center m-auto"
    *ngIf="element.rowData.applicationStatus == 'draft'" lfxEllipsis [ngClass]="{
    'inprogress-status': element.rowData.applicationStatus == 'draft'}"></div>
  <div class="status mw-100 w-100 d-flex align-items-center justify-content-center m-auto"
    *ngIf="((element.rowData.applicationStatus.toLowerCase() === programStatus.pending.toLowerCase()) && (selectedTab === 'myApplications'))"
    lfxEllipsis>
    <i class="fa fa-check" aria-hidden="true"></i>
  </div>
</ng-template>

<ng-template #actionTemplate let-element>
  <div class="d-flex justify-content-center align-items-center w-100">
    <a type="button" class="py-1 px-3 size-16" (click)="viewMileStones(element.rowData.program.id)">View</a>
  </div>
</ng-template>

<ng-template #actionTemplate_cp let-element>
  <div class="d-flex justify-content-center align-items-center w-100">
    <a class="py-1 px-3"><img src="assets/contactAdmin.png"></a>
    <a class="py-1 px-3"><img src="assets/downloadIcon.png"></a>
    <!-- <button type="button" class="py-1 px-3 size-12"
      (click)="navigateToMenteeApplication(element.rowData.id)">Complete Task</button> -->
  </div>
</ng-template>

<ng-template #actionTemplate_myApp let-element>
  <div class="d-flex justify-content-center align-items-center w-100">
    <a type="button" class="py-1 px-3 size-16" (click)="navigateToMenteeApplication(element.rowData.id)">View</a>
  </div>
</ng-template>