export const MENTEE_ID = '0034100000dFd99AAC' // '19cdca5b-bc94-492c-bb97-4be0548e2b1c';
// 'd41db3ce-abc5-4ed8-bc5a-d47f44c4d383';
// export const MENTEE_ID = '65498-466577-46636-dh5648';

export const MAX_TASK_NAME_LENGTH = 20;
export const MAX_TASK_DESCRIPTION_LENGTH = 500;
export const MIN_TITLE_LENGTH = 3;
export const MAX_TITLE_LENGTH = 100;
export const MAX_DESCRIPTION_LENGTH = 1500;

export const PHONE_NUMBER_PATTERN = '^[0-9-+s()]*$';
export const MIN_PHONE_NUMBER_LENGTH = 4;
export const MAX_PHONE_NUMBER_LENGTH = 12;

export const ProgramStatus = {
    draft: "draft",
    pending: "pending",
    completed: "completed",
    accepted: "accepted",
    declined: "declined",
    acceptingApplication: "Accepting Applications"
}

export const MenteeApplicationStatus = {
    draft: 'draft',
    submitted: 'submitted',
    pending: "pending",
    accepted: "accepted",
    declined: "declined",
    graduated: "graduated"
}

export const MentorInvitationStatus = {
    pending: "pending",
    accepted: "accepted",
    declined: "declined"
}

export const mentorReviewStatus = {
    pending: "pending",
    accepted: "accepted",
    declined: "declined"
}

export const taskStatus = {
    pending: 'pending',
    submitted: 'submitted',
    completed: 'completed',
    uploaded: 'uploaded'
}

export const MilestoneActivityActionType = {
    submitted: 'submitted',
    feedback: 'feedback',
    comment: 'comment'
}

export const SubmissionTypes = {
    url: 'URL',
    pdf: 'PDF',
    excel: 'Excel',
    text: 'Text'
}
