import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenteeDashboardComponent } from './dashboard/pages/mentee-dashboard/mentee-dashboard.component';
import { ProgramListViewComponent } from './mentorship/pages/program-list-view/program-list-view.component';
import { TaskManagementComponent } from './task-management/pages/task-management/task-management.component';
import { MentorsPageComponent } from './dashboard/pages/mentors-page/mentors-page.component';
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    component: MenteeDashboardComponent,
    path: '',
    canActivate: [AuthGuard]
  },
  {
    path: 'programs',
    component: ProgramListViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'mentee',
    loadChildren: () => import('./mentee/mentee.module').then(m => m.MenteeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'program/milestone/:programId',
    component: TaskManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    component: MentorsPageComponent,
    path: 'mentors',
    canActivate: [AuthGuard]
  },
  {
    path: 'program',
    loadChildren: () => import('./mentorship/mentorship.module').then(m => m.MentorshipModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
