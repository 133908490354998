<div class="program-overview-container">
  <ng-container *ngIf="!isLoading else loadingTemplate">
    <lfx-breadcrumbs [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>
    <lfx-program-overview-header *ngIf="program" [program]="program"></lfx-program-overview-header>

    <div class="d-flex card mt-4" id="Prerequisites">
      <div class="card-header d-flex flex-column justify-content-between">
        <span class="section-title size-20 weight-600">
          Prerequisites
        </span>
        <span class="section-subtitle">Please complete the prerequisite tasks listed below for your application to be
          considered for this mentorship program.</span>
      </div>
      <div class="card-body">
        <lfx-prerequisites [preRequisites]="preRequisiteTasks"></lfx-prerequisites>
      </div>
    </div>

    <div class="action-buttons d-flex pt-3 pb-3 justify-content-end">
      <button class="btn  btn-outline-secondary px-3 mr-2 cancel" (click)="goTo('cancel')">Cancel</button>
      <button class="btn btn-outline-primary px-3 mr-2 save"
        *ngIf="((menteeApplication.applicationStatus === menteeApplicationStatus.draft))" (click)="goTo('save')">Save & Close</button>
      <button class="btn btn-primary px-3 submit" *ngIf="((menteeApplication.applicationStatus === menteeApplicationStatus.draft))"
        [disabled]="!isUploadAll()" (click)="goTo('submit')">Submit
        Application</button>
    </div>
  </ng-container>
</div>

<ng-template #loadingTemplate>
  <lfx-loading></lfx-loading>
</ng-template>