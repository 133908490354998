<div class="program-overview-container">
  <ng-container *ngIf="!isLoading else loadingTemplate">
    <lfx-breadcrumbs [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>

    <lfx-program-overview-header [program]="program"></lfx-program-overview-header>
    <div class="d-flex align-items-center my-4">
      <span (click)="scrollToElement('Overview')" class="tab active">
        Overview
      </span>
      <span (click)="scrollToElement('Prerequisites')" class="tab">
        Prerequisites
      </span>
      <span (click)="scrollToElement('Admin')" class="tab">
        Co-Admin
      </span>
      <span (click)="scrollToElement('Mentor')" class="tab">
        Mentor
      </span>
    </div>
    <h4 class="section-header size-24 weight-600">Overview</h4>
    <div class="d-flex" id="Overview">
      <div class="card px-0 mr-3 col-6">
        <div class="card-header size-18 weight-600 d-flex align-items-center justify-content-between">
          Description
        </div>
        <div class="card-body">
          {{program.description}}
        </div>
      </div>
      <div class="card px-0 flex-grow-1">
        <div class="card-header size-18 weight-600 d-flex align-items-center justify-content-between">
          Learning Objectives and Outcomes
        </div>
        <div class="card-body d-flex flex-column">
          <div class="learn-objective-item d-flex flex-column"
            *ngFor="let learnObjective of program.programDetail.learningObjectives| slice : 0:(showAllLearnObjectives ? program.programDetail.learningObjectives.length : 1); let index = index">
            <span class="learn-objective-title size-18">{{(index + 1)+'. '+learnObjective.title}}</span>
            <span class="learn-objective-text">{{learnObjective.text}}</span>
          </div>
          <span class="text-primary cursor-pointer mt-5"
            *ngIf="program.programDetail.learningObjectives.length > 1 && !showAllLearnObjectives"
            (click)="showAllLearnObjectives = true">View more</span>
        </div>
      </div>
    </div>
    <div class="d-flex card mt-4">
      <div class="card-header terms-header d-flex px-0">
        <div class="tab">
          Available Terms
        </div>
        <div class="tab-spacer"></div>
      </div>
      <div class="card-body">
        <app-common-table [tableConfiguration]="termConfig" [tableRecords]="program.terms"></app-common-table>
      </div>
    </div>
    <div class="d-flex card mt-4" *ngIf="program.programDetail.relevantLinks?.length">
      <div class="card-header d-flex justify-content-between">
        <span class="section-title size-20 weight-600">
          Relevant Links & Files
        </span>
      </div>
      <div class="card-body">
        <div class="grid-container">
          <div class="view-card p-3 text-primary" *ngFor="let link of program.programDetail.relevantLinks">
            <a [href]="link.url" target="_blank" rel="noopener noreferrer"> {{link.title}}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex card mt-4" *ngIf="program.programDetail.requiredSkillsAndTechnologies.length">
      <div class="card-header d-flex justify-content-between">
        <span class="section-title size-20 weight-600">
          Required Skills and Technologies
        </span>
      </div>
      <div class="card-body">
        <div class="d-flex align-items-center flex-wrap">
          <div class="view-card px-3 py-2 text-light-black size-14 mr-2 mb-2"
            *ngFor="let skill of program.programDetail.requiredSkillsAndTechnologies">
            {{skill}}
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex card mt-4" id="Prerequisites" *ngIf="program.preRequisiteTasks.length">
      <div class="card-header d-flex justify-content-between">
        <span class="section-title size-20 weight-600">
          Prerequisites
        </span>
      </div>
      <div class="card-body">
        <app-common-table [tableConfiguration]="prerequisitesConfig" [tableRecords]="program.preRequisiteTasks">
        </app-common-table>
      </div>
    </div>

    <div class="d-flex card mt-4" id="Admin" *ngIf="program.coAdmins?.length">
      <div class="card-header d-flex justify-content-between">
        <span class="section-title size-20 weight-600">
          Co-Admins
        </span>
      </div>
      <div class="card-body">
        <app-common-table [tableConfiguration]="adminConfig" [tableRecords]="program.coAdmins ? program.coAdmins : []">
        </app-common-table>
      </div>
    </div>

    <div class="d-flex card mt-4" id="Mentor" *ngIf="program.mentors.length">
      <div class="card-header d-flex justify-content-between">
        <span class="section-title size-20 weight-600">
          Mentors
        </span>
      </div>
      <div class="card-body">
        <app-common-table [tableConfiguration]="mentorConfig" [tableRecords]="program.mentors"></app-common-table>
      </div>
    </div>

    <div class="publish-container p-3 my-4">
      <div class="view-card p-4 d-flex align-items-center">
        <div class="card-item d-flex flex-column" *ngFor="let publishType of program.programDetail.publish">
          <span class="publish-title size-14 weight-600">{{'publish-types.'+publishType.publishTo | translate}}:</span>
          <span class="link text-primary" lfxEllipsis>{{publishType.url}}</span>
        </div>
      </div>
    </div>
    <br>
  </ng-container>
</div>

<ng-template #loadingTemplate>
  <lfx-loading></lfx-loading>
</ng-template>

<ng-template #termNameTemplate let-element>
  <span class="term-name mw-100 w-100 my-auto" lfxEllipsis>{{element.rowData[element.columnConfig.key]}}</span>
</ng-template>

<ng-template #descriptionTemplate let-element>
  <span class="date-text mw-100 w-100 pl-3" lfxEllipsis>{{element.rowData[element.columnConfig.key]}}</span>
</ng-template>

<ng-template #dateTemplate let-element>
  <span class="date-text mw-100 w-100" lfxEllipsis>
    {{(element.rowData[element.columnConfig.key+'StartDate'] | date:'mediumDate') +
    ' - ' +
    (element.rowData[element.columnConfig.key+'EndDate'] | date: 'mediumDate')}}
  </span>
</ng-template>

<ng-template #usernameTemplate let-element>
  <div class="d-flex align-items-center w-100">
    <img class="user-image mr-4"
      [src]="element.rowData.imageUrl ? element.rowData.imageUrl :'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+element.rowData.firstName.toLowerCase().charAt(0)+'.png'"
      alt="">
    <span class="user-name" lfxEllipsis>{{element.rowData['firstName'] + ' ' + element.rowData['lastName']}}</span>
  </div>
</ng-template>

<ng-template #statusTemplate let-element>
  <span class="status mw-100 w-100 my-auto" [ngClass]="{'pending-status': element.rowData[element.columnConfig.key]?.toLowerCase() === 'pending',
'accept-status': element.rowData[element.columnConfig.key]?.toLowerCase() === 'accepted',
'declined-status': element.rowData[element.columnConfig.key]?.toLowerCase() === 'declined'}"
    lfxEllipsis>{{element.rowData[element.columnConfig.key] | titlecase}}</span>
</ng-template>

<ng-template #termsActionsTemplate let-element>
  <div class="d-flex justify-content-center align-items-center w-100">
    <button type="button" class="btn btn-outline-primary custom-btn mr-3" (click)="applyAsMentee()">Apply As
      Mentee</button>
    <button type="button" class="btn btn-outline-primary custom-btn">Volunteer As Mentor</button>
  </div>
</ng-template>

<ng-template #contactTemplate let-element>
  <div class="d-flex justify-content-center align-items-center w-100 my-auto">
    <button type="button" class="btn btn-outline-primary py-1 px-3 ml-1">Email</button>
  </div>
</ng-template>

<ng-template #prerequisitesActionsTemplate let-element>
  <div class="d-flex justify-content-center align-items-center w-100">
    <button type="button" class="btn btn-outline-primary py-1 px-3">View</button>
  </div>
</ng-template>