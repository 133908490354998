import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MilestoneCardComponent } from './components/milestone-card/milestone-card.component';
import { MilestoneDetailsComponent } from './pages/milestone-details/milestone-details.component';
import { TaskManagementComponent } from './pages/task-management/task-management.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DragDropModule
  ],
  declarations: [
    TaskManagementComponent,
    MilestoneCardComponent,
    MilestoneDetailsComponent
  ]
})
export class TaskManagementModule { }
