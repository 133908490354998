import { MilestoneDetailsComponent } from './../../pages/milestone-details/milestone-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'lfx-milestone-card',
  templateUrl: './milestone-card.component.html',
  styleUrls: ['./milestone-card.component.scss']
})
export class MilestoneCardComponent implements OnInit {

  @Input() milestone!: any;
  constructor(private x: NgbModal) { }

  ngOnInit() {
    this.milestone.progress = this.getMilestoneProgress();
  }

  getRemainDays() {
    const today = moment();
    const date = moment(this.milestone.dueDate);
    return date.diff(today, 'days') + 1;
  }

  createNewTask() {
    const modalRef = this.x.open(MilestoneDetailsComponent, {
      size: 'xl',
      modalDialogClass: 'modal-max-height',
    });
    const instance = modalRef.componentInstance;
    instance.milestone = this.milestone;
  }

  getMilestoneProgress() {
    let progress = 0;
    let tasksProgress = 0;
    this.milestone.milestoneTasks?.forEach((e: any) => {
      const taskProgress = e.taskDetail.progress ? e.taskDetail.progress : 0;
      tasksProgress += taskProgress;
    });
    if (this.milestone.milestoneTasks !== null) {
      progress = tasksProgress / this.milestone?.milestoneTasks.length;
    }
    return progress ? Math.round(progress) : 0;
  }

}
