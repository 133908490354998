import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { AuthHttpInterceptor } from './core/interceptor/auth-http-interceptor';
import { MentorshipModule } from './mentorship/mentorship.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QuillModule } from 'ngx-quill';

import { APP_BASE_HREF } from '@angular/common';
import { MenteeModule } from './mentee/mentee.module';
import { TaskManagementModule } from './task-management/task-management.module';

import { AuthModule } from '@auth0/auth0-angular';
import { environment as env } from 'src/environments/environment';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    DashboardModule,
    MentorshipModule,
    MenteeModule,
    SharedModule,
    TaskManagementModule,
    BrowserAnimationsModule,
    NgbModule,
    AmplifyAngularModule,
    QuillModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AuthModule.forRoot({
      ...env.auth0,
      useRefreshTokens: true,
      scope: 'access:api openid email profile offline_access'
    }),
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    AmplifyService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
