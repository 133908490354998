import { NgModule } from '@angular/core';
import { NgbAccordionModule, NgbCarouselModule, NgbDatepickerModule, NgbModalModule, NgbNavModule, NgbPopoverModule, NgbProgressbarModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    exports: [
        NgbCarouselModule,
        NgbTooltipModule,
        NgbNavModule,
        NgbTypeaheadModule,
        NgbDatepickerModule,
        NgbModalModule,
        NgbPopoverModule,
        NgbProgressbarModule,
        NgbAccordionModule
    ],
})
export class NgBootstrapModule { }
