import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MenteeRoutingModule } from './mentee-routing.module';
import { MenteeApplicationProfileComponent } from './pages/mentee-application-profile/mentee-application-profile.component';

@NgModule({
  imports: [CommonModule, SharedModule, MenteeRoutingModule],
  declarations: [MenteeApplicationProfileComponent],
})
export class MenteeModule { }
