export const MENTEE_PROFILE_STATIC_DATA = {
  ageQuestion : {
    question: 'How old are you?',
    options: [
      {
        name: 'Select',
        value: '',
      },
      {
        name: '19 or younger',
        value: '-19',
      },
      {
        name: '20-39',
        value: '20-39',
      },
      {
        name: '40-60',
        value: '40-60',
      },
      {
        name: '61 or older',
        value: '61 or older',
      },
      {
        name: `I don't want to provide`,
        value: 'IDonotWantToProvide',
      },
    ],
  },
  genderQuestion : {
    question: 'Which gender do you identify with?',
    options: [
      {
        name: 'Select',
        value: '',
      },
      {
        name: 'Male',
        value: 'male',
      },
      {
        name: 'Female',
        value: 'female',
      },
      {
        name: 'Non-binary',
        value: 'nonBinary',
      },
      {
        name: `I don't want to provide`,
        value: 'IDonotWantToProvide',
      },
    ],
  },
  incomeConsentQuestion : {
    question: 'Which socioeconomic class do you identify with?',
    options: [
      {
        name: 'Select',
        value: '',
      },
      {
        name: 'Working class',
        value: 'workingClass',
      },
      {
        name: 'Lower middle class',
        value: 'lowerMiddleClass',
      },
      {
        name: 'Upper middle class',
        value: 'upperMiddleClass',
      },
      {
        name: 'Upper class',
        value: 'upperClass',
      },
      {
        name: `I don't want to provide`,
        value: 'IDonotWantToProvide',
      },
    ],
  },
  educationLevelQuestion : {
    question: 'What is your education level?',
    options: [
      {
        name: 'Select',
        value: '',
      },
      {
        name: 'Some high school',
        value: 'someHighSchool',
      },
      {
        name: 'Some college/technical training',
        value: 'someCollege',
      },
      {
        name: 'Completed college',
        value: 'college',
      },
      {
        name: `Completed master's degree`,
        value: 'masters',
      },
      {
        name: 'Completed Ph.D',
        value: 'phd',
      },
      {
        name: `I don't want to provide`,
        value: 'IDonotWantToProvide',
      },
    ],
  },
  racesQuestion : {
    question: 'What is your racial or ethnic identity?',
    options: [
      {
        name: 'Select',
        value: '',
      },
      {
        name: 'White',
        value: 'white',
      },
      {
        name: 'Hispanic or Latino',
        value: 'hispanicOrLatino',
      },
      {
        name: 'Black or African-American',
        value: 'blackOrAfricanAmerican',
      },
      {
        name: 'American Indian or Alaskan Native',
        value: 'americanIndianOrAlaskanNative',
      },
      {
        name: 'Asian',
        value: 'asian',
      },
      {
        name: 'Native Hawaiian or other Pacific islander',
        value: 'NativeHawaiianOrOtherPacificIslander',
      },
      {
        name: 'Two or more races',
        value: 'twoOrMoreRaces',
      },
      {
        name: `I don't want to provide`,
        value: 'IDonotWantToProvide',
      },
    ],
  }
}
