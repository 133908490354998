import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenteeApplicationStatus } from 'src/app/core/constants';
import { Utilities } from 'src/app/core/utilities';
import { Program } from 'src/app/models/program';
import { Term } from 'src/app/models/term';

@Component({
  selector: 'lfx-program-overview-header',
  templateUrl: './program-overview-header.component.html',
  styleUrls: ['./program-overview-header.component.scss']
})
export class ProgramOverviewHeaderComponent implements OnInit {

  @Input() program!: Program;
  activeTerms!: Array<Term>;
  utilities = new Utilities();
  acceptedMenteeCount: number = 0;
  menteeApplicationCount: number = 0;
  constructor(
  ) { }

  ngOnInit() {
    this.getActiveTerm();
    this.getMenteeCounts();
  }

  private getMenteeCounts() {
    this.acceptedMenteeCount = this.program.mentees.filter(mentee => mentee.applicationStatus === MenteeApplicationStatus.accepted).length;
    this.menteeApplicationCount = this.program.mentees.length;
  }

  private getActiveTerm() {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    this.activeTerms = this.program.terms.filter(term => this.utilities.isDateInRange(term.applicationAcceptanceStartDate, term.termEndDate, today));
  }


}
