import { APIService } from './../../API.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class ProgramController {

    httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    });

    options = {
        headers: this.httpHeaders
    };

    constructor(private http: HttpClient, private api: APIService) { }

    createProgram(request: any) {
        return this.http.post('https://0sz2cejc5f.execute-api.us-east-1.amazonaws.com/dev/mentorship-service/admin/create', request, this.options);
    }

    getAllPrograms(filter?: any) {
        return this.http.get('https://0sz2cejc5f.execute-api.us-east-1.amazonaws.com/dev/mentorship-service/admin/programs?limit=10&cursor=').pipe(map((res: any) => res.response.data.listPrograms));
    }

    mapProgram(program: any) {
        if (program?.mentees && program?.mentees.items) {
            program.mentees = program.mentees.items.map((item: any) => {
                return { ...item, ...item.mentee };
            }) as any;
        }
        if (program?.mentors && program?.mentors.items) {
            program.mentors = program.mentors.items.map((item: any) => {
                const mentor: any = _.cloneDeep(item!.mentor);
                mentor.invitationStatus = item?.invitationStatus
                mentor.emailMessage = item?.emailMessage
                mentor.dateAccepted = item?.dateAccepted
                return mentor;
            }) as any;
        }
        program.preRequisiteTasks.map((preRequisiteTask: any) => {
            if (!preRequisiteTask?.custom) {
                preRequisiteTask!.dueDate = null;
            }
        });
        return program;
    }


    getAllProgramsGraphql(filter?: any): Observable<any> {
        return from(this.api.ListPrograms()).pipe(map(res => {
            res.items = res.items?.map(this.mapProgram);
            return res;
        }));
    }

    getProgramByIdGraphql(id: string): Observable<any> {
        return from(this.api.GetProgram(id)).pipe(map(this.mapProgram));
    }
}
