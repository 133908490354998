/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateProgram: OnCreateProgramSubscription;
  onUpdateProgram: OnUpdateProgramSubscription;
  onDeleteProgram: OnDeleteProgramSubscription;
  onCreateMentor: OnCreateMentorSubscription;
  onUpdateMentor: OnUpdateMentorSubscription;
  onDeleteMentor: OnDeleteMentorSubscription;
  onCreateMentorProgram: OnCreateMentorProgramSubscription;
  onUpdateMentorProgram: OnUpdateMentorProgramSubscription;
  onDeleteMentorProgram: OnDeleteMentorProgramSubscription;
  onCreateMentee: OnCreateMenteeSubscription;
  onUpdateMentee: OnUpdateMenteeSubscription;
  onDeleteMentee: OnDeleteMenteeSubscription;
  onCreateMenteeApplication: OnCreateMenteeApplicationSubscription;
  onUpdateMenteeApplication: OnUpdateMenteeApplicationSubscription;
  onDeleteMenteeApplication: OnDeleteMenteeApplicationSubscription;
  onCreateMilestone: OnCreateMilestoneSubscription;
  onUpdateMilestone: OnUpdateMilestoneSubscription;
  onDeleteMilestone: OnDeleteMilestoneSubscription;
  onCreateMilestoneActionLog: OnCreateMilestoneActionLogSubscription;
  onUpdateMilestoneActionLog: OnUpdateMilestoneActionLogSubscription;
  onDeleteMilestoneActionLog: OnDeleteMilestoneActionLogSubscription;
  onCreateTask: OnCreateTaskSubscription;
  onUpdateTask: OnUpdateTaskSubscription;
  onDeleteTask: OnDeleteTaskSubscription;
  onCreateMenteeTask: OnCreateMenteeTaskSubscription;
  onUpdateMenteeTask: OnUpdateMenteeTaskSubscription;
  onDeleteMenteeTask: OnDeleteMenteeTaskSubscription;
  onCreatePreRequisiteTask: OnCreatePreRequisiteTaskSubscription;
  onUpdatePreRequisiteTask: OnUpdatePreRequisiteTaskSubscription;
  onDeletePreRequisiteTask: OnDeletePreRequisiteTaskSubscription;
  onCreateMentorReview: OnCreateMentorReviewSubscription;
  onUpdateMentorReview: OnUpdateMentorReviewSubscription;
  onDeleteMentorReview: OnDeleteMentorReviewSubscription;
};

export type CreateProgramInput = {
  id?: string | null;
  projectID?: string | null;
  organizationID: string;
  departmentName?: string | null;
  programName: string;
  description: string;
  websiteURL?: string | null;
  programDetail: ProgramDetailInput;
  preRequisiteTasks: Array<ProgramPreRequisiteInput | null>;
  terms: Array<TermInput | null>;
  coAdmins?: Array<CoAdminInput | null> | null;
  status: string;
  createdAt: number;
  createdBy: string;
  updatedAt?: number | null;
  updatedBy?: string | null;
};

export type ProgramDetailInput = {
  programLogoURL: string;
  learningObjectives: Array<LearningObjectiveInput>;
  relevantLinks?: Array<RelevantLinkInput> | null;
  requiredSkillsAndTechnologies: Array<string>;
  availableMenteeSpots?: number | null;
  publish?: Array<PublishInput> | null;
};

export type LearningObjectiveInput = {
  title: string;
  text: string;
};

export type RelevantLinkInput = {
  title: string;
  url: string;
};

export type PublishInput = {
  publishTo: string;
  url: string;
};

export type ProgramPreRequisiteInput = {
  prerequisiteName: string;
  dueDate?: number | null;
  submission?: string | null;
  submitFile?: string | null;
  githubURL?: string | null;
  description: string;
  custom: boolean;
};

export type TermInput = {
  termName: string;
  termStartDate: number;
  termEndDate: number;
  applicationAcceptanceStartDate: number;
  applicationAcceptanceEndDate: number;
};

export type CoAdminInput = {
  firstName: string;
  lastName: string;
  imageUrl?: string | null;
  email: string;
  role?: string | null;
  invitationStatus: string;
  companyName?: string | null;
  githubHandle?: string | null;
  emailMessage: string;
  userID: string;
};

export type ModelProgramConditionInput = {
  projectID?: ModelStringInput | null;
  organizationID?: ModelStringInput | null;
  departmentName?: ModelStringInput | null;
  programName?: ModelStringInput | null;
  description?: ModelStringInput | null;
  websiteURL?: ModelStringInput | null;
  status?: ModelStringInput | null;
  createdAt?: ModelFloatInput | null;
  createdBy?: ModelStringInput | null;
  updatedAt?: ModelFloatInput | null;
  updatedBy?: ModelStringInput | null;
  and?: Array<ModelProgramConditionInput | null> | null;
  or?: Array<ModelProgramConditionInput | null> | null;
  not?: ModelProgramConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type Program = {
  id: string;
  projectID?: string | null;
  organizationID: string;
  departmentName?: string | null;
  programName: string;
  description: string;
  websiteURL?: string | null;
  programDetail: ProgramDetail;
  preRequisiteTasks: Array<ProgramPreRequisite | null>;
  terms: Array<Term | null>;
  coAdmins?: Array<CoAdmin | null> | null;
  mentors?: ModelMentorProgramConnection | null;
  mentees?: ModelMenteeApplicationConnection | null;
  status: string;
  createdAt: number;
  createdBy: string;
  updatedAt?: number | null;
  updatedBy?: string | null;
};

export type ProgramDetail = {
  programLogoURL: string;
  learningObjectives: Array<LearningObjective>;
  relevantLinks?: Array<RelevantLink> | null;
  requiredSkillsAndTechnologies: Array<string>;
  availableMenteeSpots?: number | null;
  publish?: Array<Publish> | null;
};

export type LearningObjective = {
  title: string;
  text: string;
};

export type RelevantLink = {
  title: string;
  url: string;
};

export type Publish = {
  publishTo: string;
  url: string;
};

export type ProgramPreRequisite = {
  prerequisiteName: string;
  dueDate?: number | null;
  submission?: string | null;
  submitFile?: string | null;
  githubURL?: string | null;
  description: string;
  custom: boolean;
};

export type Term = {
  termName: string;
  termStartDate: number;
  termEndDate: number;
  applicationAcceptanceStartDate: number;
  applicationAcceptanceEndDate: number;
  tasks?: Array<Task | null> | null;
};

export type Task = {
  id: string;
  milestoneID: string;
  milestone?: Milestone | null;
  assignedToMentees?: ModelMenteeTaskConnection | null;
  taskName: string;
  description: string;
  taskType?: string | null;
  dueDate: number;
  uploadRequired?: boolean | null;
  attachments?: Array<TaskAttachment | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type Milestone = {
  id: string;
  programID: string;
  program?: Program | null;
  milestoneName: string;
  dueDate: number;
  tasks?: ModelTaskConnection | null;
  status: string;
  createdBy?: string | null;
  description?: string | null;
  termName?: string | null;
  progress?: number | null;
  evaluation?: Array<MilestoneEvaluation | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelTaskConnection = {
  items?: Array<Task | null> | null;
  nextToken?: string | null;
};

export type MilestoneEvaluation = {
  status?: string | null;
  feedBackToProgramAdmin?: string | null;
  menteeID?: string | null;
  createdAt?: number | null;
};

export type ModelMenteeTaskConnection = {
  items?: Array<MenteeTask | null> | null;
  nextToken?: string | null;
};

export type MenteeTask = {
  id: string;
  menteeID: string;
  taskID: string;
  mentee: Mentee;
  task: Task;
  status: string;
  progress?: number | null;
  comments?: Array<TaskComments | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type Mentee = {
  id: string;
  menteeApplications?: ModelMenteeApplicationConnection | null;
  imageUrl?: string | null;
  dateAccepted?: number | null;
  userID: string;
  firstName: string;
  lastName: string;
  email: string;
  tasksAssigned?: ModelMenteeTaskConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelMenteeApplicationConnection = {
  items?: Array<MenteeApplication | null> | null;
  nextToken?: string | null;
};

export type MenteeApplication = {
  id: string;
  userID: string;
  programID: string;
  program: Program;
  mentee: Mentee;
  applicationStatus: string;
  applicationDate: number;
  preRequisites?: ModelPreRequisiteTaskConnection | null;
  dateAssigned?: number | null;
  mentorReviews?: ModelMentorReviewConnection | null;
  evaluations?: Array<Evaluation | null> | null;
  feedBacks?: Array<FeedBack | null> | null;
  decisionReason?: string | null;
  certificate?: string | null;
  overallFeedback?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelPreRequisiteTaskConnection = {
  items?: Array<PreRequisiteTask | null> | null;
  nextToken?: string | null;
};

export type PreRequisiteTask = {
  id: string;
  programID: string;
  applicationID: string;
  prerequisiteName: string;
  dueDate?: number | null;
  submission?: string | null;
  submitFile?: string | null;
  githubURL?: string | null;
  description: string;
  category: string;
  custom: boolean;
  status: string;
  comments?: Array<TaskComments | null> | null;
  mentorFeedBack?: string | null;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type TaskComments = {
  commentBy?: string | null;
  commentText?: string | null;
  createdAt?: number | null;
};

export type ModelMentorReviewConnection = {
  items?: Array<MentorReview | null> | null;
  nextToken?: string | null;
};

export type MentorReview = {
  id: string;
  mentorID: string;
  menteeApplicationID: string;
  menteeApplication: MenteeApplication;
  mentor: Mentor;
  status: string;
  feedback?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type Mentor = {
  id: string;
  userID: string;
  programs?: ModelMentorProgramConnection | null;
  mentorReviews?: ModelMentorReviewConnection | null;
  firstName: string;
  lastName: string;
  imageUrl?: string | null;
  email: string;
  role?: string | null;
  companyName?: string | null;
  githubHandle?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelMentorProgramConnection = {
  items?: Array<MentorProgram | null> | null;
  nextToken?: string | null;
};

export type MentorProgram = {
  id: string;
  programID: string;
  mentorID: string;
  program: Program;
  mentor: Mentor;
  invitationStatus: string;
  emailMessage: string;
  dateAccepted?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type Evaluation = {
  evaluationName: string;
  dueDate: number;
  status: string;
};

export type FeedBack = {
  feedBackName: string;
  date: number;
  status: string;
};

export type TaskAttachment = {
  attachmentURL?: string | null;
  attachmentText?: string | null;
};

export type CoAdmin = {
  firstName: string;
  lastName: string;
  imageUrl?: string | null;
  email: string;
  role?: string | null;
  invitationStatus: string;
  companyName?: string | null;
  githubHandle?: string | null;
  emailMessage: string;
  userID: string;
};

export type UpdateProgramInput = {
  id: string;
  projectID?: string | null;
  organizationID?: string | null;
  departmentName?: string | null;
  programName?: string | null;
  description?: string | null;
  websiteURL?: string | null;
  programDetail?: ProgramDetailInput | null;
  preRequisiteTasks?: Array<ProgramPreRequisiteInput | null> | null;
  terms?: Array<TermInput | null> | null;
  coAdmins?: Array<CoAdminInput | null> | null;
  status?: string | null;
  createdAt?: number | null;
  createdBy?: string | null;
  updatedAt?: number | null;
  updatedBy?: string | null;
};

export type DeleteProgramInput = {
  id: string;
};

export type CreateMentorInput = {
  id?: string | null;
  userID: string;
  firstName: string;
  lastName: string;
  imageUrl?: string | null;
  email: string;
  role?: string | null;
  companyName?: string | null;
  githubHandle?: string | null;
};

export type ModelMentorConditionInput = {
  userID?: ModelIDInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  imageUrl?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelStringInput | null;
  companyName?: ModelStringInput | null;
  githubHandle?: ModelStringInput | null;
  and?: Array<ModelMentorConditionInput | null> | null;
  or?: Array<ModelMentorConditionInput | null> | null;
  not?: ModelMentorConditionInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type UpdateMentorInput = {
  id: string;
  userID?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  imageUrl?: string | null;
  email?: string | null;
  role?: string | null;
  companyName?: string | null;
  githubHandle?: string | null;
};

export type DeleteMentorInput = {
  id: string;
};

export type CreateMentorProgramInput = {
  id?: string | null;
  programID: string;
  mentorID: string;
  invitationStatus: string;
  emailMessage: string;
  dateAccepted?: number | null;
};

export type ModelMentorProgramConditionInput = {
  programID?: ModelIDInput | null;
  mentorID?: ModelIDInput | null;
  invitationStatus?: ModelStringInput | null;
  emailMessage?: ModelStringInput | null;
  dateAccepted?: ModelFloatInput | null;
  and?: Array<ModelMentorProgramConditionInput | null> | null;
  or?: Array<ModelMentorProgramConditionInput | null> | null;
  not?: ModelMentorProgramConditionInput | null;
};

export type UpdateMentorProgramInput = {
  id: string;
  programID?: string | null;
  mentorID?: string | null;
  invitationStatus?: string | null;
  emailMessage?: string | null;
  dateAccepted?: number | null;
};

export type DeleteMentorProgramInput = {
  id: string;
};

export type CreateMenteeInput = {
  id?: string | null;
  imageUrl?: string | null;
  dateAccepted?: number | null;
  userID: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type ModelMenteeConditionInput = {
  imageUrl?: ModelStringInput | null;
  dateAccepted?: ModelFloatInput | null;
  userID?: ModelIDInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  email?: ModelStringInput | null;
  and?: Array<ModelMenteeConditionInput | null> | null;
  or?: Array<ModelMenteeConditionInput | null> | null;
  not?: ModelMenteeConditionInput | null;
};

export type UpdateMenteeInput = {
  id: string;
  imageUrl?: string | null;
  dateAccepted?: number | null;
  userID?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
};

export type DeleteMenteeInput = {
  id: string;
};

export type CreateMenteeApplicationInput = {
  id?: string | null;
  userID: string;
  programID: string;
  applicationStatus: string;
  applicationDate: number;
  dateAssigned?: number | null;
  evaluations?: Array<EvaluationInput | null> | null;
  feedBacks?: Array<FeedBackInput | null> | null;
  decisionReason?: string | null;
  certificate?: string | null;
  overallFeedback?: string | null;
};

export type EvaluationInput = {
  evaluationName: string;
  dueDate: number;
  status: string;
};

export type FeedBackInput = {
  feedBackName: string;
  date: number;
  status: string;
};

export type ModelMenteeApplicationConditionInput = {
  userID?: ModelIDInput | null;
  programID?: ModelIDInput | null;
  applicationStatus?: ModelStringInput | null;
  applicationDate?: ModelFloatInput | null;
  dateAssigned?: ModelFloatInput | null;
  decisionReason?: ModelStringInput | null;
  certificate?: ModelStringInput | null;
  overallFeedback?: ModelStringInput | null;
  and?: Array<ModelMenteeApplicationConditionInput | null> | null;
  or?: Array<ModelMenteeApplicationConditionInput | null> | null;
  not?: ModelMenteeApplicationConditionInput | null;
};

export type UpdateMenteeApplicationInput = {
  id: string;
  userID?: string | null;
  programID?: string | null;
  applicationStatus?: string | null;
  applicationDate?: number | null;
  dateAssigned?: number | null;
  evaluations?: Array<EvaluationInput | null> | null;
  feedBacks?: Array<FeedBackInput | null> | null;
  decisionReason?: string | null;
  certificate?: string | null;
  overallFeedback?: string | null;
};

export type DeleteMenteeApplicationInput = {
  id: string;
};

export type CreateMilestoneInput = {
  id?: string | null;
  programID: string;
  milestoneName: string;
  dueDate: number;
  status: string;
  createdBy?: string | null;
  description?: string | null;
  termName?: string | null;
  progress?: number | null;
  evaluation?: Array<MilestoneEvaluationInput | null> | null;
};

export type MilestoneEvaluationInput = {
  status?: string | null;
  feedBackToProgramAdmin?: string | null;
  menteeID?: string | null;
  createdAt?: number | null;
};

export type ModelMilestoneConditionInput = {
  programID?: ModelIDInput | null;
  milestoneName?: ModelStringInput | null;
  dueDate?: ModelFloatInput | null;
  status?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  description?: ModelStringInput | null;
  termName?: ModelStringInput | null;
  progress?: ModelFloatInput | null;
  and?: Array<ModelMilestoneConditionInput | null> | null;
  or?: Array<ModelMilestoneConditionInput | null> | null;
  not?: ModelMilestoneConditionInput | null;
};

export type UpdateMilestoneInput = {
  id: string;
  programID?: string | null;
  milestoneName?: string | null;
  dueDate?: number | null;
  status?: string | null;
  createdBy?: string | null;
  description?: string | null;
  termName?: string | null;
  progress?: number | null;
  evaluation?: Array<MilestoneEvaluationInput | null> | null;
};

export type DeleteMilestoneInput = {
  id: string;
};

export type CreateMilestoneActionLogInput = {
  id?: string | null;
  milestoneID: string;
  menteeTaskID: string;
  createdAt?: number | null;
  actor?: string | null;
  actorID?: string | null;
  action?: string | null;
  actionType?: string | null;
};

export type ModelMilestoneActionLogConditionInput = {
  milestoneID?: ModelIDInput | null;
  menteeTaskID?: ModelIDInput | null;
  createdAt?: ModelFloatInput | null;
  actor?: ModelStringInput | null;
  actorID?: ModelIDInput | null;
  action?: ModelStringInput | null;
  actionType?: ModelStringInput | null;
  and?: Array<ModelMilestoneActionLogConditionInput | null> | null;
  or?: Array<ModelMilestoneActionLogConditionInput | null> | null;
  not?: ModelMilestoneActionLogConditionInput | null;
};

export type MilestoneActionLog = {
  id: string;
  milestoneID: string;
  milestone?: Milestone | null;
  menteeTaskID: string;
  menteeTask?: MenteeTask | null;
  createdAt?: number | null;
  actor?: string | null;
  actorID?: string | null;
  action?: string | null;
  actionType?: string | null;
  updatedAt: string;
};

export type UpdateMilestoneActionLogInput = {
  id: string;
  milestoneID?: string | null;
  menteeTaskID?: string | null;
  createdAt?: number | null;
  actor?: string | null;
  actorID?: string | null;
  action?: string | null;
  actionType?: string | null;
};

export type DeleteMilestoneActionLogInput = {
  id: string;
};

export type CreateTaskInput = {
  id?: string | null;
  milestoneID: string;
  taskName: string;
  description: string;
  taskType?: string | null;
  dueDate: number;
  uploadRequired?: boolean | null;
  attachments?: Array<TaskAttachmentInput | null> | null;
};

export type TaskAttachmentInput = {
  attachmentURL?: string | null;
  attachmentText?: string | null;
};

export type ModelTaskConditionInput = {
  milestoneID?: ModelIDInput | null;
  taskName?: ModelStringInput | null;
  description?: ModelStringInput | null;
  taskType?: ModelStringInput | null;
  dueDate?: ModelFloatInput | null;
  uploadRequired?: ModelBooleanInput | null;
  and?: Array<ModelTaskConditionInput | null> | null;
  or?: Array<ModelTaskConditionInput | null> | null;
  not?: ModelTaskConditionInput | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateTaskInput = {
  id: string;
  milestoneID?: string | null;
  taskName?: string | null;
  description?: string | null;
  taskType?: string | null;
  dueDate?: number | null;
  uploadRequired?: boolean | null;
  attachments?: Array<TaskAttachmentInput | null> | null;
};

export type DeleteTaskInput = {
  id: string;
};

export type CreateMenteeTaskInput = {
  id?: string | null;
  menteeID: string;
  taskID: string;
  status: string;
  progress?: number | null;
  comments?: Array<TaskCommentsInput | null> | null;
};

export type TaskCommentsInput = {
  commentBy?: string | null;
  commentText?: string | null;
  createdAt?: number | null;
};

export type ModelMenteeTaskConditionInput = {
  menteeID?: ModelIDInput | null;
  taskID?: ModelIDInput | null;
  status?: ModelStringInput | null;
  progress?: ModelFloatInput | null;
  and?: Array<ModelMenteeTaskConditionInput | null> | null;
  or?: Array<ModelMenteeTaskConditionInput | null> | null;
  not?: ModelMenteeTaskConditionInput | null;
};

export type UpdateMenteeTaskInput = {
  id: string;
  menteeID?: string | null;
  taskID?: string | null;
  status?: string | null;
  progress?: number | null;
  comments?: Array<TaskCommentsInput | null> | null;
};

export type DeleteMenteeTaskInput = {
  id: string;
};

export type CreatePreRequisiteTaskInput = {
  id?: string | null;
  programID: string;
  applicationID: string;
  prerequisiteName: string;
  dueDate?: number | null;
  submission?: string | null;
  submitFile?: string | null;
  githubURL?: string | null;
  description: string;
  category: string;
  custom: boolean;
  status: string;
  comments?: Array<TaskCommentsInput | null> | null;
  mentorFeedBack?: string | null;
  userID: string;
};

export type ModelPreRequisiteTaskConditionInput = {
  programID?: ModelIDInput | null;
  applicationID?: ModelIDInput | null;
  prerequisiteName?: ModelStringInput | null;
  dueDate?: ModelFloatInput | null;
  submission?: ModelStringInput | null;
  submitFile?: ModelStringInput | null;
  githubURL?: ModelStringInput | null;
  description?: ModelStringInput | null;
  category?: ModelStringInput | null;
  custom?: ModelBooleanInput | null;
  status?: ModelStringInput | null;
  mentorFeedBack?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  and?: Array<ModelPreRequisiteTaskConditionInput | null> | null;
  or?: Array<ModelPreRequisiteTaskConditionInput | null> | null;
  not?: ModelPreRequisiteTaskConditionInput | null;
};

export type UpdatePreRequisiteTaskInput = {
  id: string;
  programID?: string | null;
  applicationID?: string | null;
  prerequisiteName?: string | null;
  dueDate?: number | null;
  submission?: string | null;
  submitFile?: string | null;
  githubURL?: string | null;
  description?: string | null;
  category?: string | null;
  custom?: boolean | null;
  status?: string | null;
  comments?: Array<TaskCommentsInput | null> | null;
  mentorFeedBack?: string | null;
  userID?: string | null;
};

export type DeletePreRequisiteTaskInput = {
  id: string;
};

export type CreateMentorReviewInput = {
  id?: string | null;
  mentorID: string;
  menteeApplicationID: string;
  status: string;
  feedback?: string | null;
};

export type ModelMentorReviewConditionInput = {
  mentorID?: ModelIDInput | null;
  menteeApplicationID?: ModelIDInput | null;
  status?: ModelStringInput | null;
  feedback?: ModelStringInput | null;
  and?: Array<ModelMentorReviewConditionInput | null> | null;
  or?: Array<ModelMentorReviewConditionInput | null> | null;
  not?: ModelMentorReviewConditionInput | null;
};

export type UpdateMentorReviewInput = {
  id: string;
  mentorID?: string | null;
  menteeApplicationID?: string | null;
  status?: string | null;
  feedback?: string | null;
};

export type DeleteMentorReviewInput = {
  id: string;
};

export type ModelProgramFilterInput = {
  id?: ModelIDInput | null;
  projectID?: ModelStringInput | null;
  organizationID?: ModelStringInput | null;
  departmentName?: ModelStringInput | null;
  programName?: ModelStringInput | null;
  description?: ModelStringInput | null;
  websiteURL?: ModelStringInput | null;
  status?: ModelStringInput | null;
  createdAt?: ModelFloatInput | null;
  createdBy?: ModelStringInput | null;
  updatedAt?: ModelFloatInput | null;
  updatedBy?: ModelStringInput | null;
  and?: Array<ModelProgramFilterInput | null> | null;
  or?: Array<ModelProgramFilterInput | null> | null;
  not?: ModelProgramFilterInput | null;
};

export type ModelProgramConnection = {
  items?: Array<Program | null> | null;
  nextToken?: string | null;
};

export type ModelMentorFilterInput = {
  id?: ModelIDInput | null;
  userID?: ModelIDInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  imageUrl?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelStringInput | null;
  companyName?: ModelStringInput | null;
  githubHandle?: ModelStringInput | null;
  and?: Array<ModelMentorFilterInput | null> | null;
  or?: Array<ModelMentorFilterInput | null> | null;
  not?: ModelMentorFilterInput | null;
};

export type ModelMentorConnection = {
  items?: Array<Mentor | null> | null;
  nextToken?: string | null;
};

export type ModelMentorProgramFilterInput = {
  id?: ModelIDInput | null;
  programID?: ModelIDInput | null;
  mentorID?: ModelIDInput | null;
  invitationStatus?: ModelStringInput | null;
  emailMessage?: ModelStringInput | null;
  dateAccepted?: ModelFloatInput | null;
  and?: Array<ModelMentorProgramFilterInput | null> | null;
  or?: Array<ModelMentorProgramFilterInput | null> | null;
  not?: ModelMentorProgramFilterInput | null;
};

export type ModelMenteeFilterInput = {
  id?: ModelIDInput | null;
  imageUrl?: ModelStringInput | null;
  dateAccepted?: ModelFloatInput | null;
  userID?: ModelIDInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  email?: ModelStringInput | null;
  and?: Array<ModelMenteeFilterInput | null> | null;
  or?: Array<ModelMenteeFilterInput | null> | null;
  not?: ModelMenteeFilterInput | null;
};

export type ModelMenteeConnection = {
  items?: Array<Mentee | null> | null;
  nextToken?: string | null;
};

export type ModelMenteeApplicationFilterInput = {
  id?: ModelIDInput | null;
  userID?: ModelIDInput | null;
  programID?: ModelIDInput | null;
  applicationStatus?: ModelStringInput | null;
  applicationDate?: ModelFloatInput | null;
  dateAssigned?: ModelFloatInput | null;
  decisionReason?: ModelStringInput | null;
  certificate?: ModelStringInput | null;
  overallFeedback?: ModelStringInput | null;
  and?: Array<ModelMenteeApplicationFilterInput | null> | null;
  or?: Array<ModelMenteeApplicationFilterInput | null> | null;
  not?: ModelMenteeApplicationFilterInput | null;
};

export type ModelMilestoneFilterInput = {
  id?: ModelIDInput | null;
  programID?: ModelIDInput | null;
  milestoneName?: ModelStringInput | null;
  dueDate?: ModelFloatInput | null;
  status?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  description?: ModelStringInput | null;
  termName?: ModelStringInput | null;
  progress?: ModelFloatInput | null;
  and?: Array<ModelMilestoneFilterInput | null> | null;
  or?: Array<ModelMilestoneFilterInput | null> | null;
  not?: ModelMilestoneFilterInput | null;
};

export type ModelMilestoneConnection = {
  items?: Array<Milestone | null> | null;
  nextToken?: string | null;
};

export type ModelMilestoneActionLogFilterInput = {
  id?: ModelIDInput | null;
  milestoneID?: ModelIDInput | null;
  menteeTaskID?: ModelIDInput | null;
  createdAt?: ModelFloatInput | null;
  actor?: ModelStringInput | null;
  actorID?: ModelIDInput | null;
  action?: ModelStringInput | null;
  actionType?: ModelStringInput | null;
  and?: Array<ModelMilestoneActionLogFilterInput | null> | null;
  or?: Array<ModelMilestoneActionLogFilterInput | null> | null;
  not?: ModelMilestoneActionLogFilterInput | null;
};

export type ModelMilestoneActionLogConnection = {
  items?: Array<MilestoneActionLog | null> | null;
  nextToken?: string | null;
};

export type ModelTaskFilterInput = {
  id?: ModelIDInput | null;
  milestoneID?: ModelIDInput | null;
  taskName?: ModelStringInput | null;
  description?: ModelStringInput | null;
  taskType?: ModelStringInput | null;
  dueDate?: ModelFloatInput | null;
  uploadRequired?: ModelBooleanInput | null;
  and?: Array<ModelTaskFilterInput | null> | null;
  or?: Array<ModelTaskFilterInput | null> | null;
  not?: ModelTaskFilterInput | null;
};

export type ModelMenteeTaskFilterInput = {
  id?: ModelIDInput | null;
  menteeID?: ModelIDInput | null;
  taskID?: ModelIDInput | null;
  status?: ModelStringInput | null;
  progress?: ModelFloatInput | null;
  and?: Array<ModelMenteeTaskFilterInput | null> | null;
  or?: Array<ModelMenteeTaskFilterInput | null> | null;
  not?: ModelMenteeTaskFilterInput | null;
};

export type ModelPreRequisiteTaskFilterInput = {
  id?: ModelIDInput | null;
  programID?: ModelIDInput | null;
  applicationID?: ModelIDInput | null;
  prerequisiteName?: ModelStringInput | null;
  dueDate?: ModelFloatInput | null;
  submission?: ModelStringInput | null;
  submitFile?: ModelStringInput | null;
  githubURL?: ModelStringInput | null;
  description?: ModelStringInput | null;
  category?: ModelStringInput | null;
  custom?: ModelBooleanInput | null;
  status?: ModelStringInput | null;
  mentorFeedBack?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  and?: Array<ModelPreRequisiteTaskFilterInput | null> | null;
  or?: Array<ModelPreRequisiteTaskFilterInput | null> | null;
  not?: ModelPreRequisiteTaskFilterInput | null;
};

export type ModelMentorReviewFilterInput = {
  id?: ModelIDInput | null;
  mentorID?: ModelIDInput | null;
  menteeApplicationID?: ModelIDInput | null;
  status?: ModelStringInput | null;
  feedback?: ModelStringInput | null;
  and?: Array<ModelMentorReviewFilterInput | null> | null;
  or?: Array<ModelMentorReviewFilterInput | null> | null;
  not?: ModelMentorReviewFilterInput | null;
};

export type CreateProgramMutation = {
  id: string;
  projectID?: string | null;
  organizationID: string;
  departmentName?: string | null;
  programName: string;
  description: string;
  websiteURL?: string | null;
  programDetail: {
    programLogoURL: string;
    learningObjectives: Array<{
      title: string;
      text: string;
    }>;
    relevantLinks?: Array<{
      title: string;
      url: string;
    }> | null;
    requiredSkillsAndTechnologies: Array<string>;
    availableMenteeSpots?: number | null;
    publish?: Array<{
      publishTo: string;
      url: string;
    }> | null;
  };
  preRequisiteTasks: Array<{
    prerequisiteName: string;
    dueDate?: number | null;
    submission?: string | null;
    submitFile?: string | null;
    githubURL?: string | null;
    description: string;
    custom: boolean;
  } | null>;
  terms: Array<{
    termName: string;
    termStartDate: number;
    termEndDate: number;
    applicationAcceptanceStartDate: number;
    applicationAcceptanceEndDate: number;
    tasks?: Array<{
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
  } | null>;
  coAdmins?: Array<{
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    invitationStatus: string;
    companyName?: string | null;
    githubHandle?: string | null;
    emailMessage: string;
    userID: string;
  } | null> | null;
  mentors?: {
    items?: Array<{
      id: string;
      programID: string;
      mentorID: string;
      invitationStatus: string;
      emailMessage: string;
      dateAccepted?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  mentees?: {
    items?: Array<{
      id: string;
      userID: string;
      programID: string;
      applicationStatus: string;
      applicationDate: number;
      dateAssigned?: number | null;
      decisionReason?: string | null;
      certificate?: string | null;
      overallFeedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  status: string;
  createdAt: number;
  createdBy: string;
  updatedAt?: number | null;
  updatedBy?: string | null;
};

export type UpdateProgramMutation = {
  id: string;
  projectID?: string | null;
  organizationID: string;
  departmentName?: string | null;
  programName: string;
  description: string;
  websiteURL?: string | null;
  programDetail: {
    programLogoURL: string;
    learningObjectives: Array<{
      title: string;
      text: string;
    }>;
    relevantLinks?: Array<{
      title: string;
      url: string;
    }> | null;
    requiredSkillsAndTechnologies: Array<string>;
    availableMenteeSpots?: number | null;
    publish?: Array<{
      publishTo: string;
      url: string;
    }> | null;
  };
  preRequisiteTasks: Array<{
    prerequisiteName: string;
    dueDate?: number | null;
    submission?: string | null;
    submitFile?: string | null;
    githubURL?: string | null;
    description: string;
    custom: boolean;
  } | null>;
  terms: Array<{
    termName: string;
    termStartDate: number;
    termEndDate: number;
    applicationAcceptanceStartDate: number;
    applicationAcceptanceEndDate: number;
    tasks?: Array<{
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
  } | null>;
  coAdmins?: Array<{
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    invitationStatus: string;
    companyName?: string | null;
    githubHandle?: string | null;
    emailMessage: string;
    userID: string;
  } | null> | null;
  mentors?: {
    items?: Array<{
      id: string;
      programID: string;
      mentorID: string;
      invitationStatus: string;
      emailMessage: string;
      dateAccepted?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  mentees?: {
    items?: Array<{
      id: string;
      userID: string;
      programID: string;
      applicationStatus: string;
      applicationDate: number;
      dateAssigned?: number | null;
      decisionReason?: string | null;
      certificate?: string | null;
      overallFeedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  status: string;
  createdAt: number;
  createdBy: string;
  updatedAt?: number | null;
  updatedBy?: string | null;
};

export type DeleteProgramMutation = {
  id: string;
  projectID?: string | null;
  organizationID: string;
  departmentName?: string | null;
  programName: string;
  description: string;
  websiteURL?: string | null;
  programDetail: {
    programLogoURL: string;
    learningObjectives: Array<{
      title: string;
      text: string;
    }>;
    relevantLinks?: Array<{
      title: string;
      url: string;
    }> | null;
    requiredSkillsAndTechnologies: Array<string>;
    availableMenteeSpots?: number | null;
    publish?: Array<{
      publishTo: string;
      url: string;
    }> | null;
  };
  preRequisiteTasks: Array<{
    prerequisiteName: string;
    dueDate?: number | null;
    submission?: string | null;
    submitFile?: string | null;
    githubURL?: string | null;
    description: string;
    custom: boolean;
  } | null>;
  terms: Array<{
    termName: string;
    termStartDate: number;
    termEndDate: number;
    applicationAcceptanceStartDate: number;
    applicationAcceptanceEndDate: number;
    tasks?: Array<{
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
  } | null>;
  coAdmins?: Array<{
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    invitationStatus: string;
    companyName?: string | null;
    githubHandle?: string | null;
    emailMessage: string;
    userID: string;
  } | null> | null;
  mentors?: {
    items?: Array<{
      id: string;
      programID: string;
      mentorID: string;
      invitationStatus: string;
      emailMessage: string;
      dateAccepted?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  mentees?: {
    items?: Array<{
      id: string;
      userID: string;
      programID: string;
      applicationStatus: string;
      applicationDate: number;
      dateAssigned?: number | null;
      decisionReason?: string | null;
      certificate?: string | null;
      overallFeedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  status: string;
  createdAt: number;
  createdBy: string;
  updatedAt?: number | null;
  updatedBy?: string | null;
};

export type CreateMentorMutation = {
  id: string;
  userID: string;
  programs?: {
    items?: Array<{
      id: string;
      programID: string;
      mentorID: string;
      invitationStatus: string;
      emailMessage: string;
      dateAccepted?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  mentorReviews?: {
    items?: Array<{
      id: string;
      mentorID: string;
      menteeApplicationID: string;
      status: string;
      feedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  firstName: string;
  lastName: string;
  imageUrl?: string | null;
  email: string;
  role?: string | null;
  companyName?: string | null;
  githubHandle?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMentorMutation = {
  id: string;
  userID: string;
  programs?: {
    items?: Array<{
      id: string;
      programID: string;
      mentorID: string;
      invitationStatus: string;
      emailMessage: string;
      dateAccepted?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  mentorReviews?: {
    items?: Array<{
      id: string;
      mentorID: string;
      menteeApplicationID: string;
      status: string;
      feedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  firstName: string;
  lastName: string;
  imageUrl?: string | null;
  email: string;
  role?: string | null;
  companyName?: string | null;
  githubHandle?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteMentorMutation = {
  id: string;
  userID: string;
  programs?: {
    items?: Array<{
      id: string;
      programID: string;
      mentorID: string;
      invitationStatus: string;
      emailMessage: string;
      dateAccepted?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  mentorReviews?: {
    items?: Array<{
      id: string;
      mentorID: string;
      menteeApplicationID: string;
      status: string;
      feedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  firstName: string;
  lastName: string;
  imageUrl?: string | null;
  email: string;
  role?: string | null;
  companyName?: string | null;
  githubHandle?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateMentorProgramMutation = {
  id: string;
  programID: string;
  mentorID: string;
  program: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  };
  mentor: {
    id: string;
    userID: string;
    programs?: {
      nextToken?: string | null;
    } | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    companyName?: string | null;
    githubHandle?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  invitationStatus: string;
  emailMessage: string;
  dateAccepted?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMentorProgramMutation = {
  id: string;
  programID: string;
  mentorID: string;
  program: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  };
  mentor: {
    id: string;
    userID: string;
    programs?: {
      nextToken?: string | null;
    } | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    companyName?: string | null;
    githubHandle?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  invitationStatus: string;
  emailMessage: string;
  dateAccepted?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteMentorProgramMutation = {
  id: string;
  programID: string;
  mentorID: string;
  program: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  };
  mentor: {
    id: string;
    userID: string;
    programs?: {
      nextToken?: string | null;
    } | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    companyName?: string | null;
    githubHandle?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  invitationStatus: string;
  emailMessage: string;
  dateAccepted?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateMenteeMutation = {
  id: string;
  menteeApplications?: {
    items?: Array<{
      id: string;
      userID: string;
      programID: string;
      applicationStatus: string;
      applicationDate: number;
      dateAssigned?: number | null;
      decisionReason?: string | null;
      certificate?: string | null;
      overallFeedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  imageUrl?: string | null;
  dateAccepted?: number | null;
  userID: string;
  firstName: string;
  lastName: string;
  email: string;
  tasksAssigned?: {
    items?: Array<{
      id: string;
      menteeID: string;
      taskID: string;
      status: string;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMenteeMutation = {
  id: string;
  menteeApplications?: {
    items?: Array<{
      id: string;
      userID: string;
      programID: string;
      applicationStatus: string;
      applicationDate: number;
      dateAssigned?: number | null;
      decisionReason?: string | null;
      certificate?: string | null;
      overallFeedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  imageUrl?: string | null;
  dateAccepted?: number | null;
  userID: string;
  firstName: string;
  lastName: string;
  email: string;
  tasksAssigned?: {
    items?: Array<{
      id: string;
      menteeID: string;
      taskID: string;
      status: string;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteMenteeMutation = {
  id: string;
  menteeApplications?: {
    items?: Array<{
      id: string;
      userID: string;
      programID: string;
      applicationStatus: string;
      applicationDate: number;
      dateAssigned?: number | null;
      decisionReason?: string | null;
      certificate?: string | null;
      overallFeedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  imageUrl?: string | null;
  dateAccepted?: number | null;
  userID: string;
  firstName: string;
  lastName: string;
  email: string;
  tasksAssigned?: {
    items?: Array<{
      id: string;
      menteeID: string;
      taskID: string;
      status: string;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateMenteeApplicationMutation = {
  id: string;
  userID: string;
  programID: string;
  program: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  };
  mentee: {
    id: string;
    menteeApplications?: {
      nextToken?: string | null;
    } | null;
    imageUrl?: string | null;
    dateAccepted?: number | null;
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    tasksAssigned?: {
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  applicationStatus: string;
  applicationDate: number;
  preRequisites?: {
    items?: Array<{
      id: string;
      programID: string;
      applicationID: string;
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      category: string;
      custom: boolean;
      status: string;
      mentorFeedBack?: string | null;
      userID: string;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  dateAssigned?: number | null;
  mentorReviews?: {
    items?: Array<{
      id: string;
      mentorID: string;
      menteeApplicationID: string;
      status: string;
      feedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  evaluations?: Array<{
    evaluationName: string;
    dueDate: number;
    status: string;
  } | null> | null;
  feedBacks?: Array<{
    feedBackName: string;
    date: number;
    status: string;
  } | null> | null;
  decisionReason?: string | null;
  certificate?: string | null;
  overallFeedback?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMenteeApplicationMutation = {
  id: string;
  userID: string;
  programID: string;
  program: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  };
  mentee: {
    id: string;
    menteeApplications?: {
      nextToken?: string | null;
    } | null;
    imageUrl?: string | null;
    dateAccepted?: number | null;
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    tasksAssigned?: {
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  applicationStatus: string;
  applicationDate: number;
  preRequisites?: {
    items?: Array<{
      id: string;
      programID: string;
      applicationID: string;
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      category: string;
      custom: boolean;
      status: string;
      mentorFeedBack?: string | null;
      userID: string;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  dateAssigned?: number | null;
  mentorReviews?: {
    items?: Array<{
      id: string;
      mentorID: string;
      menteeApplicationID: string;
      status: string;
      feedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  evaluations?: Array<{
    evaluationName: string;
    dueDate: number;
    status: string;
  } | null> | null;
  feedBacks?: Array<{
    feedBackName: string;
    date: number;
    status: string;
  } | null> | null;
  decisionReason?: string | null;
  certificate?: string | null;
  overallFeedback?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteMenteeApplicationMutation = {
  id: string;
  userID: string;
  programID: string;
  program: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  };
  mentee: {
    id: string;
    menteeApplications?: {
      nextToken?: string | null;
    } | null;
    imageUrl?: string | null;
    dateAccepted?: number | null;
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    tasksAssigned?: {
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  applicationStatus: string;
  applicationDate: number;
  preRequisites?: {
    items?: Array<{
      id: string;
      programID: string;
      applicationID: string;
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      category: string;
      custom: boolean;
      status: string;
      mentorFeedBack?: string | null;
      userID: string;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  dateAssigned?: number | null;
  mentorReviews?: {
    items?: Array<{
      id: string;
      mentorID: string;
      menteeApplicationID: string;
      status: string;
      feedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  evaluations?: Array<{
    evaluationName: string;
    dueDate: number;
    status: string;
  } | null> | null;
  feedBacks?: Array<{
    feedBackName: string;
    date: number;
    status: string;
  } | null> | null;
  decisionReason?: string | null;
  certificate?: string | null;
  overallFeedback?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateMilestoneMutation = {
  id: string;
  programID: string;
  program?: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  } | null;
  milestoneName: string;
  dueDate: number;
  tasks?: {
    items?: Array<{
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  status: string;
  createdBy?: string | null;
  description?: string | null;
  termName?: string | null;
  progress?: number | null;
  evaluation?: Array<{
    status?: string | null;
    feedBackToProgramAdmin?: string | null;
    menteeID?: string | null;
    createdAt?: number | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMilestoneMutation = {
  id: string;
  programID: string;
  program?: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  } | null;
  milestoneName: string;
  dueDate: number;
  tasks?: {
    items?: Array<{
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  status: string;
  createdBy?: string | null;
  description?: string | null;
  termName?: string | null;
  progress?: number | null;
  evaluation?: Array<{
    status?: string | null;
    feedBackToProgramAdmin?: string | null;
    menteeID?: string | null;
    createdAt?: number | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteMilestoneMutation = {
  id: string;
  programID: string;
  program?: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  } | null;
  milestoneName: string;
  dueDate: number;
  tasks?: {
    items?: Array<{
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  status: string;
  createdBy?: string | null;
  description?: string | null;
  termName?: string | null;
  progress?: number | null;
  evaluation?: Array<{
    status?: string | null;
    feedBackToProgramAdmin?: string | null;
    menteeID?: string | null;
    createdAt?: number | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateMilestoneActionLogMutation = {
  id: string;
  milestoneID: string;
  milestone?: {
    id: string;
    programID: string;
    program?: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    } | null;
    milestoneName: string;
    dueDate: number;
    tasks?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdBy?: string | null;
    description?: string | null;
    termName?: string | null;
    progress?: number | null;
    evaluation?: Array<{
      status?: string | null;
      feedBackToProgramAdmin?: string | null;
      menteeID?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  menteeTaskID: string;
  menteeTask?: {
    id: string;
    menteeID: string;
    taskID: string;
    mentee: {
      id: string;
      imageUrl?: string | null;
      dateAccepted?: number | null;
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
      updatedAt: string;
    };
    task: {
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    status: string;
    progress?: number | null;
    comments?: Array<{
      commentBy?: string | null;
      commentText?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt?: number | null;
  actor?: string | null;
  actorID?: string | null;
  action?: string | null;
  actionType?: string | null;
  updatedAt: string;
};

export type UpdateMilestoneActionLogMutation = {
  id: string;
  milestoneID: string;
  milestone?: {
    id: string;
    programID: string;
    program?: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    } | null;
    milestoneName: string;
    dueDate: number;
    tasks?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdBy?: string | null;
    description?: string | null;
    termName?: string | null;
    progress?: number | null;
    evaluation?: Array<{
      status?: string | null;
      feedBackToProgramAdmin?: string | null;
      menteeID?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  menteeTaskID: string;
  menteeTask?: {
    id: string;
    menteeID: string;
    taskID: string;
    mentee: {
      id: string;
      imageUrl?: string | null;
      dateAccepted?: number | null;
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
      updatedAt: string;
    };
    task: {
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    status: string;
    progress?: number | null;
    comments?: Array<{
      commentBy?: string | null;
      commentText?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt?: number | null;
  actor?: string | null;
  actorID?: string | null;
  action?: string | null;
  actionType?: string | null;
  updatedAt: string;
};

export type DeleteMilestoneActionLogMutation = {
  id: string;
  milestoneID: string;
  milestone?: {
    id: string;
    programID: string;
    program?: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    } | null;
    milestoneName: string;
    dueDate: number;
    tasks?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdBy?: string | null;
    description?: string | null;
    termName?: string | null;
    progress?: number | null;
    evaluation?: Array<{
      status?: string | null;
      feedBackToProgramAdmin?: string | null;
      menteeID?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  menteeTaskID: string;
  menteeTask?: {
    id: string;
    menteeID: string;
    taskID: string;
    mentee: {
      id: string;
      imageUrl?: string | null;
      dateAccepted?: number | null;
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
      updatedAt: string;
    };
    task: {
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    status: string;
    progress?: number | null;
    comments?: Array<{
      commentBy?: string | null;
      commentText?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt?: number | null;
  actor?: string | null;
  actorID?: string | null;
  action?: string | null;
  actionType?: string | null;
  updatedAt: string;
};

export type CreateTaskMutation = {
  id: string;
  milestoneID: string;
  milestone?: {
    id: string;
    programID: string;
    program?: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    } | null;
    milestoneName: string;
    dueDate: number;
    tasks?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdBy?: string | null;
    description?: string | null;
    termName?: string | null;
    progress?: number | null;
    evaluation?: Array<{
      status?: string | null;
      feedBackToProgramAdmin?: string | null;
      menteeID?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assignedToMentees?: {
    items?: Array<{
      id: string;
      menteeID: string;
      taskID: string;
      status: string;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  taskName: string;
  description: string;
  taskType?: string | null;
  dueDate: number;
  uploadRequired?: boolean | null;
  attachments?: Array<{
    attachmentURL?: string | null;
    attachmentText?: string | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTaskMutation = {
  id: string;
  milestoneID: string;
  milestone?: {
    id: string;
    programID: string;
    program?: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    } | null;
    milestoneName: string;
    dueDate: number;
    tasks?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdBy?: string | null;
    description?: string | null;
    termName?: string | null;
    progress?: number | null;
    evaluation?: Array<{
      status?: string | null;
      feedBackToProgramAdmin?: string | null;
      menteeID?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assignedToMentees?: {
    items?: Array<{
      id: string;
      menteeID: string;
      taskID: string;
      status: string;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  taskName: string;
  description: string;
  taskType?: string | null;
  dueDate: number;
  uploadRequired?: boolean | null;
  attachments?: Array<{
    attachmentURL?: string | null;
    attachmentText?: string | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTaskMutation = {
  id: string;
  milestoneID: string;
  milestone?: {
    id: string;
    programID: string;
    program?: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    } | null;
    milestoneName: string;
    dueDate: number;
    tasks?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdBy?: string | null;
    description?: string | null;
    termName?: string | null;
    progress?: number | null;
    evaluation?: Array<{
      status?: string | null;
      feedBackToProgramAdmin?: string | null;
      menteeID?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assignedToMentees?: {
    items?: Array<{
      id: string;
      menteeID: string;
      taskID: string;
      status: string;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  taskName: string;
  description: string;
  taskType?: string | null;
  dueDate: number;
  uploadRequired?: boolean | null;
  attachments?: Array<{
    attachmentURL?: string | null;
    attachmentText?: string | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateMenteeTaskMutation = {
  id: string;
  menteeID: string;
  taskID: string;
  mentee: {
    id: string;
    menteeApplications?: {
      nextToken?: string | null;
    } | null;
    imageUrl?: string | null;
    dateAccepted?: number | null;
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    tasksAssigned?: {
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  task: {
    id: string;
    milestoneID: string;
    milestone?: {
      id: string;
      programID: string;
      milestoneName: string;
      dueDate: number;
      status: string;
      createdBy?: string | null;
      description?: string | null;
      termName?: string | null;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    assignedToMentees?: {
      nextToken?: string | null;
    } | null;
    taskName: string;
    description: string;
    taskType?: string | null;
    dueDate: number;
    uploadRequired?: boolean | null;
    attachments?: Array<{
      attachmentURL?: string | null;
      attachmentText?: string | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  };
  status: string;
  progress?: number | null;
  comments?: Array<{
    commentBy?: string | null;
    commentText?: string | null;
    createdAt?: number | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMenteeTaskMutation = {
  id: string;
  menteeID: string;
  taskID: string;
  mentee: {
    id: string;
    menteeApplications?: {
      nextToken?: string | null;
    } | null;
    imageUrl?: string | null;
    dateAccepted?: number | null;
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    tasksAssigned?: {
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  task: {
    id: string;
    milestoneID: string;
    milestone?: {
      id: string;
      programID: string;
      milestoneName: string;
      dueDate: number;
      status: string;
      createdBy?: string | null;
      description?: string | null;
      termName?: string | null;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    assignedToMentees?: {
      nextToken?: string | null;
    } | null;
    taskName: string;
    description: string;
    taskType?: string | null;
    dueDate: number;
    uploadRequired?: boolean | null;
    attachments?: Array<{
      attachmentURL?: string | null;
      attachmentText?: string | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  };
  status: string;
  progress?: number | null;
  comments?: Array<{
    commentBy?: string | null;
    commentText?: string | null;
    createdAt?: number | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteMenteeTaskMutation = {
  id: string;
  menteeID: string;
  taskID: string;
  mentee: {
    id: string;
    menteeApplications?: {
      nextToken?: string | null;
    } | null;
    imageUrl?: string | null;
    dateAccepted?: number | null;
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    tasksAssigned?: {
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  task: {
    id: string;
    milestoneID: string;
    milestone?: {
      id: string;
      programID: string;
      milestoneName: string;
      dueDate: number;
      status: string;
      createdBy?: string | null;
      description?: string | null;
      termName?: string | null;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    assignedToMentees?: {
      nextToken?: string | null;
    } | null;
    taskName: string;
    description: string;
    taskType?: string | null;
    dueDate: number;
    uploadRequired?: boolean | null;
    attachments?: Array<{
      attachmentURL?: string | null;
      attachmentText?: string | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  };
  status: string;
  progress?: number | null;
  comments?: Array<{
    commentBy?: string | null;
    commentText?: string | null;
    createdAt?: number | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type CreatePreRequisiteTaskMutation = {
  id: string;
  programID: string;
  applicationID: string;
  prerequisiteName: string;
  dueDate?: number | null;
  submission?: string | null;
  submitFile?: string | null;
  githubURL?: string | null;
  description: string;
  category: string;
  custom: boolean;
  status: string;
  comments?: Array<{
    commentBy?: string | null;
    commentText?: string | null;
    createdAt?: number | null;
  } | null> | null;
  mentorFeedBack?: string | null;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdatePreRequisiteTaskMutation = {
  id: string;
  programID: string;
  applicationID: string;
  prerequisiteName: string;
  dueDate?: number | null;
  submission?: string | null;
  submitFile?: string | null;
  githubURL?: string | null;
  description: string;
  category: string;
  custom: boolean;
  status: string;
  comments?: Array<{
    commentBy?: string | null;
    commentText?: string | null;
    createdAt?: number | null;
  } | null> | null;
  mentorFeedBack?: string | null;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type DeletePreRequisiteTaskMutation = {
  id: string;
  programID: string;
  applicationID: string;
  prerequisiteName: string;
  dueDate?: number | null;
  submission?: string | null;
  submitFile?: string | null;
  githubURL?: string | null;
  description: string;
  category: string;
  custom: boolean;
  status: string;
  comments?: Array<{
    commentBy?: string | null;
    commentText?: string | null;
    createdAt?: number | null;
  } | null> | null;
  mentorFeedBack?: string | null;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateMentorReviewMutation = {
  id: string;
  mentorID: string;
  menteeApplicationID: string;
  menteeApplication: {
    id: string;
    userID: string;
    programID: string;
    program: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    };
    mentee: {
      id: string;
      imageUrl?: string | null;
      dateAccepted?: number | null;
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
      updatedAt: string;
    };
    applicationStatus: string;
    applicationDate: number;
    preRequisites?: {
      nextToken?: string | null;
    } | null;
    dateAssigned?: number | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    evaluations?: Array<{
      evaluationName: string;
      dueDate: number;
      status: string;
    } | null> | null;
    feedBacks?: Array<{
      feedBackName: string;
      date: number;
      status: string;
    } | null> | null;
    decisionReason?: string | null;
    certificate?: string | null;
    overallFeedback?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  mentor: {
    id: string;
    userID: string;
    programs?: {
      nextToken?: string | null;
    } | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    companyName?: string | null;
    githubHandle?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  status: string;
  feedback?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMentorReviewMutation = {
  id: string;
  mentorID: string;
  menteeApplicationID: string;
  menteeApplication: {
    id: string;
    userID: string;
    programID: string;
    program: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    };
    mentee: {
      id: string;
      imageUrl?: string | null;
      dateAccepted?: number | null;
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
      updatedAt: string;
    };
    applicationStatus: string;
    applicationDate: number;
    preRequisites?: {
      nextToken?: string | null;
    } | null;
    dateAssigned?: number | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    evaluations?: Array<{
      evaluationName: string;
      dueDate: number;
      status: string;
    } | null> | null;
    feedBacks?: Array<{
      feedBackName: string;
      date: number;
      status: string;
    } | null> | null;
    decisionReason?: string | null;
    certificate?: string | null;
    overallFeedback?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  mentor: {
    id: string;
    userID: string;
    programs?: {
      nextToken?: string | null;
    } | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    companyName?: string | null;
    githubHandle?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  status: string;
  feedback?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteMentorReviewMutation = {
  id: string;
  mentorID: string;
  menteeApplicationID: string;
  menteeApplication: {
    id: string;
    userID: string;
    programID: string;
    program: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    };
    mentee: {
      id: string;
      imageUrl?: string | null;
      dateAccepted?: number | null;
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
      updatedAt: string;
    };
    applicationStatus: string;
    applicationDate: number;
    preRequisites?: {
      nextToken?: string | null;
    } | null;
    dateAssigned?: number | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    evaluations?: Array<{
      evaluationName: string;
      dueDate: number;
      status: string;
    } | null> | null;
    feedBacks?: Array<{
      feedBackName: string;
      date: number;
      status: string;
    } | null> | null;
    decisionReason?: string | null;
    certificate?: string | null;
    overallFeedback?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  mentor: {
    id: string;
    userID: string;
    programs?: {
      nextToken?: string | null;
    } | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    companyName?: string | null;
    githubHandle?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  status: string;
  feedback?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type GetProgramQuery = {
  id: string;
  projectID?: string | null;
  organizationID: string;
  departmentName?: string | null;
  programName: string;
  description: string;
  websiteURL?: string | null;
  programDetail: {
    programLogoURL: string;
    learningObjectives: Array<{
      title: string;
      text: string;
    }>;
    relevantLinks?: Array<{
      title: string;
      url: string;
    }> | null;
    requiredSkillsAndTechnologies: Array<string>;
    availableMenteeSpots?: number | null;
    publish?: Array<{
      publishTo: string;
      url: string;
    }> | null;
  };
  preRequisiteTasks: Array<{
    prerequisiteName: string;
    dueDate?: number | null;
    submission?: string | null;
    submitFile?: string | null;
    githubURL?: string | null;
    description: string;
    custom: boolean;
  } | null>;
  terms: Array<{
    termName: string;
    termStartDate: number;
    termEndDate: number;
    applicationAcceptanceStartDate: number;
    applicationAcceptanceEndDate: number;
    tasks?: Array<{
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
  } | null>;
  coAdmins?: Array<{
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    invitationStatus: string;
    companyName?: string | null;
    githubHandle?: string | null;
    emailMessage: string;
    userID: string;
  } | null> | null;
  mentors?: {
    items?: Array<{
      id: string;
      mentor: Mentor;
      programID: string;
      mentorID: string;
      invitationStatus: string;
      emailMessage: string;
      dateAccepted?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  mentees?: {
    items?: Array<{
      id: string;
      mentee: Mentee;
      userID: string;
      programID: string;
      applicationStatus: string;
      applicationDate: number;
      dateAssigned?: number | null;
      decisionReason?: string | null;
      certificate?: string | null;
      overallFeedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  status: string;
  createdAt: number;
  createdBy: string;
  updatedAt?: number | null;
  updatedBy?: string | null;
};

export type ListProgramsQuery = {
  items?: Array<GetProgramQuery | null> | null;
  nextToken?: string | null;
};

export type GetMentorQuery = {
  id: string;
  userID: string;
  programs?: {
    items?: Array<{
      id: string;
      programID: string;
      mentorID: string;
      invitationStatus: string;
      emailMessage: string;
      dateAccepted?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  mentorReviews?: {
    items?: Array<{
      id: string;
      mentorID: string;
      menteeApplicationID: string;
      status: string;
      feedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  firstName: string;
  lastName: string;
  imageUrl?: string | null;
  email: string;
  role?: string | null;
  companyName?: string | null;
  githubHandle?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListMentorsQuery = {
  items?: Array<{
    id: string;
    userID: string;
    programs?: {
      nextToken?: string | null;
    } | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    companyName?: string | null;
    githubHandle?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetMentorProgramQuery = {
  id: string;
  programID: string;
  mentorID: string;
  program: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  };
  mentor: {
    id: string;
    userID: string;
    programs?: {
      nextToken?: string | null;
    } | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    companyName?: string | null;
    githubHandle?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  invitationStatus: string;
  emailMessage: string;
  dateAccepted?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type ListMentorProgramsQuery = {
  items?: Array<{
    id: string;
    programID: string;
    mentorID: string;
    program: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    };
    mentor: {
      id: string;
      userID: string;
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      companyName?: string | null;
      githubHandle?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    invitationStatus: string;
    emailMessage: string;
    dateAccepted?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetMenteeQuery = {
  id: string;
  menteeApplications?: {
    items?: Array<{
      id: string;
      userID: string;
      programID: string;
      applicationStatus: string;
      applicationDate: number;
      dateAssigned?: number | null;
      decisionReason?: string | null;
      certificate?: string | null;
      overallFeedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  imageUrl?: string | null;
  dateAccepted?: number | null;
  userID: string;
  firstName: string;
  lastName: string;
  email: string;
  tasksAssigned?: {
    items?: Array<{
      id: string;
      menteeID: string;
      taskID: string;
      status: string;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListMenteesQuery = {
  items?: Array<{
    id: string;
    menteeApplications?: {
      nextToken?: string | null;
    } | null;
    imageUrl?: string | null;
    dateAccepted?: number | null;
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    tasksAssigned?: {
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetMenteeApplicationQuery = {
  id: string;
  userID: string;
  programID: string;
  program: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  };
  mentee: {
    id: string;
    menteeApplications?: {
      nextToken?: string | null;
    } | null;
    imageUrl?: string | null;
    dateAccepted?: number | null;
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    tasksAssigned?: {
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  applicationStatus: string;
  applicationDate: number;
  preRequisites?: {
    items?: Array<{
      id: string;
      programID: string;
      applicationID: string;
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      category: string;
      custom: boolean;
      status: string;
      mentorFeedBack?: string | null;
      userID: string;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  dateAssigned?: number | null;
  mentorReviews?: {
    items?: Array<{
      id: string;
      mentorID: string;
      mentor: Mentor;
      menteeApplicationID: string;
      status: string;
      feedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  evaluations?: Array<{
    evaluationName: string;
    dueDate: number;
    status: string;
  } | null> | null;
  feedBacks?: Array<{
    feedBackName: string;
    date: number;
    status: string;
  } | null> | null;
  decisionReason?: string | null;
  certificate?: string | null;
  overallFeedback?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListMenteeApplicationsQuery = {
  items?: Array<GetMenteeApplicationQuery | null> | null;
  nextToken?: string | null;
};

export type GetMilestoneQuery = {
  id: string;
  programID: string;
  program?: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  } | null;
  milestoneName: string;
  dueDate: number;
  tasks?: {
    items?: Array<{
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  status: string;
  createdBy?: string | null;
  description?: string | null;
  termName?: string | null;
  progress?: number | null;
  evaluation?: Array<{
    status?: string | null;
    feedBackToProgramAdmin?: string | null;
    menteeID?: string | null;
    createdAt?: number | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type ListMilestonesQuery = {
  items?: Array<{
    id: string;
    programID: string;
    program?: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    } | null;
    milestoneName: string;
    dueDate: number;
    tasks?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdBy?: string | null;
    description?: string | null;
    termName?: string | null;
    progress?: number | null;
    evaluation?: Array<{
      status?: string | null;
      feedBackToProgramAdmin?: string | null;
      menteeID?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetMilestoneActionLogQuery = {
  id: string;
  milestoneID: string;
  milestone?: {
    id: string;
    programID: string;
    program?: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    } | null;
    milestoneName: string;
    dueDate: number;
    tasks?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdBy?: string | null;
    description?: string | null;
    termName?: string | null;
    progress?: number | null;
    evaluation?: Array<{
      status?: string | null;
      feedBackToProgramAdmin?: string | null;
      menteeID?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  menteeTaskID: string;
  menteeTask?: {
    id: string;
    menteeID: string;
    taskID: string;
    mentee: {
      id: string;
      imageUrl?: string | null;
      dateAccepted?: number | null;
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
      updatedAt: string;
    };
    task: {
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    status: string;
    progress?: number | null;
    comments?: Array<{
      commentBy?: string | null;
      commentText?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt?: number | null;
  actor?: string | null;
  actorID?: string | null;
  action?: string | null;
  actionType?: string | null;
  updatedAt: string;
};

export type ListMilestoneActionLogsQuery = {
  items?: Array<{
    id: string;
    milestoneID: string;
    milestone?: {
      id: string;
      programID: string;
      milestoneName: string;
      dueDate: number;
      status: string;
      createdBy?: string | null;
      description?: string | null;
      termName?: string | null;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    menteeTaskID: string;
    menteeTask?: {
      id: string;
      menteeID: string;
      taskID: string;
      status: string;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt?: number | null;
    actor?: string | null;
    actorID?: string | null;
    action?: string | null;
    actionType?: string | null;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetTaskQuery = {
  id: string;
  milestoneID: string;
  milestone?: {
    id: string;
    programID: string;
    program?: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    } | null;
    milestoneName: string;
    dueDate: number;
    tasks?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdBy?: string | null;
    description?: string | null;
    termName?: string | null;
    progress?: number | null;
    evaluation?: Array<{
      status?: string | null;
      feedBackToProgramAdmin?: string | null;
      menteeID?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assignedToMentees?: {
    items?: Array<{
      id: string;
      menteeID: string;
      taskID: string;
      status: string;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  taskName: string;
  description: string;
  taskType?: string | null;
  dueDate: number;
  uploadRequired?: boolean | null;
  attachments?: Array<{
    attachmentURL?: string | null;
    attachmentText?: string | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type ListTasksQuery = {
  items?: Array<{
    id: string;
    milestoneID: string;
    milestone?: {
      id: string;
      programID: string;
      milestoneName: string;
      dueDate: number;
      status: string;
      createdBy?: string | null;
      description?: string | null;
      termName?: string | null;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    assignedToMentees?: {
      nextToken?: string | null;
    } | null;
    taskName: string;
    description: string;
    taskType?: string | null;
    dueDate: number;
    uploadRequired?: boolean | null;
    attachments?: Array<{
      attachmentURL?: string | null;
      attachmentText?: string | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetMenteeTaskQuery = {
  id: string;
  menteeID: string;
  taskID: string;
  mentee: {
    id: string;
    menteeApplications?: {
      nextToken?: string | null;
    } | null;
    imageUrl?: string | null;
    dateAccepted?: number | null;
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    tasksAssigned?: {
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  task: {
    id: string;
    milestoneID: string;
    milestone?: {
      id: string;
      programID: string;
      milestoneName: string;
      dueDate: number;
      status: string;
      createdBy?: string | null;
      description?: string | null;
      termName?: string | null;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    assignedToMentees?: {
      nextToken?: string | null;
    } | null;
    taskName: string;
    description: string;
    taskType?: string | null;
    dueDate: number;
    uploadRequired?: boolean | null;
    attachments?: Array<{
      attachmentURL?: string | null;
      attachmentText?: string | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  };
  status: string;
  progress?: number | null;
  comments?: Array<{
    commentBy?: string | null;
    commentText?: string | null;
    createdAt?: number | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type ListMenteeTasksQuery = {
  items?: Array<{
    id: string;
    menteeID: string;
    taskID: string;
    mentee: {
      id: string;
      imageUrl?: string | null;
      dateAccepted?: number | null;
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
      updatedAt: string;
    };
    task: {
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    status: string;
    progress?: number | null;
    comments?: Array<{
      commentBy?: string | null;
      commentText?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetPreRequisiteTaskQuery = {
  id: string;
  programID: string;
  applicationID: string;
  prerequisiteName: string;
  dueDate?: number | null;
  submission?: string | null;
  submitFile?: string | null;
  githubURL?: string | null;
  description: string;
  category: string;
  custom: boolean;
  status: string;
  comments?: Array<{
    commentBy?: string | null;
    commentText?: string | null;
    createdAt?: number | null;
  } | null> | null;
  mentorFeedBack?: string | null;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type ListPreRequisiteTasksQuery = {
  items?: Array<{
    id: string;
    programID: string;
    applicationID: string;
    prerequisiteName: string;
    dueDate?: number | null;
    submission?: string | null;
    submitFile?: string | null;
    githubURL?: string | null;
    description: string;
    category: string;
    custom: boolean;
    status: string;
    comments?: Array<{
      commentBy?: string | null;
      commentText?: string | null;
      createdAt?: number | null;
    } | null> | null;
    mentorFeedBack?: string | null;
    userID: string;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetMentorReviewQuery = {
  id: string;
  mentorID: string;
  menteeApplicationID: string;
  menteeApplication: {
    id: string;
    userID: string;
    programID: string;
    program: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    };
    mentee: {
      id: string;
      imageUrl?: string | null;
      dateAccepted?: number | null;
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
      updatedAt: string;
    };
    applicationStatus: string;
    applicationDate: number;
    preRequisites?: {
      nextToken?: string | null;
    } | null;
    dateAssigned?: number | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    evaluations?: Array<{
      evaluationName: string;
      dueDate: number;
      status: string;
    } | null> | null;
    feedBacks?: Array<{
      feedBackName: string;
      date: number;
      status: string;
    } | null> | null;
    decisionReason?: string | null;
    certificate?: string | null;
    overallFeedback?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  mentor: {
    id: string;
    userID: string;
    programs?: {
      nextToken?: string | null;
    } | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    companyName?: string | null;
    githubHandle?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  status: string;
  feedback?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListMentorReviewsQuery = {
  items?: Array<{
    id: string;
    mentorID: string;
    menteeApplicationID: string;
    menteeApplication: {
      id: string;
      userID: string;
      programID: string;
      applicationStatus: string;
      applicationDate: number;
      dateAssigned?: number | null;
      decisionReason?: string | null;
      certificate?: string | null;
      overallFeedback?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    mentor: {
      id: string;
      userID: string;
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      companyName?: string | null;
      githubHandle?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    status: string;
    feedback?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type OnCreateProgramSubscription = {
  id: string;
  projectID?: string | null;
  organizationID: string;
  departmentName?: string | null;
  programName: string;
  description: string;
  websiteURL?: string | null;
  programDetail: {
    programLogoURL: string;
    learningObjectives: Array<{
      title: string;
      text: string;
    }>;
    relevantLinks?: Array<{
      title: string;
      url: string;
    }> | null;
    requiredSkillsAndTechnologies: Array<string>;
    availableMenteeSpots?: number | null;
    publish?: Array<{
      publishTo: string;
      url: string;
    }> | null;
  };
  preRequisiteTasks: Array<{
    prerequisiteName: string;
    dueDate?: number | null;
    submission?: string | null;
    submitFile?: string | null;
    githubURL?: string | null;
    description: string;
    custom: boolean;
  } | null>;
  terms: Array<{
    termName: string;
    termStartDate: number;
    termEndDate: number;
    applicationAcceptanceStartDate: number;
    applicationAcceptanceEndDate: number;
    tasks?: Array<{
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
  } | null>;
  coAdmins?: Array<{
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    invitationStatus: string;
    companyName?: string | null;
    githubHandle?: string | null;
    emailMessage: string;
    userID: string;
  } | null> | null;
  mentors?: {
    items?: Array<{
      id: string;
      programID: string;
      mentorID: string;
      invitationStatus: string;
      emailMessage: string;
      dateAccepted?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  mentees?: {
    items?: Array<{
      id: string;
      userID: string;
      programID: string;
      applicationStatus: string;
      applicationDate: number;
      dateAssigned?: number | null;
      decisionReason?: string | null;
      certificate?: string | null;
      overallFeedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  status: string;
  createdAt: number;
  createdBy: string;
  updatedAt?: number | null;
  updatedBy?: string | null;
};

export type OnUpdateProgramSubscription = {
  id: string;
  projectID?: string | null;
  organizationID: string;
  departmentName?: string | null;
  programName: string;
  description: string;
  websiteURL?: string | null;
  programDetail: {
    programLogoURL: string;
    learningObjectives: Array<{
      title: string;
      text: string;
    }>;
    relevantLinks?: Array<{
      title: string;
      url: string;
    }> | null;
    requiredSkillsAndTechnologies: Array<string>;
    availableMenteeSpots?: number | null;
    publish?: Array<{
      publishTo: string;
      url: string;
    }> | null;
  };
  preRequisiteTasks: Array<{
    prerequisiteName: string;
    dueDate?: number | null;
    submission?: string | null;
    submitFile?: string | null;
    githubURL?: string | null;
    description: string;
    custom: boolean;
  } | null>;
  terms: Array<{
    termName: string;
    termStartDate: number;
    termEndDate: number;
    applicationAcceptanceStartDate: number;
    applicationAcceptanceEndDate: number;
    tasks?: Array<{
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
  } | null>;
  coAdmins?: Array<{
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    invitationStatus: string;
    companyName?: string | null;
    githubHandle?: string | null;
    emailMessage: string;
    userID: string;
  } | null> | null;
  mentors?: {
    items?: Array<{
      id: string;
      programID: string;
      mentorID: string;
      invitationStatus: string;
      emailMessage: string;
      dateAccepted?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  mentees?: {
    items?: Array<{
      id: string;
      userID: string;
      programID: string;
      applicationStatus: string;
      applicationDate: number;
      dateAssigned?: number | null;
      decisionReason?: string | null;
      certificate?: string | null;
      overallFeedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  status: string;
  createdAt: number;
  createdBy: string;
  updatedAt?: number | null;
  updatedBy?: string | null;
};

export type OnDeleteProgramSubscription = {
  id: string;
  projectID?: string | null;
  organizationID: string;
  departmentName?: string | null;
  programName: string;
  description: string;
  websiteURL?: string | null;
  programDetail: {
    programLogoURL: string;
    learningObjectives: Array<{
      title: string;
      text: string;
    }>;
    relevantLinks?: Array<{
      title: string;
      url: string;
    }> | null;
    requiredSkillsAndTechnologies: Array<string>;
    availableMenteeSpots?: number | null;
    publish?: Array<{
      publishTo: string;
      url: string;
    }> | null;
  };
  preRequisiteTasks: Array<{
    prerequisiteName: string;
    dueDate?: number | null;
    submission?: string | null;
    submitFile?: string | null;
    githubURL?: string | null;
    description: string;
    custom: boolean;
  } | null>;
  terms: Array<{
    termName: string;
    termStartDate: number;
    termEndDate: number;
    applicationAcceptanceStartDate: number;
    applicationAcceptanceEndDate: number;
    tasks?: Array<{
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
  } | null>;
  coAdmins?: Array<{
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    invitationStatus: string;
    companyName?: string | null;
    githubHandle?: string | null;
    emailMessage: string;
    userID: string;
  } | null> | null;
  mentors?: {
    items?: Array<{
      id: string;
      programID: string;
      mentorID: string;
      invitationStatus: string;
      emailMessage: string;
      dateAccepted?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  mentees?: {
    items?: Array<{
      id: string;
      userID: string;
      programID: string;
      applicationStatus: string;
      applicationDate: number;
      dateAssigned?: number | null;
      decisionReason?: string | null;
      certificate?: string | null;
      overallFeedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  status: string;
  createdAt: number;
  createdBy: string;
  updatedAt?: number | null;
  updatedBy?: string | null;
};

export type OnCreateMentorSubscription = {
  id: string;
  userID: string;
  programs?: {
    items?: Array<{
      id: string;
      programID: string;
      mentorID: string;
      invitationStatus: string;
      emailMessage: string;
      dateAccepted?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  mentorReviews?: {
    items?: Array<{
      id: string;
      mentorID: string;
      menteeApplicationID: string;
      status: string;
      feedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  firstName: string;
  lastName: string;
  imageUrl?: string | null;
  email: string;
  role?: string | null;
  companyName?: string | null;
  githubHandle?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateMentorSubscription = {
  id: string;
  userID: string;
  programs?: {
    items?: Array<{
      id: string;
      programID: string;
      mentorID: string;
      invitationStatus: string;
      emailMessage: string;
      dateAccepted?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  mentorReviews?: {
    items?: Array<{
      id: string;
      mentorID: string;
      menteeApplicationID: string;
      status: string;
      feedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  firstName: string;
  lastName: string;
  imageUrl?: string | null;
  email: string;
  role?: string | null;
  companyName?: string | null;
  githubHandle?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteMentorSubscription = {
  id: string;
  userID: string;
  programs?: {
    items?: Array<{
      id: string;
      programID: string;
      mentorID: string;
      invitationStatus: string;
      emailMessage: string;
      dateAccepted?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  mentorReviews?: {
    items?: Array<{
      id: string;
      mentorID: string;
      menteeApplicationID: string;
      status: string;
      feedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  firstName: string;
  lastName: string;
  imageUrl?: string | null;
  email: string;
  role?: string | null;
  companyName?: string | null;
  githubHandle?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateMentorProgramSubscription = {
  id: string;
  programID: string;
  mentorID: string;
  program: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  };
  mentor: {
    id: string;
    userID: string;
    programs?: {
      nextToken?: string | null;
    } | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    companyName?: string | null;
    githubHandle?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  invitationStatus: string;
  emailMessage: string;
  dateAccepted?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateMentorProgramSubscription = {
  id: string;
  programID: string;
  mentorID: string;
  program: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  };
  mentor: {
    id: string;
    userID: string;
    programs?: {
      nextToken?: string | null;
    } | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    companyName?: string | null;
    githubHandle?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  invitationStatus: string;
  emailMessage: string;
  dateAccepted?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteMentorProgramSubscription = {
  id: string;
  programID: string;
  mentorID: string;
  program: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  };
  mentor: {
    id: string;
    userID: string;
    programs?: {
      nextToken?: string | null;
    } | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    companyName?: string | null;
    githubHandle?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  invitationStatus: string;
  emailMessage: string;
  dateAccepted?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateMenteeSubscription = {
  id: string;
  menteeApplications?: {
    items?: Array<{
      id: string;
      userID: string;
      programID: string;
      applicationStatus: string;
      applicationDate: number;
      dateAssigned?: number | null;
      decisionReason?: string | null;
      certificate?: string | null;
      overallFeedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  imageUrl?: string | null;
  dateAccepted?: number | null;
  userID: string;
  firstName: string;
  lastName: string;
  email: string;
  tasksAssigned?: {
    items?: Array<{
      id: string;
      menteeID: string;
      taskID: string;
      status: string;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateMenteeSubscription = {
  id: string;
  menteeApplications?: {
    items?: Array<{
      id: string;
      userID: string;
      programID: string;
      applicationStatus: string;
      applicationDate: number;
      dateAssigned?: number | null;
      decisionReason?: string | null;
      certificate?: string | null;
      overallFeedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  imageUrl?: string | null;
  dateAccepted?: number | null;
  userID: string;
  firstName: string;
  lastName: string;
  email: string;
  tasksAssigned?: {
    items?: Array<{
      id: string;
      menteeID: string;
      taskID: string;
      status: string;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteMenteeSubscription = {
  id: string;
  menteeApplications?: {
    items?: Array<{
      id: string;
      userID: string;
      programID: string;
      applicationStatus: string;
      applicationDate: number;
      dateAssigned?: number | null;
      decisionReason?: string | null;
      certificate?: string | null;
      overallFeedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  imageUrl?: string | null;
  dateAccepted?: number | null;
  userID: string;
  firstName: string;
  lastName: string;
  email: string;
  tasksAssigned?: {
    items?: Array<{
      id: string;
      menteeID: string;
      taskID: string;
      status: string;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateMenteeApplicationSubscription = {
  id: string;
  userID: string;
  programID: string;
  program: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  };
  mentee: {
    id: string;
    menteeApplications?: {
      nextToken?: string | null;
    } | null;
    imageUrl?: string | null;
    dateAccepted?: number | null;
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    tasksAssigned?: {
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  applicationStatus: string;
  applicationDate: number;
  preRequisites?: {
    items?: Array<{
      id: string;
      programID: string;
      applicationID: string;
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      category: string;
      custom: boolean;
      status: string;
      mentorFeedBack?: string | null;
      userID: string;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  dateAssigned?: number | null;
  mentorReviews?: {
    items?: Array<{
      id: string;
      mentorID: string;
      menteeApplicationID: string;
      status: string;
      feedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  evaluations?: Array<{
    evaluationName: string;
    dueDate: number;
    status: string;
  } | null> | null;
  feedBacks?: Array<{
    feedBackName: string;
    date: number;
    status: string;
  } | null> | null;
  decisionReason?: string | null;
  certificate?: string | null;
  overallFeedback?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateMenteeApplicationSubscription = {
  id: string;
  userID: string;
  programID: string;
  program: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  };
  mentee: {
    id: string;
    menteeApplications?: {
      nextToken?: string | null;
    } | null;
    imageUrl?: string | null;
    dateAccepted?: number | null;
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    tasksAssigned?: {
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  applicationStatus: string;
  applicationDate: number;
  preRequisites?: {
    items?: Array<{
      id: string;
      programID: string;
      applicationID: string;
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      category: string;
      custom: boolean;
      status: string;
      mentorFeedBack?: string | null;
      userID: string;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  dateAssigned?: number | null;
  mentorReviews?: {
    items?: Array<{
      id: string;
      mentorID: string;
      menteeApplicationID: string;
      status: string;
      feedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  evaluations?: Array<{
    evaluationName: string;
    dueDate: number;
    status: string;
  } | null> | null;
  feedBacks?: Array<{
    feedBackName: string;
    date: number;
    status: string;
  } | null> | null;
  decisionReason?: string | null;
  certificate?: string | null;
  overallFeedback?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteMenteeApplicationSubscription = {
  id: string;
  userID: string;
  programID: string;
  program: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  };
  mentee: {
    id: string;
    menteeApplications?: {
      nextToken?: string | null;
    } | null;
    imageUrl?: string | null;
    dateAccepted?: number | null;
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    tasksAssigned?: {
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  applicationStatus: string;
  applicationDate: number;
  preRequisites?: {
    items?: Array<{
      id: string;
      programID: string;
      applicationID: string;
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      category: string;
      custom: boolean;
      status: string;
      mentorFeedBack?: string | null;
      userID: string;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  dateAssigned?: number | null;
  mentorReviews?: {
    items?: Array<{
      id: string;
      mentorID: string;
      menteeApplicationID: string;
      status: string;
      feedback?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  evaluations?: Array<{
    evaluationName: string;
    dueDate: number;
    status: string;
  } | null> | null;
  feedBacks?: Array<{
    feedBackName: string;
    date: number;
    status: string;
  } | null> | null;
  decisionReason?: string | null;
  certificate?: string | null;
  overallFeedback?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateMilestoneSubscription = {
  id: string;
  programID: string;
  program?: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  } | null;
  milestoneName: string;
  dueDate: number;
  tasks?: {
    items?: Array<{
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  status: string;
  createdBy?: string | null;
  description?: string | null;
  termName?: string | null;
  progress?: number | null;
  evaluation?: Array<{
    status?: string | null;
    feedBackToProgramAdmin?: string | null;
    menteeID?: string | null;
    createdAt?: number | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateMilestoneSubscription = {
  id: string;
  programID: string;
  program?: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  } | null;
  milestoneName: string;
  dueDate: number;
  tasks?: {
    items?: Array<{
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  status: string;
  createdBy?: string | null;
  description?: string | null;
  termName?: string | null;
  progress?: number | null;
  evaluation?: Array<{
    status?: string | null;
    feedBackToProgramAdmin?: string | null;
    menteeID?: string | null;
    createdAt?: number | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteMilestoneSubscription = {
  id: string;
  programID: string;
  program?: {
    id: string;
    projectID?: string | null;
    organizationID: string;
    departmentName?: string | null;
    programName: string;
    description: string;
    websiteURL?: string | null;
    programDetail: {
      programLogoURL: string;
      requiredSkillsAndTechnologies: Array<string>;
      availableMenteeSpots?: number | null;
    };
    preRequisiteTasks: Array<{
      prerequisiteName: string;
      dueDate?: number | null;
      submission?: string | null;
      submitFile?: string | null;
      githubURL?: string | null;
      description: string;
      custom: boolean;
    } | null>;
    terms: Array<{
      termName: string;
      termStartDate: number;
      termEndDate: number;
      applicationAcceptanceStartDate: number;
      applicationAcceptanceEndDate: number;
    } | null>;
    coAdmins?: Array<{
      firstName: string;
      lastName: string;
      imageUrl?: string | null;
      email: string;
      role?: string | null;
      invitationStatus: string;
      companyName?: string | null;
      githubHandle?: string | null;
      emailMessage: string;
      userID: string;
    } | null> | null;
    mentors?: {
      nextToken?: string | null;
    } | null;
    mentees?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdAt: number;
    createdBy: string;
    updatedAt?: number | null;
    updatedBy?: string | null;
  } | null;
  milestoneName: string;
  dueDate: number;
  tasks?: {
    items?: Array<{
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  status: string;
  createdBy?: string | null;
  description?: string | null;
  termName?: string | null;
  progress?: number | null;
  evaluation?: Array<{
    status?: string | null;
    feedBackToProgramAdmin?: string | null;
    menteeID?: string | null;
    createdAt?: number | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateMilestoneActionLogSubscription = {
  id: string;
  milestoneID: string;
  milestone?: {
    id: string;
    programID: string;
    program?: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    } | null;
    milestoneName: string;
    dueDate: number;
    tasks?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdBy?: string | null;
    description?: string | null;
    termName?: string | null;
    progress?: number | null;
    evaluation?: Array<{
      status?: string | null;
      feedBackToProgramAdmin?: string | null;
      menteeID?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  menteeTaskID: string;
  menteeTask?: {
    id: string;
    menteeID: string;
    taskID: string;
    mentee: {
      id: string;
      imageUrl?: string | null;
      dateAccepted?: number | null;
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
      updatedAt: string;
    };
    task: {
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    status: string;
    progress?: number | null;
    comments?: Array<{
      commentBy?: string | null;
      commentText?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt?: number | null;
  actor?: string | null;
  actorID?: string | null;
  action?: string | null;
  actionType?: string | null;
  updatedAt: string;
};

export type OnUpdateMilestoneActionLogSubscription = {
  id: string;
  milestoneID: string;
  milestone?: {
    id: string;
    programID: string;
    program?: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    } | null;
    milestoneName: string;
    dueDate: number;
    tasks?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdBy?: string | null;
    description?: string | null;
    termName?: string | null;
    progress?: number | null;
    evaluation?: Array<{
      status?: string | null;
      feedBackToProgramAdmin?: string | null;
      menteeID?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  menteeTaskID: string;
  menteeTask?: {
    id: string;
    menteeID: string;
    taskID: string;
    mentee: {
      id: string;
      imageUrl?: string | null;
      dateAccepted?: number | null;
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
      updatedAt: string;
    };
    task: {
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    status: string;
    progress?: number | null;
    comments?: Array<{
      commentBy?: string | null;
      commentText?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt?: number | null;
  actor?: string | null;
  actorID?: string | null;
  action?: string | null;
  actionType?: string | null;
  updatedAt: string;
};

export type OnDeleteMilestoneActionLogSubscription = {
  id: string;
  milestoneID: string;
  milestone?: {
    id: string;
    programID: string;
    program?: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    } | null;
    milestoneName: string;
    dueDate: number;
    tasks?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdBy?: string | null;
    description?: string | null;
    termName?: string | null;
    progress?: number | null;
    evaluation?: Array<{
      status?: string | null;
      feedBackToProgramAdmin?: string | null;
      menteeID?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  menteeTaskID: string;
  menteeTask?: {
    id: string;
    menteeID: string;
    taskID: string;
    mentee: {
      id: string;
      imageUrl?: string | null;
      dateAccepted?: number | null;
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
      updatedAt: string;
    };
    task: {
      id: string;
      milestoneID: string;
      taskName: string;
      description: string;
      taskType?: string | null;
      dueDate: number;
      uploadRequired?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    status: string;
    progress?: number | null;
    comments?: Array<{
      commentBy?: string | null;
      commentText?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt?: number | null;
  actor?: string | null;
  actorID?: string | null;
  action?: string | null;
  actionType?: string | null;
  updatedAt: string;
};

export type OnCreateTaskSubscription = {
  id: string;
  milestoneID: string;
  milestone?: {
    id: string;
    programID: string;
    program?: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    } | null;
    milestoneName: string;
    dueDate: number;
    tasks?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdBy?: string | null;
    description?: string | null;
    termName?: string | null;
    progress?: number | null;
    evaluation?: Array<{
      status?: string | null;
      feedBackToProgramAdmin?: string | null;
      menteeID?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assignedToMentees?: {
    items?: Array<{
      id: string;
      menteeID: string;
      taskID: string;
      status: string;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  taskName: string;
  description: string;
  taskType?: string | null;
  dueDate: number;
  uploadRequired?: boolean | null;
  attachments?: Array<{
    attachmentURL?: string | null;
    attachmentText?: string | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTaskSubscription = {
  id: string;
  milestoneID: string;
  milestone?: {
    id: string;
    programID: string;
    program?: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    } | null;
    milestoneName: string;
    dueDate: number;
    tasks?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdBy?: string | null;
    description?: string | null;
    termName?: string | null;
    progress?: number | null;
    evaluation?: Array<{
      status?: string | null;
      feedBackToProgramAdmin?: string | null;
      menteeID?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assignedToMentees?: {
    items?: Array<{
      id: string;
      menteeID: string;
      taskID: string;
      status: string;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  taskName: string;
  description: string;
  taskType?: string | null;
  dueDate: number;
  uploadRequired?: boolean | null;
  attachments?: Array<{
    attachmentURL?: string | null;
    attachmentText?: string | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTaskSubscription = {
  id: string;
  milestoneID: string;
  milestone?: {
    id: string;
    programID: string;
    program?: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    } | null;
    milestoneName: string;
    dueDate: number;
    tasks?: {
      nextToken?: string | null;
    } | null;
    status: string;
    createdBy?: string | null;
    description?: string | null;
    termName?: string | null;
    progress?: number | null;
    evaluation?: Array<{
      status?: string | null;
      feedBackToProgramAdmin?: string | null;
      menteeID?: string | null;
      createdAt?: number | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assignedToMentees?: {
    items?: Array<{
      id: string;
      menteeID: string;
      taskID: string;
      status: string;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  taskName: string;
  description: string;
  taskType?: string | null;
  dueDate: number;
  uploadRequired?: boolean | null;
  attachments?: Array<{
    attachmentURL?: string | null;
    attachmentText?: string | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateMenteeTaskSubscription = {
  id: string;
  menteeID: string;
  taskID: string;
  mentee: {
    id: string;
    menteeApplications?: {
      nextToken?: string | null;
    } | null;
    imageUrl?: string | null;
    dateAccepted?: number | null;
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    tasksAssigned?: {
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  task: {
    id: string;
    milestoneID: string;
    milestone?: {
      id: string;
      programID: string;
      milestoneName: string;
      dueDate: number;
      status: string;
      createdBy?: string | null;
      description?: string | null;
      termName?: string | null;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    assignedToMentees?: {
      nextToken?: string | null;
    } | null;
    taskName: string;
    description: string;
    taskType?: string | null;
    dueDate: number;
    uploadRequired?: boolean | null;
    attachments?: Array<{
      attachmentURL?: string | null;
      attachmentText?: string | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  };
  status: string;
  progress?: number | null;
  comments?: Array<{
    commentBy?: string | null;
    commentText?: string | null;
    createdAt?: number | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateMenteeTaskSubscription = {
  id: string;
  menteeID: string;
  taskID: string;
  mentee: {
    id: string;
    menteeApplications?: {
      nextToken?: string | null;
    } | null;
    imageUrl?: string | null;
    dateAccepted?: number | null;
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    tasksAssigned?: {
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  task: {
    id: string;
    milestoneID: string;
    milestone?: {
      id: string;
      programID: string;
      milestoneName: string;
      dueDate: number;
      status: string;
      createdBy?: string | null;
      description?: string | null;
      termName?: string | null;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    assignedToMentees?: {
      nextToken?: string | null;
    } | null;
    taskName: string;
    description: string;
    taskType?: string | null;
    dueDate: number;
    uploadRequired?: boolean | null;
    attachments?: Array<{
      attachmentURL?: string | null;
      attachmentText?: string | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  };
  status: string;
  progress?: number | null;
  comments?: Array<{
    commentBy?: string | null;
    commentText?: string | null;
    createdAt?: number | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteMenteeTaskSubscription = {
  id: string;
  menteeID: string;
  taskID: string;
  mentee: {
    id: string;
    menteeApplications?: {
      nextToken?: string | null;
    } | null;
    imageUrl?: string | null;
    dateAccepted?: number | null;
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    tasksAssigned?: {
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  task: {
    id: string;
    milestoneID: string;
    milestone?: {
      id: string;
      programID: string;
      milestoneName: string;
      dueDate: number;
      status: string;
      createdBy?: string | null;
      description?: string | null;
      termName?: string | null;
      progress?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    assignedToMentees?: {
      nextToken?: string | null;
    } | null;
    taskName: string;
    description: string;
    taskType?: string | null;
    dueDate: number;
    uploadRequired?: boolean | null;
    attachments?: Array<{
      attachmentURL?: string | null;
      attachmentText?: string | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  };
  status: string;
  progress?: number | null;
  comments?: Array<{
    commentBy?: string | null;
    commentText?: string | null;
    createdAt?: number | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreatePreRequisiteTaskSubscription = {
  id: string;
  programID: string;
  applicationID: string;
  prerequisiteName: string;
  dueDate?: number | null;
  submission?: string | null;
  submitFile?: string | null;
  githubURL?: string | null;
  description: string;
  category: string;
  custom: boolean;
  status: string;
  comments?: Array<{
    commentBy?: string | null;
    commentText?: string | null;
    createdAt?: number | null;
  } | null> | null;
  mentorFeedBack?: string | null;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdatePreRequisiteTaskSubscription = {
  id: string;
  programID: string;
  applicationID: string;
  prerequisiteName: string;
  dueDate?: number | null;
  submission?: string | null;
  submitFile?: string | null;
  githubURL?: string | null;
  description: string;
  category: string;
  custom: boolean;
  status: string;
  comments?: Array<{
    commentBy?: string | null;
    commentText?: string | null;
    createdAt?: number | null;
  } | null> | null;
  mentorFeedBack?: string | null;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeletePreRequisiteTaskSubscription = {
  id: string;
  programID: string;
  applicationID: string;
  prerequisiteName: string;
  dueDate?: number | null;
  submission?: string | null;
  submitFile?: string | null;
  githubURL?: string | null;
  description: string;
  category: string;
  custom: boolean;
  status: string;
  comments?: Array<{
    commentBy?: string | null;
    commentText?: string | null;
    createdAt?: number | null;
  } | null> | null;
  mentorFeedBack?: string | null;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateMentorReviewSubscription = {
  id: string;
  mentorID: string;
  menteeApplicationID: string;
  menteeApplication: {
    id: string;
    userID: string;
    programID: string;
    program: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    };
    mentee: {
      id: string;
      imageUrl?: string | null;
      dateAccepted?: number | null;
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
      updatedAt: string;
    };
    applicationStatus: string;
    applicationDate: number;
    preRequisites?: {
      nextToken?: string | null;
    } | null;
    dateAssigned?: number | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    evaluations?: Array<{
      evaluationName: string;
      dueDate: number;
      status: string;
    } | null> | null;
    feedBacks?: Array<{
      feedBackName: string;
      date: number;
      status: string;
    } | null> | null;
    decisionReason?: string | null;
    certificate?: string | null;
    overallFeedback?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  mentor: {
    id: string;
    userID: string;
    programs?: {
      nextToken?: string | null;
    } | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    companyName?: string | null;
    githubHandle?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  status: string;
  feedback?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateMentorReviewSubscription = {
  id: string;
  mentorID: string;
  menteeApplicationID: string;
  menteeApplication: {
    id: string;
    userID: string;
    programID: string;
    program: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    };
    mentee: {
      id: string;
      imageUrl?: string | null;
      dateAccepted?: number | null;
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
      updatedAt: string;
    };
    applicationStatus: string;
    applicationDate: number;
    preRequisites?: {
      nextToken?: string | null;
    } | null;
    dateAssigned?: number | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    evaluations?: Array<{
      evaluationName: string;
      dueDate: number;
      status: string;
    } | null> | null;
    feedBacks?: Array<{
      feedBackName: string;
      date: number;
      status: string;
    } | null> | null;
    decisionReason?: string | null;
    certificate?: string | null;
    overallFeedback?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  mentor: {
    id: string;
    userID: string;
    programs?: {
      nextToken?: string | null;
    } | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    companyName?: string | null;
    githubHandle?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  status: string;
  feedback?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteMentorReviewSubscription = {
  id: string;
  mentorID: string;
  menteeApplicationID: string;
  menteeApplication: {
    id: string;
    userID: string;
    programID: string;
    program: {
      id: string;
      projectID?: string | null;
      organizationID: string;
      departmentName?: string | null;
      programName: string;
      description: string;
      websiteURL?: string | null;
      status: string;
      createdAt: number;
      createdBy: string;
      updatedAt?: number | null;
      updatedBy?: string | null;
    };
    mentee: {
      id: string;
      imageUrl?: string | null;
      dateAccepted?: number | null;
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
      updatedAt: string;
    };
    applicationStatus: string;
    applicationDate: number;
    preRequisites?: {
      nextToken?: string | null;
    } | null;
    dateAssigned?: number | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    evaluations?: Array<{
      evaluationName: string;
      dueDate: number;
      status: string;
    } | null> | null;
    feedBacks?: Array<{
      feedBackName: string;
      date: number;
      status: string;
    } | null> | null;
    decisionReason?: string | null;
    certificate?: string | null;
    overallFeedback?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  mentor: {
    id: string;
    userID: string;
    programs?: {
      nextToken?: string | null;
    } | null;
    mentorReviews?: {
      nextToken?: string | null;
    } | null;
    firstName: string;
    lastName: string;
    imageUrl?: string | null;
    email: string;
    role?: string | null;
    companyName?: string | null;
    githubHandle?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  status: string;
  feedback?: string | null;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateProgram(
    input: CreateProgramInput,
    condition?: ModelProgramConditionInput
  ): Promise<CreateProgramMutation> {
    const statement = `mutation CreateProgram($input: CreateProgramInput!, $condition: ModelProgramConditionInput) {
        createProgram(input: $input, condition: $condition) {
          id
          projectID
          organizationID
          departmentName
          programName
          description
          websiteURL
          programDetail {
            programLogoURL
            learningObjectives {
              title
              text
            }
            relevantLinks {
              title
              url
            }
            requiredSkillsAndTechnologies
            availableMenteeSpots
            publish {
              publishTo
              url
            }
          }
          preRequisiteTasks {
            prerequisiteName
            dueDate
            submission
            submitFile
            githubURL
            description
            custom
          }
          terms {
            termName
            termStartDate
            termEndDate
            applicationAcceptanceStartDate
            applicationAcceptanceEndDate
            tasks {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
          }
          coAdmins {
            firstName
            lastName
            imageUrl
            email
            role
            invitationStatus
            companyName
            githubHandle
            emailMessage
            userID
          }
          mentors {
            items {
              id
              programID
              mentorID
              invitationStatus
              emailMessage
              dateAccepted
              createdAt
              updatedAt
            }
            nextToken
          }
          mentees {
            items {
              id
              userID
              programID
              applicationStatus
              applicationDate
              dateAssigned
              decisionReason
              certificate
              overallFeedback
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProgramMutation>response.data.createProgram;
  }
  async UpdateProgram(
    input: UpdateProgramInput,
    condition?: ModelProgramConditionInput
  ): Promise<UpdateProgramMutation> {
    const statement = `mutation UpdateProgram($input: UpdateProgramInput!, $condition: ModelProgramConditionInput) {
        updateProgram(input: $input, condition: $condition) {
          id
          projectID
          organizationID
          departmentName
          programName
          description
          websiteURL
          programDetail {
            programLogoURL
            learningObjectives {
              title
              text
            }
            relevantLinks {
              title
              url
            }
            requiredSkillsAndTechnologies
            availableMenteeSpots
            publish {
              publishTo
              url
            }
          }
          preRequisiteTasks {
            prerequisiteName
            dueDate
            submission
            submitFile
            githubURL
            description
            custom
          }
          terms {
            termName
            termStartDate
            termEndDate
            applicationAcceptanceStartDate
            applicationAcceptanceEndDate
            tasks {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
            }
          }
          coAdmins {
            firstName
            lastName
            imageUrl
            email
            role
            invitationStatus
            companyName
            githubHandle
            emailMessage
            userID
          }
          mentors {
            items {
              id
              programID
              mentorID
              invitationStatus
              emailMessage
              dateAccepted
            }
            nextToken
          }
          mentees {
            items {
              id
              userID
              programID
              applicationStatus
              applicationDate
              dateAssigned
              decisionReason
              certificate
              overallFeedback
            }
            nextToken
          }
          status
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProgramMutation>response.data.updateProgram;
  }
  async DeleteProgram(
    input: DeleteProgramInput,
    condition?: ModelProgramConditionInput
  ): Promise<DeleteProgramMutation> {
    const statement = `mutation DeleteProgram($input: DeleteProgramInput!, $condition: ModelProgramConditionInput) {
        deleteProgram(input: $input, condition: $condition) {
          id
          projectID
          organizationID
          departmentName
          programName
          description
          websiteURL
          programDetail {
            programLogoURL
            learningObjectives {
              title
              text
            }
            relevantLinks {
              title
              url
            }
            requiredSkillsAndTechnologies
            availableMenteeSpots
            publish {
              publishTo
              url
            }
          }
          preRequisiteTasks {
            prerequisiteName
            dueDate
            submission
            submitFile
            githubURL
            description
            custom
          }
          terms {
            termName
            termStartDate
            termEndDate
            applicationAcceptanceStartDate
            applicationAcceptanceEndDate
            tasks {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
          }
          coAdmins {
            firstName
            lastName
            imageUrl
            email
            role
            invitationStatus
            companyName
            githubHandle
            emailMessage
            userID
          }
          mentors {
            items {
              id
              programID
              mentorID
              invitationStatus
              emailMessage
              dateAccepted
              createdAt
              updatedAt
            }
            nextToken
          }
          mentees {
            items {
              id
              userID
              programID
              applicationStatus
              applicationDate
              dateAssigned
              decisionReason
              certificate
              overallFeedback
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProgramMutation>response.data.deleteProgram;
  }
  async CreateMentor(
    input: CreateMentorInput,
    condition?: ModelMentorConditionInput
  ): Promise<CreateMentorMutation> {
    const statement = `mutation CreateMentor($input: CreateMentorInput!, $condition: ModelMentorConditionInput) {
        createMentor(input: $input, condition: $condition) {
          id
          userID
          programs {
            items {
              id
              programID
              mentorID
              invitationStatus
              emailMessage
              dateAccepted
              createdAt
              updatedAt
            }
            nextToken
          }
          mentorReviews {
            items {
              id
              mentorID
              menteeApplicationID
              status
              feedback
              createdAt
              updatedAt
            }
            nextToken
          }
          firstName
          lastName
          imageUrl
          email
          role
          companyName
          githubHandle
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMentorMutation>response.data.createMentor;
  }
  async UpdateMentor(
    input: UpdateMentorInput,
    condition?: ModelMentorConditionInput
  ): Promise<UpdateMentorMutation> {
    const statement = `mutation UpdateMentor($input: UpdateMentorInput!, $condition: ModelMentorConditionInput) {
        updateMentor(input: $input, condition: $condition) {
          id
          userID
          programs {
            items {
              id
              programID
              mentorID
              invitationStatus
              emailMessage
              dateAccepted
              createdAt
              updatedAt
            }
            nextToken
          }
          mentorReviews {
            items {
              id
              mentorID
              menteeApplicationID
              status
              feedback
              createdAt
              updatedAt
            }
            nextToken
          }
          firstName
          lastName
          imageUrl
          email
          role
          companyName
          githubHandle
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMentorMutation>response.data.updateMentor;
  }
  async DeleteMentor(
    input: DeleteMentorInput,
    condition?: ModelMentorConditionInput
  ): Promise<DeleteMentorMutation> {
    const statement = `mutation DeleteMentor($input: DeleteMentorInput!, $condition: ModelMentorConditionInput) {
        deleteMentor(input: $input, condition: $condition) {
          id
          userID
          programs {
            items {
              id
              programID
              mentorID
              invitationStatus
              emailMessage
              dateAccepted
              createdAt
              updatedAt
            }
            nextToken
          }
          mentorReviews {
            items {
              id
              mentorID
              menteeApplicationID
              status
              feedback
              createdAt
              updatedAt
            }
            nextToken
          }
          firstName
          lastName
          imageUrl
          email
          role
          companyName
          githubHandle
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMentorMutation>response.data.deleteMentor;
  }
  async CreateMentorProgram(
    input: CreateMentorProgramInput,
    condition?: ModelMentorProgramConditionInput
  ): Promise<CreateMentorProgramMutation> {
    const statement = `mutation CreateMentorProgram($input: CreateMentorProgramInput!, $condition: ModelMentorProgramConditionInput) {
        createMentorProgram(input: $input, condition: $condition) {
          id
          programID
          mentorID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          mentor {
            id
            userID
            programs {
              nextToken
            }
            mentorReviews {
              nextToken
            }
            firstName
            lastName
            imageUrl
            email
            role
            companyName
            githubHandle
            createdAt
            updatedAt
          }
          invitationStatus
          emailMessage
          dateAccepted
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMentorProgramMutation>response.data.createMentorProgram;
  }
  async UpdateMentorProgram(
    input: UpdateMentorProgramInput,
    condition?: ModelMentorProgramConditionInput
  ): Promise<UpdateMentorProgramMutation> {
    const statement = `mutation UpdateMentorProgram($input: UpdateMentorProgramInput!, $condition: ModelMentorProgramConditionInput) {
        updateMentorProgram(input: $input, condition: $condition) {
          id
          programID
          mentorID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          mentor {
            id
            userID
            programs {
              nextToken
            }
            mentorReviews {
              nextToken
            }
            firstName
            lastName
            imageUrl
            email
            role
            companyName
            githubHandle
          }
          invitationStatus
          emailMessage
          dateAccepted
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMentorProgramMutation>response.data.updateMentorProgram;
  }
  async DeleteMentorProgram(
    input: DeleteMentorProgramInput,
    condition?: ModelMentorProgramConditionInput
  ): Promise<DeleteMentorProgramMutation> {
    const statement = `mutation DeleteMentorProgram($input: DeleteMentorProgramInput!, $condition: ModelMentorProgramConditionInput) {
        deleteMentorProgram(input: $input, condition: $condition) {
          id
          programID
          mentorID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          mentor {
            id
            userID
            programs {
              nextToken
            }
            mentorReviews {
              nextToken
            }
            firstName
            lastName
            imageUrl
            email
            role
            companyName
            githubHandle
            createdAt
            updatedAt
          }
          invitationStatus
          emailMessage
          dateAccepted
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMentorProgramMutation>response.data.deleteMentorProgram;
  }
  async CreateMentee(
    input: CreateMenteeInput,
    condition?: ModelMenteeConditionInput
  ): Promise<CreateMenteeMutation> {
    const statement = `mutation CreateMentee($input: CreateMenteeInput!, $condition: ModelMenteeConditionInput) {
        createMentee(input: $input, condition: $condition) {
          id
          menteeApplications {
            items {
              id
              userID
              programID
              applicationStatus
              applicationDate
              dateAssigned
              decisionReason
              certificate
              overallFeedback
              createdAt
              updatedAt
            }
            nextToken
          }
          imageUrl
          dateAccepted
          userID
          firstName
          lastName
          email
          tasksAssigned {
            items {
              id
              menteeID
              taskID
              status
              progress
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenteeMutation>response.data.createMentee;
  }
  async UpdateMentee(
    input: UpdateMenteeInput,
    condition?: ModelMenteeConditionInput
  ): Promise<UpdateMenteeMutation> {
    const statement = `mutation UpdateMentee($input: UpdateMenteeInput!, $condition: ModelMenteeConditionInput) {
        updateMentee(input: $input, condition: $condition) {
          id
          menteeApplications {
            items {
              id
              userID
              programID
              applicationStatus
              applicationDate
              dateAssigned
              decisionReason
              certificate
              overallFeedback
              createdAt
              updatedAt
            }
            nextToken
          }
          imageUrl
          dateAccepted
          userID
          firstName
          lastName
          email
          tasksAssigned {
            items {
              id
              menteeID
              taskID
              status
              progress
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenteeMutation>response.data.updateMentee;
  }
  async DeleteMentee(
    input: DeleteMenteeInput,
    condition?: ModelMenteeConditionInput
  ): Promise<DeleteMenteeMutation> {
    const statement = `mutation DeleteMentee($input: DeleteMenteeInput!, $condition: ModelMenteeConditionInput) {
        deleteMentee(input: $input, condition: $condition) {
          id
          menteeApplications {
            items {
              id
              userID
              programID
              applicationStatus
              applicationDate
              dateAssigned
              decisionReason
              certificate
              overallFeedback
              createdAt
              updatedAt
            }
            nextToken
          }
          imageUrl
          dateAccepted
          userID
          firstName
          lastName
          email
          tasksAssigned {
            items {
              id
              menteeID
              taskID
              status
              progress
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenteeMutation>response.data.deleteMentee;
  }
  async CreateMenteeApplication(
    input: CreateMenteeApplicationInput,
    condition?: ModelMenteeApplicationConditionInput
  ): Promise<CreateMenteeApplicationMutation> {
    const statement = `mutation CreateMenteeApplication($input: CreateMenteeApplicationInput!, $condition: ModelMenteeApplicationConditionInput) {
        createMenteeApplication(input: $input, condition: $condition) {
          id
          userID
          programID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          mentee {
            id
            menteeApplications {
              nextToken
            }
            imageUrl
            dateAccepted
            userID
            firstName
            lastName
            email
            tasksAssigned {
              nextToken
            }
            createdAt
            updatedAt
          }
          applicationStatus
          applicationDate
          preRequisites {
            items {
              id
              programID
              applicationID
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              category
              custom
              status
              mentorFeedBack
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          dateAssigned
          mentorReviews {
            items {
              id
              mentorID
              menteeApplicationID
              status
              feedback
              createdAt
              updatedAt
            }
            nextToken
          }
          evaluations {
            evaluationName
            dueDate
            status
          }
          feedBacks {
            feedBackName
            date
            status
          }
          decisionReason
          certificate
          overallFeedback
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenteeApplicationMutation>(
      response.data.createMenteeApplication
    );
  }
  async UpdateMenteeApplication(
    input: UpdateMenteeApplicationInput,
    condition?: ModelMenteeApplicationConditionInput
  ): Promise<UpdateMenteeApplicationMutation> {
    const statement = `mutation UpdateMenteeApplication($input: UpdateMenteeApplicationInput!, $condition: ModelMenteeApplicationConditionInput) {
        updateMenteeApplication(input: $input, condition: $condition) {
          id
          userID
          programID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          mentee {
            id
            menteeApplications {
              nextToken
            }
            imageUrl
            dateAccepted
            userID
            firstName
            lastName
            email
            tasksAssigned {
              nextToken
            }
            createdAt
            updatedAt
          }
          applicationStatus
          applicationDate
          preRequisites {
            items {
              id
              programID
              applicationID
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              category
              custom
              status
              mentorFeedBack
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          dateAssigned
          mentorReviews {
            items {
              id
              mentorID
              menteeApplicationID
              status
              feedback
              createdAt
              updatedAt
            }
            nextToken
          }
          evaluations {
            evaluationName
            dueDate
            status
          }
          feedBacks {
            feedBackName
            date
            status
          }
          decisionReason
          certificate
          overallFeedback
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenteeApplicationMutation>(
      response.data.updateMenteeApplication
    );
  }
  async DeleteMenteeApplication(
    input: DeleteMenteeApplicationInput,
    condition?: ModelMenteeApplicationConditionInput
  ): Promise<DeleteMenteeApplicationMutation> {
    const statement = `mutation DeleteMenteeApplication($input: DeleteMenteeApplicationInput!, $condition: ModelMenteeApplicationConditionInput) {
        deleteMenteeApplication(input: $input, condition: $condition) {
          id
          userID
          programID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          mentee {
            id
            menteeApplications {
              nextToken
            }
            imageUrl
            dateAccepted
            userID
            firstName
            lastName
            email
            tasksAssigned {
              nextToken
            }
            createdAt
            updatedAt
          }
          applicationStatus
          applicationDate
          preRequisites {
            items {
              id
              programID
              applicationID
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              category
              custom
              status
              mentorFeedBack
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          dateAssigned
          mentorReviews {
            items {
              id
              mentorID
              menteeApplicationID
              status
              feedback
              createdAt
              updatedAt
            }
            nextToken
          }
          evaluations {
            evaluationName
            dueDate
            status
          }
          feedBacks {
            feedBackName
            date
            status
          }
          decisionReason
          certificate
          overallFeedback
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenteeApplicationMutation>(
      response.data.deleteMenteeApplication
    );
  }
  async CreateMilestone(
    input: CreateMilestoneInput,
    condition?: ModelMilestoneConditionInput
  ): Promise<CreateMilestoneMutation> {
    const statement = `mutation CreateMilestone($input: CreateMilestoneInput!, $condition: ModelMilestoneConditionInput) {
        createMilestone(input: $input, condition: $condition) {
          id
          programID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
              tasks {
                id
                milestoneID
                taskName
                description
                taskType
                dueDate
                uploadRequired
              }
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              items {
                id
                mentor {
                  id
                  userID
                  email
                  firstName
                  lastName
                  imageUrl
                  role
                }
                invitationStatus
                dateAccepted
                emailMessage
                programID
                mentorID
              }
            }
            mentees {
              items {
                id
                mentee {
                  id
                  userID
                  email
                  firstName
                  lastName
                  imageUrl
                }
                userID
                programID
                applicationStatus
                applicationDate
                dateAssigned
              }
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          milestoneName
          dueDate
          tasks {
            items {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          createdBy
          description
          termName
          progress
          evaluation {
            status
            feedBackToProgramAdmin
            menteeID
            createdAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMilestoneMutation>response.data.createMilestone;
  }
  async UpdateMilestone(
    input: UpdateMilestoneInput,
    condition?: ModelMilestoneConditionInput
  ): Promise<UpdateMilestoneMutation> {
    const statement = `mutation UpdateMilestone($input: UpdateMilestoneInput!, $condition: ModelMilestoneConditionInput) {
        updateMilestone(input: $input, condition: $condition) {
          id
          programID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          milestoneName
          dueDate
          tasks {
            items {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          createdBy
          description
          termName
          progress
          evaluation {
            status
            feedBackToProgramAdmin
            menteeID
            createdAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMilestoneMutation>response.data.updateMilestone;
  }
  async DeleteMilestone(
    input: DeleteMilestoneInput,
    condition?: ModelMilestoneConditionInput
  ): Promise<DeleteMilestoneMutation> {
    const statement = `mutation DeleteMilestone($input: DeleteMilestoneInput!, $condition: ModelMilestoneConditionInput) {
        deleteMilestone(input: $input, condition: $condition) {
          id
          programID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          milestoneName
          dueDate
          tasks {
            items {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          createdBy
          description
          termName
          progress
          evaluation {
            status
            feedBackToProgramAdmin
            menteeID
            createdAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMilestoneMutation>response.data.deleteMilestone;
  }
  async CreateMilestoneActionLog(
    input: CreateMilestoneActionLogInput,
    condition?: ModelMilestoneActionLogConditionInput
  ): Promise<CreateMilestoneActionLogMutation> {
    const statement = `mutation CreateMilestoneActionLog($input: CreateMilestoneActionLogInput!, $condition: ModelMilestoneActionLogConditionInput) {
        createMilestoneActionLog(input: $input, condition: $condition) {
          id
          milestoneID
          milestone {
            id
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            milestoneName
            dueDate
            tasks {
              nextToken
            }
            status
            createdBy
            description
            termName
            progress
            evaluation {
              status
              feedBackToProgramAdmin
              menteeID
              createdAt
            }
            createdAt
            updatedAt
          }
          menteeTaskID
          menteeTask {
            id
            menteeID
            taskID
            mentee {
              id
              imageUrl
              dateAccepted
              userID
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
            task {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
            status
            progress
            comments {
              commentBy
              commentText
              createdAt
            }
            updatedAt
          }
          createdAt
          actor
          actorID
          action
          actionType
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMilestoneActionLogMutation>(
      response.data.createMilestoneActionLog
    );
  }
  async UpdateMilestoneActionLog(
    input: UpdateMilestoneActionLogInput,
    condition?: ModelMilestoneActionLogConditionInput
  ): Promise<UpdateMilestoneActionLogMutation> {
    const statement = `mutation UpdateMilestoneActionLog($input: UpdateMilestoneActionLogInput!, $condition: ModelMilestoneActionLogConditionInput) {
        updateMilestoneActionLog(input: $input, condition: $condition) {
          id
          milestoneID
          milestone {
            id
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            milestoneName
            dueDate
            tasks {
              nextToken
            }
            status
            createdBy
            description
            termName
            progress
            evaluation {
              status
              feedBackToProgramAdmin
              menteeID
              createdAt
            }
            createdAt
            updatedAt
          }
          menteeTaskID
          menteeTask {
            id
            menteeID
            taskID
            mentee {
              id
              imageUrl
              dateAccepted
              userID
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
            task {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
            status
            progress
            comments {
              commentBy
              commentText
              createdAt
            }
            createdAt
            updatedAt
          }
          createdAt
          actor
          actorID
          action
          actionType
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMilestoneActionLogMutation>(
      response.data.updateMilestoneActionLog
    );
  }
  async DeleteMilestoneActionLog(
    input: DeleteMilestoneActionLogInput,
    condition?: ModelMilestoneActionLogConditionInput
  ): Promise<DeleteMilestoneActionLogMutation> {
    const statement = `mutation DeleteMilestoneActionLog($input: DeleteMilestoneActionLogInput!, $condition: ModelMilestoneActionLogConditionInput) {
        deleteMilestoneActionLog(input: $input, condition: $condition) {
          id
          milestoneID
          milestone {
            id
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            milestoneName
            dueDate
            tasks {
              nextToken
            }
            status
            createdBy
            description
            termName
            progress
            evaluation {
              status
              feedBackToProgramAdmin
              menteeID
              createdAt
            }
            createdAt
            updatedAt
          }
          menteeTaskID
          menteeTask {
            id
            menteeID
            taskID
            mentee {
              id
              imageUrl
              dateAccepted
              userID
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
            task {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
            status
            progress
            comments {
              commentBy
              commentText
              createdAt
            }
            createdAt
            updatedAt
          }
          createdAt
          actor
          actorID
          action
          actionType
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMilestoneActionLogMutation>(
      response.data.deleteMilestoneActionLog
    );
  }
  async CreateTask(
    input: CreateTaskInput,
    condition?: ModelTaskConditionInput
  ): Promise<CreateTaskMutation> {
    const statement = `mutation CreateTask($input: CreateTaskInput!, $condition: ModelTaskConditionInput) {
        createTask(input: $input, condition: $condition) {
          id
          milestoneID
          milestone {
            id
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            milestoneName
            dueDate
            tasks {
              nextToken
            }
            status
            createdBy
            description
            termName
            progress
            evaluation {
              status
              feedBackToProgramAdmin
              menteeID
              createdAt
            }
            createdAt
            updatedAt
          }
          assignedToMentees {
            items {
              id
              menteeID
              taskID
              status
              progress
              createdAt
              updatedAt
            }
            nextToken
          }
          taskName
          description
          taskType
          dueDate
          uploadRequired
          attachments {
            attachmentURL
            attachmentText
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTaskMutation>response.data.createTask;
  }
  async UpdateTask(
    input: UpdateTaskInput,
    condition?: ModelTaskConditionInput
  ): Promise<UpdateTaskMutation> {
    const statement = `mutation UpdateTask($input: UpdateTaskInput!, $condition: ModelTaskConditionInput) {
        updateTask(input: $input, condition: $condition) {
          id
          milestoneID
          milestone {
            id
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            milestoneName
            dueDate
            tasks {
              nextToken
            }
            status
            createdBy
            description
            termName
            progress
            evaluation {
              status
              feedBackToProgramAdmin
              menteeID
              createdAt
            }
            createdAt
            updatedAt
          }
          assignedToMentees {
            items {
              id
              menteeID
              taskID
              status
              progress
              createdAt
              updatedAt
            }
            nextToken
          }
          taskName
          description
          taskType
          dueDate
          uploadRequired
          attachments {
            attachmentURL
            attachmentText
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTaskMutation>response.data.updateTask;
  }
  async DeleteTask(
    input: DeleteTaskInput,
    condition?: ModelTaskConditionInput
  ): Promise<DeleteTaskMutation> {
    const statement = `mutation DeleteTask($input: DeleteTaskInput!, $condition: ModelTaskConditionInput) {
        deleteTask(input: $input, condition: $condition) {
          id
          milestoneID
          milestone {
            id
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            milestoneName
            dueDate
            tasks {
              nextToken
            }
            status
            createdBy
            description
            termName
            progress
            evaluation {
              status
              feedBackToProgramAdmin
              menteeID
              createdAt
            }
            createdAt
            updatedAt
          }
          assignedToMentees {
            items {
              id
              menteeID
              taskID
              status
              progress
              createdAt
              updatedAt
            }
            nextToken
          }
          taskName
          description
          taskType
          dueDate
          uploadRequired
          attachments {
            attachmentURL
            attachmentText
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTaskMutation>response.data.deleteTask;
  }
  async CreateMenteeTask(
    input: CreateMenteeTaskInput,
    condition?: ModelMenteeTaskConditionInput
  ): Promise<CreateMenteeTaskMutation> {
    const statement = `mutation CreateMenteeTask($input: CreateMenteeTaskInput!, $condition: ModelMenteeTaskConditionInput) {
        createMenteeTask(input: $input, condition: $condition) {
          id
          menteeID
          taskID
          mentee {
            id
            menteeApplications {
              nextToken
            }
            imageUrl
            dateAccepted
            userID
            firstName
            lastName
            email
            tasksAssigned {
              nextToken
            }
            createdAt
            updatedAt
          }
          task {
            id
            milestoneID
            milestone {
              id
              programID
              milestoneName
              dueDate
              status
              createdBy
              description
              termName
              progress
              createdAt
              updatedAt
            }
            assignedToMentees {
              nextToken
            }
            taskName
            description
            taskType
            dueDate
            uploadRequired
            attachments {
              attachmentURL
              attachmentText
            }
            createdAt
            updatedAt
          }
          status
          progress
          comments {
            commentBy
            commentText
            createdAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenteeTaskMutation>response.data.createMenteeTask;
  }
  async UpdateMenteeTask(
    input: UpdateMenteeTaskInput,
    condition?: ModelMenteeTaskConditionInput
  ): Promise<UpdateMenteeTaskMutation> {
    const statement = `mutation UpdateMenteeTask($input: UpdateMenteeTaskInput!, $condition: ModelMenteeTaskConditionInput) {
        updateMenteeTask(input: $input, condition: $condition) {
          id
          menteeID
          taskID
          status
          mentee {
            id
            menteeApplications {
              nextToken
            }
            imageUrl
            dateAccepted
            userID
            firstName
            lastName
            email
            tasksAssigned {
              nextToken
            }
            createdAt
            updatedAt
          }
          task {
            id
            milestoneID
            milestone {
              id
              programID
              milestoneName
              dueDate
              status
              createdBy
              description
              termName
              progress
              createdAt
              updatedAt
            }
            assignedToMentees {
              nextToken
            }
            taskName
            description
            taskType
            dueDate
            uploadRequired
            attachments {
              attachmentURL
              attachmentText
            }
            createdAt
            updatedAt
          }
          status
          progress
          comments {
            commentBy
            commentText
            createdAt
          }
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenteeTaskMutation>response.data.updateMenteeTask;
  }
  async DeleteMenteeTask(
    input: DeleteMenteeTaskInput,
    condition?: ModelMenteeTaskConditionInput
  ): Promise<DeleteMenteeTaskMutation> {
    const statement = `mutation DeleteMenteeTask($input: DeleteMenteeTaskInput!, $condition: ModelMenteeTaskConditionInput) {
        deleteMenteeTask(input: $input, condition: $condition) {
          id
          menteeID
          taskID
          mentee {
            id
            menteeApplications {
              nextToken
            }
            imageUrl
            dateAccepted
            userID
            firstName
            lastName
            email
            tasksAssigned {
              nextToken
            }
            createdAt
            updatedAt
          }
          task {
            id
            milestoneID
            milestone {
              id
              programID
              milestoneName
              dueDate
              status
              createdBy
              description
              termName
              progress
              createdAt
              updatedAt
            }
            assignedToMentees {
              nextToken
            }
            taskName
            description
            taskType
            dueDate
            uploadRequired
            attachments {
              attachmentURL
              attachmentText
            }
            createdAt
            updatedAt
          }
          status
          progress
          comments {
            commentBy
            commentText
            createdAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenteeTaskMutation>response.data.deleteMenteeTask;
  }
  async CreatePreRequisiteTask(
    input: CreatePreRequisiteTaskInput,
    condition?: ModelPreRequisiteTaskConditionInput
  ): Promise<CreatePreRequisiteTaskMutation> {
    const statement = `mutation CreatePreRequisiteTask($input: CreatePreRequisiteTaskInput!, $condition: ModelPreRequisiteTaskConditionInput) {
        createPreRequisiteTask(input: $input, condition: $condition) {
          id
          programID
          applicationID
          prerequisiteName
          dueDate
          submission
          submitFile
          githubURL
          description
          category
          custom
          status
          comments {
            commentBy
            commentText
            createdAt
          }
          mentorFeedBack
          userID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePreRequisiteTaskMutation>response.data.createPreRequisiteTask;
  }
  async UpdatePreRequisiteTask(
    input: UpdatePreRequisiteTaskInput,
    condition?: ModelPreRequisiteTaskConditionInput
  ): Promise<UpdatePreRequisiteTaskMutation> {
    const statement = `mutation UpdatePreRequisiteTask($input: UpdatePreRequisiteTaskInput!, $condition: ModelPreRequisiteTaskConditionInput) {
        updatePreRequisiteTask(input: $input, condition: $condition) {
          id
          programID
          applicationID
          prerequisiteName
          dueDate
          submission
          submitFile
          githubURL
          description
          category
          custom
          status
          comments {
            commentBy
            commentText
            createdAt
          }
          mentorFeedBack
          userID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePreRequisiteTaskMutation>response.data.updatePreRequisiteTask;
  }
  async DeletePreRequisiteTask(
    input: DeletePreRequisiteTaskInput,
    condition?: ModelPreRequisiteTaskConditionInput
  ): Promise<DeletePreRequisiteTaskMutation> {
    const statement = `mutation DeletePreRequisiteTask($input: DeletePreRequisiteTaskInput!, $condition: ModelPreRequisiteTaskConditionInput) {
        deletePreRequisiteTask(input: $input, condition: $condition) {
          id
          programID
          applicationID
          prerequisiteName
          dueDate
          submission
          submitFile
          githubURL
          description
          category
          custom
          status
          comments {
            commentBy
            commentText
            createdAt
          }
          mentorFeedBack
          userID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePreRequisiteTaskMutation>response.data.deletePreRequisiteTask;
  }
  async CreateMentorReview(
    input: CreateMentorReviewInput,
    condition?: ModelMentorReviewConditionInput
  ): Promise<CreateMentorReviewMutation> {
    const statement = `mutation CreateMentorReview($input: CreateMentorReviewInput!, $condition: ModelMentorReviewConditionInput) {
        createMentorReview(input: $input, condition: $condition) {
          id
          mentorID
          menteeApplicationID
          menteeApplication {
            id
            userID
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            mentee {
              id
              imageUrl
              dateAccepted
              userID
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
            applicationStatus
            applicationDate
            preRequisites {
              nextToken
            }
            dateAssigned
            mentorReviews {
              nextToken
            }
            evaluations {
              evaluationName
              dueDate
              status
            }
            feedBacks {
              feedBackName
              date
              status
            }
            decisionReason
            certificate
            overallFeedback
            createdAt
            updatedAt
          }
          mentor {
            id
            userID
            programs {
              nextToken
            }
            mentorReviews {
              nextToken
            }
            firstName
            lastName
            imageUrl
            email
            role
            companyName
            githubHandle
            createdAt
            updatedAt
          }
          status
          feedback
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMentorReviewMutation>response.data.createMentorReview;
  }
  async UpdateMentorReview(
    input: UpdateMentorReviewInput,
    condition?: ModelMentorReviewConditionInput
  ): Promise<UpdateMentorReviewMutation> {
    const statement = `mutation UpdateMentorReview($input: UpdateMentorReviewInput!, $condition: ModelMentorReviewConditionInput) {
        updateMentorReview(input: $input, condition: $condition) {
          id
          mentorID
          menteeApplicationID
          menteeApplication {
            id
            userID
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            mentee {
              id
              imageUrl
              dateAccepted
              userID
              firstName
              lastName
              email
            }
            applicationStatus
            applicationDate
            preRequisites {
              nextToken
            }
            dateAssigned
            mentorReviews {
              nextToken
            }
            evaluations {
              evaluationName
              dueDate
              status
            }
            feedBacks {
              feedBackName
              date
              status
            }
            decisionReason
            certificate
            overallFeedback
          }
          mentor {
            id
            userID
            programs {
              nextToken
            }
            mentorReviews {
              nextToken
            }
            firstName
            lastName
            imageUrl
            email
            role
            companyName
            githubHandle
          }
          status
          feedback
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMentorReviewMutation>response.data.updateMentorReview;
  }
  async DeleteMentorReview(
    input: DeleteMentorReviewInput,
    condition?: ModelMentorReviewConditionInput
  ): Promise<DeleteMentorReviewMutation> {
    const statement = `mutation DeleteMentorReview($input: DeleteMentorReviewInput!, $condition: ModelMentorReviewConditionInput) {
        deleteMentorReview(input: $input, condition: $condition) {
          id
          mentorID
          menteeApplicationID
          menteeApplication {
            id
            userID
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            mentee {
              id
              imageUrl
              dateAccepted
              userID
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
            applicationStatus
            applicationDate
            preRequisites {
              nextToken
            }
            dateAssigned
            mentorReviews {
              nextToken
            }
            evaluations {
              evaluationName
              dueDate
              status
            }
            feedBacks {
              feedBackName
              date
              status
            }
            decisionReason
            certificate
            overallFeedback
            createdAt
            updatedAt
          }
          mentor {
            id
            userID
            programs {
              nextToken
            }
            mentorReviews {
              nextToken
            }
            firstName
            lastName
            imageUrl
            email
            role
            companyName
            githubHandle
            createdAt
            updatedAt
          }
          status
          feedback
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMentorReviewMutation>response.data.deleteMentorReview;
  }
  async GetProgram(id: string): Promise<GetProgramQuery> {
    const statement = `query GetProgram($id: ID!) {
        getProgram(id: $id) {
          id
          projectID
          organizationID
          departmentName
          programName
          description
          websiteURL
          programDetail {
            programLogoURL
            learningObjectives {
              title
              text
            }
            relevantLinks {
              title
              url
            }
            requiredSkillsAndTechnologies
            availableMenteeSpots
            publish {
              publishTo
              url
            }
          }
          preRequisiteTasks {
            prerequisiteName
            dueDate
            submission
            submitFile
            githubURL
            description
            custom
          }
          terms {
            termName
            termStartDate
            termEndDate
            applicationAcceptanceStartDate
            applicationAcceptanceEndDate
            tasks {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
            }
          }
          coAdmins {
            firstName
            lastName
            imageUrl
            email
            role
            invitationStatus
            companyName
            githubHandle
            emailMessage
            userID
          }
          mentors {
            items {
              id
              mentor {
                id
                userID
                email
                firstName
                lastName
                imageUrl
                role
              }
              programID
              mentorID
              invitationStatus
              emailMessage
              dateAccepted
            }
            nextToken
          }
          mentees {
            items {
              id
              mentee {
                id
                userID
                email
                firstName
                lastName
                imageUrl
              }
              mentorReviews {
                items {
                  id
                  mentorID
                  mentor {
                    id
                    userID
                    email
                    firstName
                    lastName
                    imageUrl
                    role
                  }
                  menteeApplicationID
                  status
                  feedback
                }
                nextToken
              }
              userID
              programID
              applicationStatus
              applicationDate
              dateAssigned
              decisionReason
              certificate
              overallFeedback
            }
            nextToken
          }
          status
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProgramQuery>response.data.getProgram;
  }
  async ListPrograms(
    filter?: ModelProgramFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProgramsQuery> {
    const statement = `query ListPrograms($filter: ModelProgramFilterInput, $limit: Int, $nextToken: String) {
        listPrograms(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              learningObjectives {
                title
                text
              }
              relevantLinks {
                title
                url
              }
              requiredSkillsAndTechnologies
              availableMenteeSpots
              publish {
                publishTo
                url
              }
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
              tasks {
                id
                milestoneID
                taskName
                description
                taskType
                dueDate
                uploadRequired
              }
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              items {
                id
                mentor {
                  id
                  userID
                  email
                  firstName
                  lastName
                  imageUrl
                  role
                }
                invitationStatus
                dateAccepted
                emailMessage
                programID
                mentorID
              }
            }
            mentees {
              items {
                id
                mentee {
                  id
                  userID
                  email
                  firstName
                  lastName
                  imageUrl
                }
                userID
                programID
                applicationStatus
                applicationDate
                dateAssigned
              }
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProgramsQuery>response.data.listPrograms;
  }
  async GetMentor(id: string): Promise<GetMentorQuery> {
    const statement = `query GetMentor($id: ID!) {
        getMentor(id: $id) {
          id
          userID
          programs {
            items {
              id
              program {
                id
                projectID
                organizationID
                departmentName
                programName
                description
                websiteURL
                programDetail {
                  programLogoURL
                  learningObjectives {
                    title
                    text
                  }
                  relevantLinks {
                    title
                    url
                  }
                  requiredSkillsAndTechnologies
                  availableMenteeSpots
                  publish {
                    publishTo
                    url
                  }
                }
                preRequisiteTasks {
                  prerequisiteName
                  dueDate
                  submission
                  submitFile
                  githubURL
                  description
                  custom
                }
                terms {
                  termName
                  termStartDate
                  termEndDate
                  applicationAcceptanceStartDate
                  applicationAcceptanceEndDate
                  tasks {
                    id
                    milestoneID
                    taskName
                    description
                    taskType
                    dueDate
                    uploadRequired
                  }
                }
                coAdmins {
                  firstName
                  lastName
                  imageUrl
                  email
                  role
                  invitationStatus
                  companyName
                  githubHandle
                  emailMessage
                  userID
                }
                mentors {
                  items {
                    id
                    mentor {
                      id
                      userID
                      email
                      firstName
                      lastName
                      imageUrl
                      role
                    }
                    invitationStatus
                    dateAccepted
                    emailMessage
                    programID
                    mentorID
                  }
                }
                mentees {
                  items {
                    id
                    mentee {
                      id
                      userID
                      email
                      firstName
                      lastName
                      imageUrl
                    }
                    userID
                    programID
                    applicationStatus
                    applicationDate
                    dateAssigned
                  }
                }
                status
              }
              programID
              mentorID
              invitationStatus
              emailMessage
              dateAccepted
              createdAt
              updatedAt
            }
            nextToken
          }
          mentorReviews {
            items {
              id
              mentorID
              menteeApplicationID
              status
              feedback
              createdAt
              updatedAt
            }
            nextToken
          }
          firstName
          lastName
          imageUrl
          email
          role
          companyName
          githubHandle
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMentorQuery>response.data.getMentor;
  }
  async ListMentors(
    filter?: ModelMentorFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMentorsQuery> {
    const statement = `query ListMentors($filter: ModelMentorFilterInput, $limit: Int, $nextToken: String) {
        listMentors(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            userID
            programs {
              items {
                id
                program {
                  id
                  projectID
                  organizationID
                  departmentName
                  programName
                  description
                  websiteURL
                  programDetail {
                    programLogoURL
                    learningObjectives {
                      title
                      text
                    }
                    relevantLinks {
                      title
                      url
                    }
                    requiredSkillsAndTechnologies
                    availableMenteeSpots
                    publish {
                      publishTo
                      url
                    }
                  }
                  preRequisiteTasks {
                    prerequisiteName
                    dueDate
                    submission
                    submitFile
                    githubURL
                    description
                    custom
                  }
                  terms {
                    termName
                    termStartDate
                    termEndDate
                    applicationAcceptanceStartDate
                    applicationAcceptanceEndDate
                    tasks {
                      id
                      milestoneID
                      taskName
                      description
                      taskType
                      dueDate
                      uploadRequired
                    }
                  }
                  coAdmins {
                    firstName
                    lastName
                    imageUrl
                    email
                    role
                    invitationStatus
                    companyName
                    githubHandle
                    emailMessage
                    userID
                  }
                  mentors {
                    items {
                      id
                      mentor {
                        id
                        userID
                        email
                        firstName
                        lastName
                        imageUrl
                        role
                      }
                      invitationStatus
                      dateAccepted
                      emailMessage
                      programID
                      mentorID
                    }
                  }
                  mentees {
                    items {
                      id
                      mentee {
                        id
                        userID
                        email
                        firstName
                        lastName
                        imageUrl
                      }
                      userID
                      programID
                      applicationStatus
                      applicationDate
                      dateAssigned
                    }
                  }
                  status
                }
                programID
                mentorID
                invitationStatus
                emailMessage
                dateAccepted
              }
              nextToken
            }
            mentorReviews {
              nextToken
            }
            firstName
            lastName
            imageUrl
            email
            role
            companyName
            githubHandle
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMentorsQuery>response.data.listMentors;
  }
  async GetMentorProgram(id: string): Promise<GetMentorProgramQuery> {
    const statement = `query GetMentorProgram($id: ID!) {
        getMentorProgram(id: $id) {
          id
          programID
          mentorID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          mentor {
            id
            userID
            programs {
              nextToken
            }
            mentorReviews {
              nextToken
            }
            firstName
            lastName
            imageUrl
            email
            role
            companyName
            githubHandle
            createdAt
            updatedAt
          }
          invitationStatus
          emailMessage
          dateAccepted
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMentorProgramQuery>response.data.getMentorProgram;
  }
  async ListMentorPrograms(
    filter?: ModelMentorProgramFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMentorProgramsQuery> {
    const statement = `query ListMentorPrograms($filter: ModelMentorProgramFilterInput, $limit: Int, $nextToken: String) {
        listMentorPrograms(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            programID
            mentorID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            mentor {
              id
              userID
              firstName
              lastName
              imageUrl
              email
              role
              companyName
              githubHandle
              createdAt
              updatedAt
            }
            invitationStatus
            emailMessage
            dateAccepted
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMentorProgramsQuery>response.data.listMentorPrograms;
  }
  async GetMentee(id: string): Promise<GetMenteeQuery> {
    const statement = `query GetMentee($id: ID!) {
        getMentee(id: $id) {
          id
          menteeApplications {
            items {
              id
              userID
              programID
              program {
                id
                programName
                organizationID
                projectID
                status
              }
              applicationStatus
              applicationDate
              dateAssigned
              decisionReason
              certificate
              overallFeedback
              createdAt
              updatedAt
            }
            nextToken
          }
          imageUrl
          dateAccepted
          userID
          firstName
          lastName
          email
          tasksAssigned {
            items {
              id
              menteeID
              taskID
              comments {
                commentBy
                commentText
                createdAt
              }
              task {
                id
                milestoneID
                taskName
                description
                dueDate
                uploadRequired
                attachments {
                  attachmentURL
                  attachmentText
                }
              }
              status
              progress
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMenteeQuery>response.data.getMentee;
  }
  async ListMentees(
    filter?: ModelMenteeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMenteesQuery> {
    const statement = `query ListMentees($filter: ModelMenteeFilterInput, $limit: Int, $nextToken: String) {
        listMentees(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            menteeApplications {
              items {
                id
                userID
                programID
                program {
                  id
                  programName
                  mentors {
                    items {
                      id
                      mentor {
                        id
                        userID
                        email
                        firstName
                        lastName
                        imageUrl
                        role
                      }
                      invitationStatus
                      dateAccepted
                      emailMessage
                      programID
                      mentorID
                    }
                  }
                }
                applicationStatus
                applicationDate
                dateAssigned
              }
              nextToken
            }
            imageUrl
            dateAccepted
            userID
            firstName
            lastName
            email
            tasksAssigned {
              items {
                id
                menteeID
                taskID
                comments {
                  commentBy
                  commentText
                }
                task {
                  id
                  milestoneID
                  taskName
                  description
                  dueDate
                  uploadRequired
                  attachments {
                    attachmentURL
                    attachmentText
                  }
                }
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMenteesQuery>response.data.listMentees;
  }
  async GetMenteeApplication(id: string): Promise<GetMenteeApplicationQuery> {
    const statement = `query GetMenteeApplication($id: ID!) {
        getMenteeApplication(id: $id) {
          id
          userID
          programID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          mentee {
            id
            menteeApplications {
              nextToken
            }
            imageUrl
            dateAccepted
            userID
            firstName
            lastName
            email
            tasksAssigned {
              nextToken
            }
            createdAt
            updatedAt
          }
          applicationStatus
          applicationDate
          preRequisites {
            items {
              id
              programID
              applicationID
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              category
              custom
              status
              mentorFeedBack
              userID
            }
            nextToken
          }
          dateAssigned
          mentorReviews {
            items {
              id
              mentorID
              mentor {
                id
                firstName
                lastName
                imageUrl
                email
              }
              menteeApplicationID
              status
              feedback
            }
            nextToken
          }
          evaluations {
            evaluationName
            dueDate
            status
          }
          feedBacks {
            feedBackName
            date
            status
          }
          decisionReason
          certificate
          overallFeedback
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMenteeApplicationQuery>response.data.getMenteeApplication;
  }
  async ListMenteeApplications(
    filter?: ModelMenteeApplicationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMenteeApplicationsQuery> {
    const statement = `query ListMenteeApplications($filter: ModelMenteeApplicationFilterInput, $limit: Int, $nextToken: String) {
        listMenteeApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            userID
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              programDetail {
                programLogoURL
                requiredSkillsAndTechnologies
                availableMenteeSpots
              }
              preRequisiteTasks {
                prerequisiteName
                dueDate
                submission
                submitFile
                githubURL
                description
                custom
              }
              terms {
                termName
                termStartDate
                termEndDate
                applicationAcceptanceStartDate
                applicationAcceptanceEndDate
              }
              coAdmins {
                firstName
                lastName
                imageUrl
                email
                role
                invitationStatus
                companyName
                githubHandle
                emailMessage
                userID
              }
              mentors {
                items {
                  id
                  mentor {
                    id
                    userID
                    email
                    firstName
                    lastName
                    imageUrl
                    role
                  }
                  invitationStatus
                  dateAccepted
                  emailMessage
                  programID
                  mentorID
                }
              }
              mentees {
                items {
                  id
                  mentee {
                    id
                    userID
                    email
                    firstName
                    lastName
                    imageUrl
                  }
                  userID
                  programID
                  applicationStatus
                  applicationDate
                  dateAssigned
                  decisionReason
                  certificate
                  overallFeedback
                }
                nextToken
              }
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            mentee {
              id
              imageUrl
              dateAccepted
              userID
              firstName
              lastName
              email
              menteeApplications {
                items {
                  id
                  userID
                  programID
                  program {
                    id
                    programName
                  }
                  applicationStatus
                  applicationDate
                  dateAssigned
                }
              }
              createdAt
              updatedAt
            }
            applicationStatus
            applicationDate
            preRequisites {
              nextToken
            }
            dateAssigned
            mentorReviews {
              items {
                id
                mentorID
                mentor {
                  id
                  userID
                  email
                  firstName
                  lastName
                  imageUrl
                  role
                }
                menteeApplicationID
                status
                feedback
              }
              nextToken
            }
            evaluations {
              evaluationName
              dueDate
              status
            }
            feedBacks {
              feedBackName
              date
              status
            }
            decisionReason
            certificate
            overallFeedback
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMenteeApplicationsQuery>response.data.listMenteeApplications;
  }
  async GetMilestone(id: string): Promise<GetMilestoneQuery> {
    const statement = `query GetMilestone($id: ID!) {
        getMilestone(id: $id) {
          id
          programID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              items {
                mentee {
                  id
                  firstName
                  lastName
                  imageUrl
                  userID
                }
              }
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          milestoneName
          dueDate
          tasks {
            items {
              id
              milestoneID
              taskName
              description
              taskType
              assignedToMentees {
                items {
                  id
                  menteeID
                  taskID
                  mentee {
                    id
                    dateAccepted
                    userID
                    firstName
                    lastName
                    email
                  }
                }
              }
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          createdBy
          description
          termName
          progress
          evaluation {
            status
            feedBackToProgramAdmin
            menteeID
            createdAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMilestoneQuery>response.data.getMilestone;
  }
  async ListMilestones(
    filter?: ModelMilestoneFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMilestonesQuery> {
    const statement = `query ListMilestones($filter: ModelMilestoneFilterInput, $limit: Int, $nextToken: String) {
        listMilestones(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              programDetail {
                programLogoURL
                requiredSkillsAndTechnologies
                availableMenteeSpots
              }
              preRequisiteTasks {
                prerequisiteName
                dueDate
                submission
                submitFile
                githubURL
                description
                custom
              }
              terms {
                termName
                termStartDate
                termEndDate
                applicationAcceptanceStartDate
                applicationAcceptanceEndDate
              }
              coAdmins {
                firstName
                lastName
                imageUrl
                email
                role
                invitationStatus
                companyName
                githubHandle
                emailMessage
                userID
              }
              mentors {
                nextToken
              }
              mentees {
                items {
                  mentee {
                    id
                    userID
                    email
                    firstName
                    lastName
                    imageUrl
                  }
                  id
                  userID
                  programID
                  applicationStatus
                  applicationDate
                  dateAssigned
                  decisionReason
                  certificate
                  overallFeedback
                }
                nextToken
              }
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            milestoneName
            dueDate
            tasks {
              items {
                id
                milestoneID
                taskName
                description
                taskType
                assignedToMentees {
                  items {
                    id
                    menteeID
                    taskID
                    status
                    comments {
                      commentBy
                      commentText
                      createdAt
                    }
                    mentee {
                      id
                      dateAccepted
                      userID
                      firstName
                      lastName
                      email
                    }
                  }
                }
                dueDate
                uploadRequired
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            createdAt
            updatedAt
            createdBy
            description
            termName
            progress
            evaluation {
              status
              feedBackToProgramAdmin
              menteeID
              createdAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMilestonesQuery>response.data.listMilestones;
  }
  async GetMilestoneActionLog(id: string): Promise<GetMilestoneActionLogQuery> {
    const statement = `query GetMilestoneActionLog($id: ID!) {
        getMilestoneActionLog(id: $id) {
          id
          milestoneID
          milestone {
            id
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            milestoneName
            dueDate
            tasks {
              nextToken
            }
            status
            createdBy
            description
            termName
            progress
            evaluation {
              status
              feedBackToProgramAdmin
              menteeID
              createdAt
            }
            createdAt
            updatedAt
          }
          menteeTaskID
          menteeTask {
            id
            menteeID
            taskID
            mentee {
              id
              imageUrl
              dateAccepted
              userID
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
            task {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
            status
            progress
            comments {
              commentBy
              commentText
              createdAt
            }
            createdAt
            updatedAt
          }
          createdAt
          actor
          actorID
          action
          actionType
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMilestoneActionLogQuery>response.data.getMilestoneActionLog;
  }
  async ListMilestoneActionLogs(
    filter?: ModelMilestoneActionLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMilestoneActionLogsQuery> {
    const statement = `query ListMilestoneActionLogs($filter: ModelMilestoneActionLogFilterInput, $limit: Int, $nextToken: String) {
        listMilestoneActionLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            milestoneID
            milestone {
              id
              programID
              milestoneName
              dueDate
              status
              createdBy
              description
              termName
              progress
              createdAt
              updatedAt
            }
            menteeTaskID
            menteeTask {
              id
              menteeID
              taskID
              task {
                id
                taskName
              }
              status
              progress
              updatedAt
            }
            createdAt
            actor
            actorID
            action
            actionType
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMilestoneActionLogsQuery>response.data.listMilestoneActionLogs;
  }
  async GetTask(id: string): Promise<GetTaskQuery> {
    const statement = `query GetTask($id: ID!) {
        getTask(id: $id) {
          id
          milestoneID
          milestone {
            id
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            milestoneName
            dueDate
            tasks {
              nextToken
            }
            status
            createdBy
            description
            termName
            progress
            evaluation {
              status
              feedBackToProgramAdmin
              menteeID
              createdAt
            }
            createdAt
            updatedAt
          }
          assignedToMentees {
            items {
              id
              menteeID
              taskID
              status
              progress
              createdAt
              updatedAt
            }
            nextToken
          }
          taskName
          description
          taskType
          dueDate
          uploadRequired
          attachments {
            attachmentURL
            attachmentText
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTaskQuery>response.data.getTask;
  }
  async ListTasks(
    filter?: ModelTaskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTasksQuery> {
    const statement = `query ListTasks($filter: ModelTaskFilterInput, $limit: Int, $nextToken: String) {
        listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            milestoneID
            milestone {
              id
              programID
              milestoneName
              dueDate
              status
              createdBy
              description
              termName
              progress
              createdAt
              updatedAt
            }
            assignedToMentees {
              items {
                id
                menteeID
                taskID
                mentee {
                  id
                  dateAccepted
                  userID
                  firstName
                  lastName
                  email
                }
              }
              nextToken
            }
            taskName
            description
            taskType
            dueDate
            uploadRequired
            attachments {
              attachmentURL
              attachmentText
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTasksQuery>response.data.listTasks;
  }
  async GetMenteeTask(id: string): Promise<GetMenteeTaskQuery> {
    const statement = `query GetMenteeTask($id: ID!) {
        getMenteeTask(id: $id) {
          id
          menteeID
          taskID
          mentee {
            id
            menteeApplications {
              nextToken
            }
            imageUrl
            dateAccepted
            userID
            firstName
            lastName
            email
            tasksAssigned {
              nextToken
            }
            createdAt
            updatedAt
          }
          task {
            id
            milestoneID
            milestone {
              id
              programID
              milestoneName
              dueDate
              status
              createdBy
              description
              termName
              progress
              createdAt
              updatedAt
            }
            assignedToMentees {
              nextToken
            }
            taskName
            description
            taskType
            dueDate
            uploadRequired
            attachments {
              attachmentURL
              attachmentText
            }
            createdAt
            updatedAt
          }
          status
          progress
          comments {
            commentBy
            commentText
            createdAt
          }
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMenteeTaskQuery>response.data.getMenteeTask;
  }
  async ListMenteeTasks(
    filter?: ModelMenteeTaskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMenteeTasksQuery> {
    const statement = `query ListMenteeTasks($filter: ModelMenteeTaskFilterInput, $limit: Int, $nextToken: String) {
        listMenteeTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            menteeID
            taskID
            mentee {
              id
              imageUrl
              dateAccepted
              userID
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
            task {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
            status
            progress
            comments {
              commentBy
              commentText
              createdAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMenteeTasksQuery>response.data.listMenteeTasks;
  }
  async GetPreRequisiteTask(id: string): Promise<GetPreRequisiteTaskQuery> {
    const statement = `query GetPreRequisiteTask($id: ID!) {
        getPreRequisiteTask(id: $id) {
          id
          programID
          applicationID
          prerequisiteName
          dueDate
          submission
          submitFile
          githubURL
          description
          category
          custom
          status
          comments {
            commentBy
            commentText
            createdAt
          }
          mentorFeedBack
          userID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPreRequisiteTaskQuery>response.data.getPreRequisiteTask;
  }
  async ListPreRequisiteTasks(
    filter?: ModelPreRequisiteTaskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPreRequisiteTasksQuery> {
    const statement = `query ListPreRequisiteTasks($filter: ModelPreRequisiteTaskFilterInput, $limit: Int, $nextToken: String) {
        listPreRequisiteTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            programID
            applicationID
            prerequisiteName
            dueDate
            submission
            submitFile
            githubURL
            description
            category
            custom
            status
            comments {
              commentBy
              commentText
              createdAt
            }
            mentorFeedBack
            userID
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPreRequisiteTasksQuery>response.data.listPreRequisiteTasks;
  }
  async GetMentorReview(id: string): Promise<GetMentorReviewQuery> {
    const statement = `query GetMentorReview($id: ID!) {
        getMentorReview(id: $id) {
          id
          mentorID
          menteeApplicationID
          menteeApplication {
            id
            userID
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            mentee {
              id
              imageUrl
              dateAccepted
              userID
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
            applicationStatus
            applicationDate
            preRequisites {
              items {
                id
                mentorFeedBack
                prerequisiteName
                programID
                applicationID
                category
                comments {
                  commentBy
                  commentText
                  createdAt
                }
                description
                githubURL
                submission
                submitFile
                dueDate
                custom
              }
              nextToken
            }
            dateAssigned
            mentorReviews {
              nextToken
            }
            evaluations {
              evaluationName
              dueDate
              status
            }
            feedBacks {
              feedBackName
              date
              status
            }
            decisionReason
            certificate
            overallFeedback
            createdAt
            updatedAt
          }
          mentor {
            id
            userID
            programs {
              nextToken
            }
            mentorReviews {
              nextToken
            }
            firstName
            lastName
            imageUrl
            email
            role
            companyName
            githubHandle
          }
          status
          feedback
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMentorReviewQuery>response.data.getMentorReview;
  }
  async ListMentorReviews(
    filter?: ModelMentorReviewFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMentorReviewsQuery> {
    const statement = `query ListMentorReviews($filter: ModelMentorReviewFilterInput, $limit: Int, $nextToken: String) {
        listMentorReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            mentorID
            menteeApplicationID
            menteeApplication {
              id
              userID
              programID
              program {
                id
                projectID
                organizationID
                departmentName
                programName
                description
                websiteURL
                status
              }
              mentee {
                id
                imageUrl
                dateAccepted
                userID
                firstName
                lastName
                email
              }
              preRequisites {
                items {
                  description
                  prerequisiteName
                  mentorFeedBack
                  comments {
                    commentText
                    commentBy
                  }
                }
              }
              applicationStatus
              applicationDate
              dateAssigned
              decisionReason
              certificate
              overallFeedback
              createdAt
              updatedAt
            }
            mentor {
              id
              userID
              firstName
              lastName
              imageUrl
              email
              role
              companyName
              githubHandle
            }
            status
            feedback
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMentorReviewsQuery>response.data.listMentorReviews;
  }
  OnCreateProgramListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProgram">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateProgram {
        onCreateProgram {
          id
          projectID
          organizationID
          departmentName
          programName
          description
          websiteURL
          programDetail {
            programLogoURL
            learningObjectives {
              title
              text
            }
            relevantLinks {
              title
              url
            }
            requiredSkillsAndTechnologies
            availableMenteeSpots
            publish {
              publishTo
              url
            }
          }
          preRequisiteTasks {
            prerequisiteName
            dueDate
            submission
            submitFile
            githubURL
            description
            custom
          }
          terms {
            termName
            termStartDate
            termEndDate
            applicationAcceptanceStartDate
            applicationAcceptanceEndDate
            tasks {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
          }
          coAdmins {
            firstName
            lastName
            imageUrl
            email
            role
            invitationStatus
            companyName
            githubHandle
            emailMessage
            userID
          }
          mentors {
            items {
              id
              programID
              mentorID
              invitationStatus
              emailMessage
              dateAccepted
              createdAt
              updatedAt
            }
            nextToken
          }
          mentees {
            items {
              id
              userID
              programID
              applicationStatus
              applicationDate
              dateAssigned
              decisionReason
              certificate
              overallFeedback
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProgram">>
  >;

  OnUpdateProgramListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProgram">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateProgram {
        onUpdateProgram {
          id
          projectID
          organizationID
          departmentName
          programName
          description
          websiteURL
          programDetail {
            programLogoURL
            learningObjectives {
              title
              text
            }
            relevantLinks {
              title
              url
            }
            requiredSkillsAndTechnologies
            availableMenteeSpots
            publish {
              publishTo
              url
            }
          }
          preRequisiteTasks {
            prerequisiteName
            dueDate
            submission
            submitFile
            githubURL
            description
            custom
          }
          terms {
            termName
            termStartDate
            termEndDate
            applicationAcceptanceStartDate
            applicationAcceptanceEndDate
            tasks {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
          }
          coAdmins {
            firstName
            lastName
            imageUrl
            email
            role
            invitationStatus
            companyName
            githubHandle
            emailMessage
            userID
          }
          mentors {
            items {
              id
              programID
              mentorID
              invitationStatus
              emailMessage
              dateAccepted
              createdAt
              updatedAt
            }
            nextToken
          }
          mentees {
            items {
              id
              userID
              programID
              applicationStatus
              applicationDate
              dateAssigned
              decisionReason
              certificate
              overallFeedback
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProgram">>
  >;

  OnDeleteProgramListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProgram">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteProgram {
        onDeleteProgram {
          id
          projectID
          organizationID
          departmentName
          programName
          description
          websiteURL
          programDetail {
            programLogoURL
            learningObjectives {
              title
              text
            }
            relevantLinks {
              title
              url
            }
            requiredSkillsAndTechnologies
            availableMenteeSpots
            publish {
              publishTo
              url
            }
          }
          preRequisiteTasks {
            prerequisiteName
            dueDate
            submission
            submitFile
            githubURL
            description
            custom
          }
          terms {
            termName
            termStartDate
            termEndDate
            applicationAcceptanceStartDate
            applicationAcceptanceEndDate
            tasks {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
          }
          coAdmins {
            firstName
            lastName
            imageUrl
            email
            role
            invitationStatus
            companyName
            githubHandle
            emailMessage
            userID
          }
          mentors {
            items {
              id
              programID
              mentorID
              invitationStatus
              emailMessage
              dateAccepted
              createdAt
              updatedAt
            }
            nextToken
          }
          mentees {
            items {
              id
              userID
              programID
              applicationStatus
              applicationDate
              dateAssigned
              decisionReason
              certificate
              overallFeedback
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProgram">>
  >;

  OnCreateMentorListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateMentor">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMentor {
        onCreateMentor {
          id
          userID
          programs {
            items {
              id
              programID
              mentorID
              invitationStatus
              emailMessage
              dateAccepted
              createdAt
              updatedAt
            }
            nextToken
          }
          mentorReviews {
            items {
              id
              mentorID
              menteeApplicationID
              status
              feedback
              createdAt
              updatedAt
            }
            nextToken
          }
          firstName
          lastName
          imageUrl
          email
          role
          companyName
          githubHandle
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateMentor">>
  >;

  OnUpdateMentorListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateMentor">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMentor {
        onUpdateMentor {
          id
          userID
          programs {
            items {
              id
              programID
              mentorID
              invitationStatus
              emailMessage
              dateAccepted
              createdAt
              updatedAt
            }
            nextToken
          }
          mentorReviews {
            items {
              id
              mentorID
              menteeApplicationID
              status
              feedback
              createdAt
              updatedAt
            }
            nextToken
          }
          firstName
          lastName
          imageUrl
          email
          role
          companyName
          githubHandle
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateMentor">>
  >;

  OnDeleteMentorListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteMentor">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMentor {
        onDeleteMentor {
          id
          userID
          programs {
            items {
              id
              programID
              mentorID
              invitationStatus
              emailMessage
              dateAccepted
              createdAt
              updatedAt
            }
            nextToken
          }
          mentorReviews {
            items {
              id
              mentorID
              menteeApplicationID
              status
              feedback
              createdAt
              updatedAt
            }
            nextToken
          }
          firstName
          lastName
          imageUrl
          email
          role
          companyName
          githubHandle
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteMentor">>
  >;

  OnCreateMentorProgramListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateMentorProgram">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMentorProgram {
        onCreateMentorProgram {
          id
          programID
          mentorID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          mentor {
            id
            userID
            programs {
              nextToken
            }
            mentorReviews {
              nextToken
            }
            firstName
            lastName
            imageUrl
            email
            role
            companyName
            githubHandle
            createdAt
            updatedAt
          }
          invitationStatus
          emailMessage
          dateAccepted
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateMentorProgram">>
  >;

  OnUpdateMentorProgramListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateMentorProgram">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMentorProgram {
        onUpdateMentorProgram {
          id
          programID
          mentorID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          mentor {
            id
            userID
            programs {
              nextToken
            }
            mentorReviews {
              nextToken
            }
            firstName
            lastName
            imageUrl
            email
            role
            companyName
            githubHandle
            createdAt
            updatedAt
          }
          invitationStatus
          emailMessage
          dateAccepted
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateMentorProgram">>
  >;

  OnDeleteMentorProgramListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteMentorProgram">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMentorProgram {
        onDeleteMentorProgram {
          id
          programID
          mentorID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          mentor {
            id
            userID
            programs {
              nextToken
            }
            mentorReviews {
              nextToken
            }
            firstName
            lastName
            imageUrl
            email
            role
            companyName
            githubHandle
            createdAt
            updatedAt
          }
          invitationStatus
          emailMessage
          dateAccepted
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteMentorProgram">>
  >;

  OnCreateMenteeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateMentee">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMentee {
        onCreateMentee {
          id
          menteeApplications {
            items {
              id
              userID
              programID
              applicationStatus
              applicationDate
              dateAssigned
              decisionReason
              certificate
              overallFeedback
              createdAt
              updatedAt
            }
            nextToken
          }
          imageUrl
          dateAccepted
          userID
          firstName
          lastName
          email
          tasksAssigned {
            items {
              id
              menteeID
              taskID
              status
              progress
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateMentee">>
  >;

  OnUpdateMenteeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateMentee">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMentee {
        onUpdateMentee {
          id
          menteeApplications {
            items {
              id
              userID
              programID
              applicationStatus
              applicationDate
              dateAssigned
              decisionReason
              certificate
              overallFeedback
              createdAt
              updatedAt
            }
            nextToken
          }
          imageUrl
          dateAccepted
          userID
          firstName
          lastName
          email
          tasksAssigned {
            items {
              id
              menteeID
              taskID
              status
              progress
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateMentee">>
  >;

  OnDeleteMenteeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteMentee">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMentee {
        onDeleteMentee {
          id
          menteeApplications {
            items {
              id
              userID
              programID
              applicationStatus
              applicationDate
              dateAssigned
              decisionReason
              certificate
              overallFeedback
              createdAt
              updatedAt
            }
            nextToken
          }
          imageUrl
          dateAccepted
          userID
          firstName
          lastName
          email
          tasksAssigned {
            items {
              id
              menteeID
              taskID
              status
              progress
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteMentee">>
  >;

  OnCreateMenteeApplicationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateMenteeApplication">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMenteeApplication {
        onCreateMenteeApplication {
          id
          userID
          programID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          mentee {
            id
            menteeApplications {
              nextToken
            }
            imageUrl
            dateAccepted
            userID
            firstName
            lastName
            email
            tasksAssigned {
              nextToken
            }
            createdAt
            updatedAt
          }
          applicationStatus
          applicationDate
          preRequisites {
            items {
              id
              programID
              applicationID
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              category
              custom
              status
              mentorFeedBack
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          dateAssigned
          mentorReviews {
            items {
              id
              mentorID
              menteeApplicationID
              status
              feedback
              createdAt
              updatedAt
            }
            nextToken
          }
          evaluations {
            evaluationName
            dueDate
            status
          }
          feedBacks {
            feedBackName
            date
            status
          }
          decisionReason
          certificate
          overallFeedback
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateMenteeApplication">
    >
  >;

  OnUpdateMenteeApplicationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateMenteeApplication">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMenteeApplication {
        onUpdateMenteeApplication {
          id
          userID
          programID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          mentee {
            id
            menteeApplications {
              nextToken
            }
            imageUrl
            dateAccepted
            userID
            firstName
            lastName
            email
            tasksAssigned {
              nextToken
            }
            createdAt
            updatedAt
          }
          applicationStatus
          applicationDate
          preRequisites {
            items {
              id
              programID
              applicationID
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              category
              custom
              status
              mentorFeedBack
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          dateAssigned
          mentorReviews {
            items {
              id
              mentorID
              menteeApplicationID
              status
              feedback
              createdAt
              updatedAt
            }
            nextToken
          }
          evaluations {
            evaluationName
            dueDate
            status
          }
          feedBacks {
            feedBackName
            date
            status
          }
          decisionReason
          certificate
          overallFeedback
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateMenteeApplication">
    >
  >;

  OnDeleteMenteeApplicationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteMenteeApplication">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMenteeApplication {
        onDeleteMenteeApplication {
          id
          userID
          programID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          mentee {
            id
            menteeApplications {
              nextToken
            }
            imageUrl
            dateAccepted
            userID
            firstName
            lastName
            email
            tasksAssigned {
              nextToken
            }
            createdAt
            updatedAt
          }
          applicationStatus
          applicationDate
          preRequisites {
            items {
              id
              programID
              applicationID
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              category
              custom
              status
              mentorFeedBack
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          dateAssigned
          mentorReviews {
            items {
              id
              mentorID
              menteeApplicationID
              status
              feedback
              createdAt
              updatedAt
            }
            nextToken
          }
          evaluations {
            evaluationName
            dueDate
            status
          }
          feedBacks {
            feedBackName
            date
            status
          }
          decisionReason
          certificate
          overallFeedback
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteMenteeApplication">
    >
  >;

  OnCreateMilestoneListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateMilestone">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMilestone {
        onCreateMilestone {
          id
          programID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          milestoneName
          dueDate
          tasks {
            items {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          createdBy
          description
          termName
          progress
          evaluation {
            status
            feedBackToProgramAdmin
            menteeID
            createdAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateMilestone">>
  >;

  OnUpdateMilestoneListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateMilestone">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMilestone {
        onUpdateMilestone {
          id
          programID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          milestoneName
          dueDate
          tasks {
            items {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          createdBy
          description
          termName
          progress
          evaluation {
            status
            feedBackToProgramAdmin
            menteeID
            createdAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateMilestone">>
  >;

  OnDeleteMilestoneListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteMilestone">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMilestone {
        onDeleteMilestone {
          id
          programID
          program {
            id
            projectID
            organizationID
            departmentName
            programName
            description
            websiteURL
            programDetail {
              programLogoURL
              requiredSkillsAndTechnologies
              availableMenteeSpots
            }
            preRequisiteTasks {
              prerequisiteName
              dueDate
              submission
              submitFile
              githubURL
              description
              custom
            }
            terms {
              termName
              termStartDate
              termEndDate
              applicationAcceptanceStartDate
              applicationAcceptanceEndDate
            }
            coAdmins {
              firstName
              lastName
              imageUrl
              email
              role
              invitationStatus
              companyName
              githubHandle
              emailMessage
              userID
            }
            mentors {
              nextToken
            }
            mentees {
              nextToken
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          milestoneName
          dueDate
          tasks {
            items {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          createdBy
          description
          termName
          progress
          evaluation {
            status
            feedBackToProgramAdmin
            menteeID
            createdAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteMilestone">>
  >;

  OnCreateMilestoneActionLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateMilestoneActionLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMilestoneActionLog {
        onCreateMilestoneActionLog {
          id
          milestoneID
          milestone {
            id
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            milestoneName
            dueDate
            tasks {
              nextToken
            }
            status
            createdBy
            description
            termName
            progress
            evaluation {
              status
              feedBackToProgramAdmin
              menteeID
              createdAt
            }
            createdAt
            updatedAt
          }
          menteeTaskID
          menteeTask {
            id
            menteeID
            taskID
            mentee {
              id
              imageUrl
              dateAccepted
              userID
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
            task {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
            status
            progress
            comments {
              commentBy
              commentText
              createdAt
            }
            createdAt
            updatedAt
          }
          createdAt
          actor
          actorID
          action
          actionType
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateMilestoneActionLog">
    >
  >;

  OnUpdateMilestoneActionLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateMilestoneActionLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMilestoneActionLog {
        onUpdateMilestoneActionLog {
          id
          milestoneID
          milestone {
            id
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            milestoneName
            dueDate
            tasks {
              nextToken
            }
            status
            createdBy
            description
            termName
            progress
            evaluation {
              status
              feedBackToProgramAdmin
              menteeID
              createdAt
            }
            createdAt
            updatedAt
          }
          menteeTaskID
          menteeTask {
            id
            menteeID
            taskID
            mentee {
              id
              imageUrl
              dateAccepted
              userID
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
            task {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
            status
            progress
            comments {
              commentBy
              commentText
              createdAt
            }
            createdAt
            updatedAt
          }
          createdAt
          actor
          actorID
          action
          actionType
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateMilestoneActionLog">
    >
  >;

  OnDeleteMilestoneActionLogListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteMilestoneActionLog">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMilestoneActionLog {
        onDeleteMilestoneActionLog {
          id
          milestoneID
          milestone {
            id
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            milestoneName
            dueDate
            tasks {
              nextToken
            }
            status
            createdBy
            description
            termName
            progress
            evaluation {
              status
              feedBackToProgramAdmin
              menteeID
              createdAt
            }
            createdAt
            updatedAt
          }
          menteeTaskID
          menteeTask {
            id
            menteeID
            taskID
            mentee {
              id
              imageUrl
              dateAccepted
              userID
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
            task {
              id
              milestoneID
              taskName
              description
              taskType
              dueDate
              uploadRequired
              createdAt
              updatedAt
            }
            status
            progress
            comments {
              commentBy
              commentText
              createdAt
            }
            createdAt
            updatedAt
          }
          createdAt
          actor
          actorID
          action
          actionType
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteMilestoneActionLog">
    >
  >;

  OnCreateTaskListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTask">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTask {
        onCreateTask {
          id
          milestoneID
          milestone {
            id
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            milestoneName
            dueDate
            tasks {
              nextToken
            }
            status
            createdBy
            description
            termName
            progress
            evaluation {
              status
              feedBackToProgramAdmin
              menteeID
              createdAt
            }
            createdAt
            updatedAt
          }
          assignedToMentees {
            items {
              id
              menteeID
              taskID
              status
              progress
              createdAt
              updatedAt
            }
            nextToken
          }
          taskName
          description
          taskType
          dueDate
          uploadRequired
          attachments {
            attachmentURL
            attachmentText
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTask">>
  >;

  OnUpdateTaskListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTask">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTask {
        onUpdateTask {
          id
          milestoneID
          milestone {
            id
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            milestoneName
            dueDate
            tasks {
              nextToken
            }
            status
            createdBy
            description
            termName
            progress
            evaluation {
              status
              feedBackToProgramAdmin
              menteeID
              createdAt
            }
            createdAt
            updatedAt
          }
          assignedToMentees {
            items {
              id
              menteeID
              taskID
              status
              progress
              createdAt
              updatedAt
            }
            nextToken
          }
          taskName
          description
          taskType
          dueDate
          uploadRequired
          attachments {
            attachmentURL
            attachmentText
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTask">>
  >;

  OnDeleteTaskListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTask">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTask {
        onDeleteTask {
          id
          milestoneID
          milestone {
            id
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            milestoneName
            dueDate
            tasks {
              nextToken
            }
            status
            createdBy
            description
            termName
            progress
            evaluation {
              status
              feedBackToProgramAdmin
              menteeID
              createdAt
            }
            createdAt
            updatedAt
          }
          assignedToMentees {
            items {
              id
              menteeID
              taskID
              status
              progress
              createdAt
              updatedAt
            }
            nextToken
          }
          taskName
          description
          taskType
          dueDate
          uploadRequired
          attachments {
            attachmentURL
            attachmentText
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTask">>
  >;

  OnCreateMenteeTaskListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateMenteeTask">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMenteeTask {
        onCreateMenteeTask {
          id
          menteeID
          taskID
          mentee {
            id
            menteeApplications {
              nextToken
            }
            imageUrl
            dateAccepted
            userID
            firstName
            lastName
            email
            tasksAssigned {
              nextToken
            }
            createdAt
            updatedAt
          }
          task {
            id
            milestoneID
            milestone {
              id
              programID
              milestoneName
              dueDate
              status
              createdBy
              description
              termName
              progress
              createdAt
              updatedAt
            }
            assignedToMentees {
              nextToken
            }
            taskName
            description
            taskType
            dueDate
            uploadRequired
            attachments {
              attachmentURL
              attachmentText
            }
            createdAt
            updatedAt
          }
          status
          progress
          comments {
            commentBy
            commentText
            createdAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateMenteeTask">>
  >;

  OnUpdateMenteeTaskListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateMenteeTask">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMenteeTask {
        onUpdateMenteeTask {
          id
          menteeID
          taskID
          mentee {
            id
            menteeApplications {
              nextToken
            }
            imageUrl
            dateAccepted
            userID
            firstName
            lastName
            email
            tasksAssigned {
              nextToken
            }
            createdAt
            updatedAt
          }
          task {
            id
            milestoneID
            milestone {
              id
              programID
              milestoneName
              dueDate
              status
              createdBy
              description
              termName
              progress
              createdAt
              updatedAt
            }
            assignedToMentees {
              nextToken
            }
            taskName
            description
            taskType
            dueDate
            uploadRequired
            attachments {
              attachmentURL
              attachmentText
            }
            createdAt
            updatedAt
          }
          status
          progress
          comments {
            commentBy
            commentText
            createdAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateMenteeTask">>
  >;

  OnDeleteMenteeTaskListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteMenteeTask">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMenteeTask {
        onDeleteMenteeTask {
          id
          menteeID
          taskID
          mentee {
            id
            menteeApplications {
              nextToken
            }
            imageUrl
            dateAccepted
            userID
            firstName
            lastName
            email
            tasksAssigned {
              nextToken
            }
            createdAt
            updatedAt
          }
          task {
            id
            milestoneID
            milestone {
              id
              programID
              milestoneName
              dueDate
              status
              createdBy
              description
              termName
              progress
              createdAt
              updatedAt
            }
            assignedToMentees {
              nextToken
            }
            taskName
            description
            taskType
            dueDate
            uploadRequired
            attachments {
              attachmentURL
              attachmentText
            }
            createdAt
            updatedAt
          }
          status
          progress
          comments {
            commentBy
            commentText
            createdAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteMenteeTask">>
  >;

  OnCreatePreRequisiteTaskListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreatePreRequisiteTask">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreatePreRequisiteTask {
        onCreatePreRequisiteTask {
          id
          programID
          applicationID
          prerequisiteName
          dueDate
          submission
          submitFile
          githubURL
          description
          category
          custom
          status
          comments {
            commentBy
            commentText
            createdAt
          }
          mentorFeedBack
          userID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreatePreRequisiteTask">
    >
  >;

  OnUpdatePreRequisiteTaskListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdatePreRequisiteTask">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdatePreRequisiteTask {
        onUpdatePreRequisiteTask {
          id
          programID
          applicationID
          prerequisiteName
          dueDate
          submission
          submitFile
          githubURL
          description
          category
          custom
          status
          comments {
            commentBy
            commentText
            createdAt
          }
          mentorFeedBack
          userID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdatePreRequisiteTask">
    >
  >;

  OnDeletePreRequisiteTaskListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeletePreRequisiteTask">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeletePreRequisiteTask {
        onDeletePreRequisiteTask {
          id
          programID
          applicationID
          prerequisiteName
          dueDate
          submission
          submitFile
          githubURL
          description
          category
          custom
          status
          comments {
            commentBy
            commentText
            createdAt
          }
          mentorFeedBack
          userID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeletePreRequisiteTask">
    >
  >;

  OnCreateMentorReviewListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateMentorReview">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMentorReview {
        onCreateMentorReview {
          id
          mentorID
          menteeApplicationID
          menteeApplication {
            id
            userID
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            mentee {
              id
              imageUrl
              dateAccepted
              userID
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
            applicationStatus
            applicationDate
            preRequisites {
              nextToken
            }
            dateAssigned
            mentorReviews {
              nextToken
            }
            evaluations {
              evaluationName
              dueDate
              status
            }
            feedBacks {
              feedBackName
              date
              status
            }
            decisionReason
            certificate
            overallFeedback
            createdAt
            updatedAt
          }
          mentor {
            id
            userID
            programs {
              nextToken
            }
            mentorReviews {
              nextToken
            }
            firstName
            lastName
            imageUrl
            email
            role
            companyName
            githubHandle
            createdAt
            updatedAt
          }
          status
          feedback
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateMentorReview">>
  >;

  OnUpdateMentorReviewListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateMentorReview">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMentorReview {
        onUpdateMentorReview {
          id
          mentorID
          menteeApplicationID
          menteeApplication {
            id
            userID
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            mentee {
              id
              imageUrl
              dateAccepted
              userID
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
            applicationStatus
            applicationDate
            preRequisites {
              nextToken
            }
            dateAssigned
            mentorReviews {
              nextToken
            }
            evaluations {
              evaluationName
              dueDate
              status
            }
            feedBacks {
              feedBackName
              date
              status
            }
            decisionReason
            certificate
            overallFeedback
            createdAt
            updatedAt
          }
          mentor {
            id
            userID
            programs {
              nextToken
            }
            mentorReviews {
              nextToken
            }
            firstName
            lastName
            imageUrl
            email
            role
            companyName
            githubHandle
            createdAt
            updatedAt
          }
          status
          feedback
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateMentorReview">>
  >;

  OnDeleteMentorReviewListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteMentorReview">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMentorReview {
        onDeleteMentorReview {
          id
          mentorID
          menteeApplicationID
          menteeApplication {
            id
            userID
            programID
            program {
              id
              projectID
              organizationID
              departmentName
              programName
              description
              websiteURL
              status
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            mentee {
              id
              imageUrl
              dateAccepted
              userID
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
            applicationStatus
            applicationDate
            preRequisites {
              nextToken
            }
            dateAssigned
            mentorReviews {
              nextToken
            }
            evaluations {
              evaluationName
              dueDate
              status
            }
            feedBacks {
              feedBackName
              date
              status
            }
            decisionReason
            certificate
            overallFeedback
            createdAt
            updatedAt
          }
          mentor {
            id
            userID
            programs {
              nextToken
            }
            mentorReviews {
              nextToken
            }
            firstName
            lastName
            imageUrl
            email
            role
            companyName
            githubHandle
            createdAt
            updatedAt
          }
          status
          feedback
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteMentorReview">>
  >;
}
