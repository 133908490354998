import { Component, Input, OnInit } from '@angular/core';
import { PreRequisiteTask } from 'src/app/API.service';
import { SubmissionTypes, taskStatus } from 'src/app/core/constants';
import { MenteeController } from 'src/app/core/controllers/mentee.controller';
import { DownloadFileService } from 'src/app/core/services/download-file.service';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { Utilities } from 'src/app/core/utilities';
import { CustomValidators } from '../validators';

@Component({
  selector: 'lfx-prerequisites',
  templateUrl: './prerequisites.component.html',
  styleUrls: ['./prerequisites.component.scss']
})
export class PrerequisitesComponent implements OnInit {
  @Input() preRequisites!: Array<PreRequisiteTask>;
  preRequisitesColumns = [
    {
      title: 'Prerequisite Name',
      isCenter: false,
    },
    {
      title: 'Status',
      isCenter: true,
    },
    {
      title: 'Due Date',
      isCenter: false,
    },
    {
      title: 'Actions',
      isCenter: true,
    }
  ];
  taskStatus = taskStatus;
  SubmissionTypes = SubmissionTypes;
  isLoading = false;
  activeIndex: number = -1;
  errorIndexList = new Array<number>();
  showAll = -1;
  constructor(
    private fileUploadService: FileUploadService,
    private menteeController: MenteeController,
    private downloadService: DownloadFileService
  ) { }

  ngOnInit() {
    this.isLoading = false;
  }

  // toggleSection(ev: any, index: number) {
    toggleSection(index: number) {
    // const target = ev.target;
    // target.classList.toggle("active");
    // this.activeIndex = index; // close
    // const panel: any = document.querySelector(`#lfx-pre-panel-${index}`);
    // if (panel?.style.maxHeight) {
    //   panel.style.maxHeight = null;
    // } else {
    //   panel.style.maxHeight = panel.scrollHeight + "px";
    // }
    if (this.showAll === index) {
      this.showAll = -1;
    } else {
      this.showAll = index;
    }
  }

  actions(ev: any, type: string) {
    ev.stopPropagation();
  }

  browseFiles(index: number) {
    const elem: any = document.querySelector(`#pre-req-${index}`);
    if (elem) {
      elem.click();
    }
  }

  handleFiles(ev: any, index: number, prerequisiteId: string) {
    this.fileUploadService.uploadFile(ev.target.files[0]).subscribe((url) => {
      const elem: any = document.querySelector(`#pre-req-file-url-${index}`);
      this.updateStatus(url, prerequisiteId, index);
    });
  }

  updateStatus(url: string, prerequisiteId: string, index: number) {
    const prerequisite = this.preRequisites[index];
    const params = {
      id: prerequisiteId,
      status: prerequisite.status === taskStatus.submitted ? taskStatus.submitted : taskStatus.uploaded,
      submitFile: url,
    };
    this.menteeController.updatePrerequisitesStatus(params).subscribe((data: any) => {
      const response = data.response.data.updatePreRequisiteTask;
      prerequisite.status = response.status;
      prerequisite.submitFile = response.submitFile;
    });
  }

  submitURLPrerequisite(url: string, prerequisiteId: string, index: number) {
    const errorIndex = this.errorIndexList.findIndex(error => error === index);
    if (errorIndex !== -1) {
      this.errorIndexList.splice(errorIndex, 1);
    }
    this.errorIndexList.includes(index) ? this.errorIndexList.push(index) : false
    if (Utilities.checkUrl(url)) {
      this.updateStatus(url, prerequisiteId, index);
    } else {
      !this.errorIndexList.includes(index) ? this.errorIndexList.push(index) : false;
    }
  }

  downloadFile(url: string) {
    if (url) {
      this.downloadService.downloadFile(url);
    }
  }

}
