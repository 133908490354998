import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { secretKey } from 'src/app/secret-key';

@Injectable({
  providedIn: 'root',
})
export class UserController {
  userData$ = new BehaviorSubject<any>(undefined);
  constructor(private http: HttpClient) {}

  getUserToken(username: string, password: string) {
    return this.http.post('https://sso.linuxfoundation.org/oauth/token', {
      grant_type: 'client_credentials',
      client_id: 'Obkt4fScGaiAGU44wHQ5quhF11juv0D3',
      client_secret: secretKey ? secretKey : '',
      audience: 'https://api-gw.platform.linuxfoundation.org/',
    });
  }

  getUsersList(nameFilter?: string) {
    return this.http
      .get(
        `https://bu3bulspo5.execute-api.us-east-1.amazonaws.com/dev/user-service/users?from=0&size=100${
          nameFilter ? '&name=' + nameFilter : ''
        }`
      )
      .pipe(
        map((res: any) =>
          res.Data.map((user: any) => {
            return {
              userId: user.ID,
              firstName: user.FirstName,
              lastName: user.LastName,
              role: user.Title ? user.Title : '',
              invitationStatus: 'Pending',
              imageUrl: user.LogoURL,
              email: user.Emails.find((email: any) => email.IsPrimary)
                .EmailAddress,
              emailMessage: `Hey, I Think You Would Be Interested In This Program. Would You Like To Join As A Mentor? Take A Look And Let Me Know What You Think!`,
            };
          })
        )
      );
  }
}
