import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MenteeAPISegments } from 'src/app/enums/mentee/mentee.enum';
import { MenteeCreateApplication, MenteeMilestoneListRequest, MenteeProgramPrerequisiteRequest, MenteeUpdateApplicationStatusRequest, MenteeUpdatePrerequisiteRequest } from 'src/app/models/menteeApplication';
import { User } from 'src/app/user';
import { APIService } from '../../API.service';
import { MENTEE_ID } from '../constants';
import { Profile } from '../models/mentee-profile.model';

@Injectable({
  providedIn: 'root'
})
export class MenteeController {

  readonly MenteeEndpoint = 'https://0sz2cejc5f.execute-api.us-east-1.amazonaws.com/dev/mentorship-service/mentee/';

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  options = {
    headers: this.httpHeaders
  };

  private _activeMentee?: any;

  constructor(private http: HttpClient, private api: APIService) { }

  createApplication(request: MenteeCreateApplication) {
    return this.http.post(`${this.MenteeEndpoint}${MenteeAPISegments.CreateApplication}`, request, this.options).pipe(map((res: any) => {
      return res.response.data.createMenteeApplication;
    }));
  }

  getApplications(queryParams: any) {
    let endpointExtras = `?userID=${queryParams.userID}&limit=${queryParams.limit}&cursor=${queryParams.cursor}`;
    if (queryParams.programID) endpointExtras += `&programID=${queryParams.programID}`;
    return this.http.get(`${this.MenteeEndpoint}${MenteeAPISegments.Applications}${endpointExtras}`);
  }

  createUserProfile(profile: Profile): Observable<any> {
    // environment.API_URL +
    return this.http.put<User>('users', profile).pipe(catchError(this.handleError));
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error.status + '|Something bad happened; please try again later.');
  }

  getPrerequisitesForProgramMentee(queryParams: MenteeProgramPrerequisiteRequest) {
    const endpointExtras = `?userID=${queryParams.userID}&programID=${queryParams.programID}&limit=${queryParams.limit}&cursor=${queryParams.cursor}`
    return this.http.get(`${this.MenteeEndpoint}${MenteeAPISegments.ListPrerequisites}${endpointExtras}`);
  }

  updatePrerequisitesStatus(params: MenteeUpdatePrerequisiteRequest) {
    return this.http.post(`${this.MenteeEndpoint}${MenteeAPISegments.UpdatePrerequisiteStatus}`, params);
  }

  updateApplicationStatusRequest(params: MenteeUpdateApplicationStatusRequest) {
    return this.http.post(`${this.MenteeEndpoint}${MenteeAPISegments.UpdateApplicationStatus}`, params);
  }

  getMenteeById(menteeID: string) {
    return from(this.api.GetMentee(menteeID));
  }

  get activeMentee() {
    if (this._activeMentee) {
      return new Observable(observable => {
        observable.next(this._activeMentee);
        observable.complete();
      });
    } else {
      return this.getMenteeById(MENTEE_ID)
    }
  }

  set activeMentee(mentee: any) {
    this._activeMentee = mentee;
  }

  getMenteeMilestones(queryParams: MenteeMilestoneListRequest) {
    const endpointExtras = `?userID=${queryParams.userID}&programID=${queryParams.programID}`
    return this.http.get(`${this.MenteeEndpoint}${MenteeAPISegments.ListMileStones}${endpointExtras}`).pipe(map((res: any) => {
      const milestones = res.response.data.listMenteeProgramMilestones.milestones;
      return milestones;
    }));
  }
}
