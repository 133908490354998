<ng-container *ngIf="preRequisites.length; else noData">
  <div class="common-table-container">
    <div class="table-header grid">
      <span class="table-header-column" [ngClass]="{'text-center': column.isCenter}" lfxEllipsis
        *ngFor="let column of preRequisitesColumns;">
        {{column.title}}
      </span>
    </div>
    <div class="table-body">
      <div class="table-row" *ngFor="let row of preRequisites; let rowIndex = index"
        [ngClass]="{'active': activeIndex === rowIndex }">
        <div class="table-body-column align-items-center">
          <div class="grid">
            <div class="column-data d-flex">
              <button type="button" class="btn py-1 px-3 mx-1 action-collapsible"
                (click)="toggleSection(rowIndex)">
                <i class="fas fa fa-chevron-{{showAll === rowIndex ? 'down' : 'right'}}"></i>
              </button>
              <div class="d-flex flex-column flex-grow-1">
                <span class="text-black weight-600">
                  {{row.prerequisiteName}}
                </span>
                <div *ngIf="row.submission === SubmissionTypes.url" class="col-xl-6 col-sm-12 pl-0 mt-3">
                  <ng-container
                    *ngTemplateOutlet="urlSubmissionTypeTemplate; context: {prerequisite: row, index: rowIndex}">
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="column-data d-flex align-items-center justify-content-center">
                <div class="status mw-100 w-100 d-flex align-items-center justify-content-center" lfxEllipsis [ngClass]="{
                  'pending-status': row.status.toLowerCase() === taskStatus.pending,
                  'submitted-status': row.status.toLowerCase() === taskStatus.submitted,
                  'completed-status': row.status.toLowerCase() === taskStatus.completed,
                  'uploaded-status': row.status.toLowerCase() === taskStatus.uploaded}"></div>
            </div>
            <div class="column-data d-flex">
              <span class="text-black d-flex align-items-center justify-content-center w-100 mw-100" lfxEllipsis
                *ngIf="row.dueDate">
                {{row.dueDate | date:'mediumDate'}}
              </span>
            </div>
            <div class="d-flex text-right justify-content-center align-items-center w-100">
              <ng-container *ngIf="row.submission !== SubmissionTypes.url">
                <a class="py-1 px-3 mx-1 ml-2"
                  (click)="browseFiles(rowIndex)"> <i class="fa fa-upload" aria-hidden="true"></i></a>
                <input [style.display]="'none'" type="file" name="uploadFile-{{rowIndex}}" id="pre-req-{{rowIndex}}"
                  (change)="handleFiles($event, rowIndex, row.id)" />
                <input type="text" id="pre-req-file-url-{{rowIndex}}" style="display: none;" />

                <a type="button" target="_blank" *ngIf="row.status != 'Pending' && row.submitFile"
                  class="py-1 px-3 mx-1" [href]="row.submitFile"><i class="fas fa-binoculars"></i></a>
                <a type="button" disabled *ngIf="!(row.status != 'Pending' && row.submitFile)"
                  class="py-1 px-3 mx-1"><i class="fas fa-binoculars btnDisable"></i></a> 
              </ng-container>
              <ng-container *ngIf="row.submission == SubmissionTypes.url">
                <a type="button" class="py-1 px-3 mx-1"><i class="fas fa-pencil-alt"></i></a> 
              </ng-container>
            </div>
          </div>
        </div>
        <div [id]="'lfx-pre-panel-'+rowIndex" class="max-height" [ngClass]="{'active': showAll === rowIndex, 'inactive': showAll !== rowIndex }">
          <div class="mx-3 my-3">
            <div class="text-black">
              <span [innerHTML]="row.description"></span>
            </div>
            <div *ngIf="row.githubURL">
              <span class="text-black">Github URL:</span>
              <a target="_blank" [href]="row.githubURL">{{' '+row.githubURL}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #urlSubmissionTypeTemplate let-prerequisite="prerequisite" let-index="index">
  <input type="text" [(ngModel)]="prerequisite.submitFile" class="form-control text-primary"
    placeholder="Submit the url to your gitHub code repo" [ngClass]="{'border-danger': errorIndexList.includes(index)}"
    (keyup.enter)="submitURLPrerequisite(prerequisite.submitFile, prerequisite.id,index)">
  <small *ngIf="errorIndexList.includes(index)" class="form-text text-danger">Please enter valid URL</small>
  <!-- <div *ngIf="editIndex === index; else submissionText">
  </div>
  <ng-template #submissionText>
    <span class="text-primary">{{prerequisite.submitFile}}</span>
  </ng-template> -->
</ng-template>

<ng-template #noData>
  <div>No Prerequisites.</div>
</ng-template>
<ng-template #loadingTemplate>
  <lfx-loading></lfx-loading>
</ng-template>