<div class="program-card-container card">
  <div class="program-card-header card-header">
    <img class="mb-3-2" [src]="program.programDetail?.programLogoURL">
    <span class="weight-bold text-primary cursor-pointer"
      (click)="navigateToProgramDetail()">{{program.programName}}</span>
    <i class="fa fa-share-square" aria-hidden="true"></i>
  </div>
  <div class="program-card-body card-body">
    <div class="col-12 info-section-container d-flex justify-content-between px-0 mb-3-2">
      <div class="col-6" *ngIf="program.mentors">
        <!-- Mentors section template -->
        <ng-template [ngTemplateOutlet]="mentorsSection"></ng-template>
      </div>
      <div class="col-6">
        <!-- Current mentees section template -->
        <ng-template [ngTemplateOutlet]="currentMenteesSection"></ng-template>
      </div>
    </div>
    <div class="col-12 info-section-container d-flex justify-content-between px-0 mb-3-2">
      <div class="col-6">
        <!-- Funding to date section template -->
        <ng-template [ngTemplateOutlet]="termsSection"></ng-template>
      </div>
      <div class="col-6">
        <!-- Graduated mentees section template -->
        <ng-template [ngTemplateOutlet]="statusSection"></ng-template>
      </div>
    </div>
    <div class="col-md-12 mt-2">
      <!-- Required skills section template -->
      <ng-template [ngTemplateOutlet]="requiredSkillsSection"></ng-template>
    </div>
  </div>
</div>



<!-- Terms section template -->
<ng-template #termsSection>
  <div class="bold-title">Term</div>
  <div class="d-flex justify-content-between mt-2">
    <div class="term-name">
      {{ program.terms[0].termName }}
    </div>
  </div>
</ng-template>

<!-- Status section template -->
<ng-template #statusSection>
  <div class="bold-title">Status</div>
  <div class="status mt-2" lfxEllipsis [ngClass]="{
    'inprogress-status': program.status === programStatus.pending,
    'completed-status': program.status === programStatus.completed,
    'accepted-status': program.status === programStatus.accepted,
    'declined-status': program.status === programStatus.declined
  }">
    {{ program.status }}
  </div>
</ng-template>

<!-- Mentors section template -->
<ng-template #mentorsSection>
  <div class="bold-title">
    Mentors
  </div>
  <div class="icons-container">
    <ng-container *ngFor="let mentor of program?.mentors | slice: 0:3">
      <img class="icon"
        [src]="mentor.imageUrl ? mentor.imageUrl : 'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+mentor.firstName.toLowerCase().charAt(0)+'.png'"
        [title]="mentor.firstName + ' ' + mentor.lastName" />

    </ng-container>
    <div *ngIf="program.mentors && program.mentors.length > 3" class="icon-default">
      +{{ program.mentors.length - 3 }}
    </div>
  </div>
</ng-template>

<!-- Current mentees section template -->
<ng-template #currentMenteesSection>
  <div class="bold-title">
    Current Mentees
  </div>
  <div class="icons-container">
    <img *ngFor="let mentee of programMentees | slice: 0:3" class="icon"
      [src]="mentee.imageUrl ? mentee.imageUrl: 'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+mentee.firstName.toLowerCase().charAt(0)+'.png'"
      [title]="mentee.firstName + ' ' + mentee.lastName" />
    <div *ngIf="programMentees.length > 3" class="icon-default">+{{ programMentees.length - 3 }}</div>
  </div>
</ng-template>

<!-- Required skills section template -->
<ng-template #requiredSkillsSection>
  <div class="bold-title">Required Skills And Technologies</div>
  <div class="d-flex justify-content-start mt-2">
    <div class="skill mr-2 " lfxEllipsis
      *ngFor="let skill of program.programDetail?.requiredSkillsAndTechnologies | slice: 0:3; index as i">
      {{ skill }}
    </div>
    <small class="skill"
      *ngIf="program.programDetail?.requiredSkillsAndTechnologies && program.programDetail.requiredSkillsAndTechnologies.length > 3"
      (mouseleave)="closePopover()">
      <span [ngbPopover]="listViewerTemplate" #skillsList="ngbPopover" (mouseenter)="openPopover(skillsList)"
        [placement]="['bottom', 'top']" triggers="manual" popoverClass="custom-popover">
        +{{ program.programDetail.requiredSkillsAndTechnologies.length - 3 }}
      </span>
    </small>
  </div>
</ng-template>

<ng-template #listViewerTemplate>
  <div class="selected-items-popover-item ellipsis"
    *ngFor="let skill of program.programDetail?.requiredSkillsAndTechnologies | slice: 3">
    {{ skill }}
  </div>
</ng-template>